import Joi from 'joi';

import { audienceTypes } from '../../shared';
import { ACTIVITY_POST_MESSAGE_MAX_LENGTH } from './activity-post.constants';
import { ActivityPostErrors } from './activity-post.errors';

export namespace ActivityPostSchema {
  export const activityKey = Joi.string()
    .required()
    .messages(ActivityPostErrors.activityKey);

  export const audiences = Joi.array()
    .items(Joi.string().valid(...audienceTypes))
    .allow(null)
    .required();

  export const message = Joi.string()
    .max(ACTIVITY_POST_MESSAGE_MAX_LENGTH)
    .required()
    .messages(ActivityPostErrors.message);

  export const activityPostKey = Joi.string().required();
}
