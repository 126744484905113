import { joiResolver } from '@hookform/resolvers/joi';
import {
  Box,
  Button,
  Icon,
  Modal,
  ModalBodyContainer,
  ModalContentContainer,
  ModalFooterContainer,
  ModalHeaderContainer,
  ModalTitle,
  ResponsiveBox,
  SolidIconButton,
  Text,
  Tooltip,
  flattenFieldErrorsObject,
  useModalState,
} from '@orbiapp/components';
import React from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';

import {
  CreateMembershipInvitationsFormValidation,
  CreateStandardMembershipInvitationsFormValidation,
  MembershipInvitesForm,
} from '../../../../../models';
import { Logger } from '../../../../../services';
import {
  CreateMembershipInvitationsSelector,
  MembershipTypeSelector,
  createMembershipInvitationsThunk,
  membershipsActions,
  searchActions,
  useDispatch,
  useSelector,
} from '../../../../../store';
import { SearchForMembers } from './search-input';
import { SelectPeriods } from './select-periods';

export const InviteMembersContext = React.createContext<
  ReturnType<typeof useModalState>
>({
  closeModal: () => {},
  isOpen: false,
  openModal: () => {},
});

export function InviteMembersProvider(props: React.PropsWithChildren) {
  const { children } = props;

  const modalState = useModalState();

  return (
    <InviteMembersContext.Provider value={modalState}>
      {children}
    </InviteMembersContext.Provider>
  );
}

function SendInvitesButton() {
  const { getValues, handleSubmit, watch } =
    useFormContext<MembershipInvitesForm>();

  const membershipType = useSelector(
    MembershipTypeSelector.selectMembershipType,
  );

  const periods = useSelector(MembershipTypeSelector.selectMembershipPeriods);

  const email = watch('emails.0');

  const dispatch = useDispatch();

  const createMembershipInvitationsStatus = useSelector(
    CreateMembershipInvitationsSelector.selectStatus,
  );

  const inviteMembers = handleSubmit(
    (data) => {
      dispatch(createMembershipInvitationsThunk(data));
    },
    (err) => {
      Logger.warning('inviteMembershipMembers Validation', {
        err: {
          ...flattenFieldErrorsObject(err),
          ...getValues('emails').reduce((acc, email, index) => {
            acc[`emails.${index}`] = email;

            return acc;
          }, {} as Record<string, string>),
        },
      });
    },
  );

  const disabled =
    !email || (membershipType !== 'standard' && !periods?.length);

  return (
    <Button
      disabled={disabled}
      isLoading={createMembershipInvitationsStatus === 'pending'}
      onClick={inviteMembers}
      tx="button.invite"
      variant="primary"
    />
  );
}

function InviteMembersForm() {
  const { closeModal } = React.useContext(InviteMembersContext);

  const membershipType = useSelector(
    MembershipTypeSelector.selectMembershipType,
  );

  const formMethods = useForm<MembershipInvitesForm>({
    defaultValues: { emails: [], membershipPeriodKeys: null },
    resolver: joiResolver(
      membershipType === 'standard'
        ? CreateStandardMembershipInvitationsFormValidation
        : CreateMembershipInvitationsFormValidation,
    ),
  });

  const dispatch = useDispatch();

  const closeModalAndClearResult = () => {
    closeModal();

    window.setTimeout(() => {
      dispatch(membershipsActions.clearMembershipInvitationResult());
      dispatch(searchActions.clearUsers());

      formMethods.reset();
    }, 200);
  };

  React.useEffect(() => formMethods.reset(), [formMethods, membershipType]);

  return (
    <FormProvider {...formMethods}>
      <ModalBodyContainer gap={24}>
        <SelectPeriods />

        <SearchForMembers />
      </ModalBodyContainer>

      <ModalFooterContainer>
        <Button
          tx="button.cancel"
          onClick={closeModalAndClearResult}
          variant="tertiary"
        />
        <SendInvitesButton />
      </ModalFooterContainer>
    </FormProvider>
  );
}

function InviteMembersResult() {
  const membershipTypeKey = useSelector(
    MembershipTypeSelector.selectMembershipTypeKey,
  );
  const { closeModal } = React.useContext(InviteMembersContext);

  const dispatch = useDispatch();

  const successfulInvitesCount = useSelector(
    CreateMembershipInvitationsSelector.selectSuccessfulInvitesCount,
  );

  const viewStatuses = () => {
    closeModal();

    window.setTimeout(() => {
      dispatch(membershipsActions.clearMembershipInvitationResult());
      dispatch(searchActions.clearUsers());
    }, 200);
  };

  const handleGoBack = () => {
    dispatch(membershipsActions.clearMembershipInvitationResult());
    dispatch(searchActions.clearUsers());
  };

  return (
    <ModalBodyContainer>
      <Box flexDirection="column" flex flexAlign="center" gap={16} py={32}>
        {successfulInvitesCount > 0 ? (
          <Icon name="check-circle-solid" color="successPrimary" size={80} />
        ) : (
          <Icon name="x-circle-solid" color="errorPrimary" size={80} />
        )}

        <Text
          tx="label.memberships.invite-members.invite-result-text"
          txArgs={{ count: successfulInvitesCount }}
        />

        {successfulInvitesCount > 0 ? (
          <Button
            onClick={viewStatuses}
            variant="primary"
            tx="button.view-status"
            to={`/memberships/${membershipTypeKey}/invites`}
          />
        ) : (
          <Button
            onClick={handleGoBack}
            variant="primary"
            tx="button.go-back"
          />
        )}
      </Box>
    </ModalBodyContainer>
  );
}

export function InviteMembersModal() {
  const { isOpen, closeModal } = React.useContext(InviteMembersContext);

  const createMembershipInvitationsResult = useSelector(
    CreateMembershipInvitationsSelector.selectData,
  );

  const dispatch = useDispatch();

  const closeModalAndClearResult = () => {
    closeModal();

    dispatch(membershipsActions.clearMembershipInvitationResult());
    dispatch(searchActions.clearUsers());
  };

  return (
    <Modal width={800} isOpen={isOpen} onClose={closeModalAndClearResult}>
      <ModalContentContainer>
        {createMembershipInvitationsResult ? (
          <InviteMembersResult />
        ) : (
          <React.Fragment>
            <ModalHeaderContainer>
              <ModalTitle tx="title.memberships.invite-members" />
            </ModalHeaderContainer>

            <InviteMembersForm />
          </React.Fragment>
        )}
      </ModalContentContainer>
    </Modal>
  );
}

export function InviteMembersButton() {
  const isIntegratd = useSelector(MembershipTypeSelector.selectIsIntegrated);

  const { openModal } = React.useContext(InviteMembersContext);

  if (isIntegratd) {
    return null;
  }

  return (
    <ResponsiveBox
      xs={
        <Button
          tx="label.memberships.invite-members.open-modal-button"
          variant="primary"
          onClick={openModal}
        />
      }
    >
      <Tooltip
        placement="left"
        titleTx="label.memberships.invite-members.open-modal-button"
      >
        <SolidIconButton icon="share-outline" onClick={openModal} />
      </Tooltip>
    </ResponsiveBox>
  );
}
