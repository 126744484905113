import {
  GeneralApiResult,
  GeneralApiResultWithData,
} from '@orbiapp/components';

import {
  DownloadableFileType,
  RefundTicketErrorResponse,
  ShrodingersTicket,
  ShrodingersTicketErrorResponse,
  TicketHistoryListItem,
} from '../../../../../../models';
import { Logger } from '../../../../../logger';
import { apisauce } from '../../../../api';
import { CUSTOM_TIMEOUTS } from '../../../../api.constants';
import { getApiLogExtras, getGeneralApiProblem } from '../../../../api.utils';
import { ShrodingersTicketDto } from './tickets.dto';
import {
  shrodingersTicketMapper,
  ticketHistoryListItemMapper,
} from './tickets.mappers';
import { sortTicketHistoryListItem } from './tickets.utils';

export const getActivityUsersData = async (params: {
  activityKey: string;
  type: DownloadableFileType;
}): Promise<GeneralApiResultWithData<Blob>> => {
  const res = await apisauce.get<Blob, any>(
    `/v1/activities/${params.activityKey}/tickets/${params.type}`,
    undefined,
    { responseType: 'blob', timeout: CUSTOM_TIMEOUTS.getActivityUsers },
  );

  if (!res.ok) {
    Logger.warning('getActivityUsersCsvData', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = res.data;

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('getActivityUsersCsvData', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export const getTicketsHistory = async (
  activityKey: string,
): Promise<GeneralApiResultWithData<TicketHistoryListItem[]>> => {
  const res = await apisauce.get<TicketHistoryListItem[], any>(
    `/v1/activities/${activityKey}/tickets/history`,
    undefined,
    { timeout: CUSTOM_TIMEOUTS.getTicketsHistory },
  );

  if (!res.ok) {
    Logger.warning('getTicketsHistory', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data: TicketHistoryListItem[] = res.data
      .map(ticketHistoryListItemMapper)
      .sort(sortTicketHistoryListItem);

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('getTicketsHistory', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export const refundTicket = async (params: {
  activityKey: string;
  ticketKey: string;
}): Promise<GeneralApiResult<RefundTicketErrorResponse>> => {
  const { activityKey, ticketKey } = params;

  const res = await apisauce.put<void, RefundTicketErrorResponse>(
    `/v1/activities/${activityKey}/tickets/${ticketKey}/refund`,
  );

  if (!res.ok) {
    Logger.warning('refundTicket', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  return { kind: 'ok' };
};

export const consumeTicket = async (params: {
  activityKey: string;
  ticketKey: string;
}): Promise<GeneralApiResult> => {
  const { activityKey, ticketKey } = params;

  const res = await apisauce.put<void, any>(
    `/v1/activities/${activityKey}/tickets/${ticketKey}/consume`,
  );

  if (!res.ok) {
    Logger.warning('consumeTicket', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  return { kind: 'ok' };
};

export const undoConsumeTicket = async (params: {
  activityKey: string;
  ticketKey: string;
}): Promise<GeneralApiResult> => {
  const { activityKey, ticketKey } = params;

  const res = await apisauce.put<void, any>(
    `/v1/activities/${activityKey}/tickets/${ticketKey}/undo-consume`,
  );

  if (!res.ok) {
    Logger.warning('undoConsumeTicket', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  return { kind: 'ok' };
};

export const scanTicket = async (params: {
  activityKey: string;
  ticketPurchaseKey: string;
}): Promise<
  GeneralApiResultWithData<ShrodingersTicket, ShrodingersTicketErrorResponse>
> => {
  const { activityKey, ticketPurchaseKey } = params;

  const res = await apisauce.put<
    ShrodingersTicketDto,
    ShrodingersTicketErrorResponse
  >(`/v1/activities/${activityKey}/scan-consume-ticket`, {
    ticketPurchaseKey,
  });

  if (!res.ok) {
    Logger.warning('scanTicket', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data: ShrodingersTicket = shrodingersTicketMapper(res.data);

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('scanTicket', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export const undoScanTicket = async (params: {
  activityKey: string;
  ticketPurchaseKey: string;
}): Promise<GeneralApiResult> => {
  const { activityKey, ticketPurchaseKey } = params;

  const res = await apisauce.put<void, any>(
    `/v1/activities/${activityKey}/undo-scan-consume-ticket`,
    { ticketPurchaseKey },
  );

  if (!res.ok) {
    Logger.warning('undoScanTicket', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  return { kind: 'ok' };
};
