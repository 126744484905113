import { CreateIntegratedMembershipTypeForm } from '../../../../../../models';
import { CreateIntegratedMembershipTypeDto } from './integrated.dto';

export const createIntegratedMembershipTypeMapper = (
  createIntegratedMembershipTypeForm: CreateIntegratedMembershipTypeForm,
): CreateIntegratedMembershipTypeDto => {
  return {
    name: createIntegratedMembershipTypeForm.name,
    backgroundImage: createIntegratedMembershipTypeForm.backgroundImage!,
    contactDetails: {
      email: createIntegratedMembershipTypeForm.contactDetails.email,
      name: createIntegratedMembershipTypeForm.contactDetails.name,
      phone: createIntegratedMembershipTypeForm.contactDetails.phone?.length
        ? createIntegratedMembershipTypeForm.contactDetails.phone
        : null,
    },
    description: createIntegratedMembershipTypeForm.description,
    logo: createIntegratedMembershipTypeForm.logo!,
    metadata: createIntegratedMembershipTypeForm.metadata,
  };
};
