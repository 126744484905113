import { Base64File } from '@orbiapp/components';
import Joi from 'joi';

import { CoverImageSchema, LogoImageSchema } from './file.schema';

export const CoverImageValidation = Joi.object<Base64File>({
  base64: CoverImageSchema.base64,
  fileName: CoverImageSchema.fileName,
  fileType: CoverImageSchema.fileType,
});

export const LogoValidation = Joi.object<Base64File>({
  base64: LogoImageSchema.base64,
  fileName: LogoImageSchema.fileName,
  fileType: LogoImageSchema.fileType,
});
