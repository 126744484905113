import { createSlice } from '@reduxjs/toolkit';

import { extraReducers } from './feature-flags.build';
import { initialFeatureFlagsState } from './feature-flags.initial';

const featureFlagsSlice = createSlice({
  name: 'feature-flags',
  initialState: initialFeatureFlagsState,
  reducers: {},
  extraReducers,
});

export const featureFlagsReducer = featureFlagsSlice.reducer;
