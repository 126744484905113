import * as Sentry from '@sentry/browser';

export namespace Logger {
  export function fatal(message: string, extra: Sentry.Event['extra']) {
    const payload: Sentry.Event = {
      level: 'fatal',
      message,
      extra,
    };

    Sentry.captureEvent(payload);
  }

  export function error(message: string, extra: Sentry.Event['extra']) {
    const payload: Sentry.Event = {
      level: 'error',
      message,
      extra,
    };

    Sentry.captureEvent(payload);
  }

  export function warning(message: string, extra: Sentry.Event['extra']) {
    const payload: Sentry.Event = {
      level: 'warning',
      message,
      extra,
    };

    Sentry.captureEvent(payload);
  }

  export function log(message: string, extra: Sentry.Event['extra']) {
    const payload: Sentry.Event = {
      level: 'log',
      message,
      extra,
    };

    Sentry.captureEvent(payload);
  }

  export function info(message: string, extra: Sentry.Event['extra']) {
    const payload: Sentry.Event = {
      level: 'info',
      message,
      extra,
    };

    Sentry.captureEvent(payload);
  }

  export function debug(message: string, extra: Sentry.Event['extra']) {
    const payload: Sentry.Event = {
      level: 'debug',
      message,
      extra,
    };

    Sentry.captureEvent(payload);
  }

  export function addBreadcrumb(
    level: Sentry.SeverityLevel,
    category: 'store' | 'api' | 'pages' | 'utils',
    message: string,
    data?: Sentry.Breadcrumb['data'],
  ) {
    const breadcrumb: Sentry.Breadcrumb = {
      level,
      category,
      message,
      timestamp: Date.now(),
      data,
    };

    Sentry.addBreadcrumb(breadcrumb);
  }
}
