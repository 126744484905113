import {
  Avatar,
  Box,
  BreadCrumb,
  BreadCrumbs,
  Button,
  ContentContainer,
  Divider,
  Icon,
  Image,
  InnerContentContainer,
  InnerPageContainer,
  Link,
  PageContainer,
  ResponsiveBox,
  Status,
  StatusVariant,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TablePlaceholderRows,
  TableRow,
  Text,
  Time,
  Toolbar,
  ToolbarContentContainer,
  Trans,
  getAvatarVariantFromString,
  parseTimestamp,
} from '@orbiapp/components';
import React from 'react';

import { assets } from '../../../assets';
import {
  DEPARTMENT_REFERRAL_BASE_URL,
  DEPARTMENT_REFERRAL_KNOWLEDGE_URL,
  DEPARTMENT_REFERRAL_REWARD_PERIOD,
  ORBI_PAY_FREE_PERIOD_FOR_REFERRALS,
} from '../../../constants';
import { Referral } from '../../../models';
import { Analytics } from '../../../services';
import {
  AccountSelector,
  DepartmentSelector,
  OrbiPaySettingsSelector,
  ReferralsSelector,
  getReferralsThunk,
  useDispatch,
  useSelector,
} from '../../../store';
import {
  getReferralRewardAmountsInCurrency,
  joinOrgNodeBranchNames,
} from '../../../utils';

const TABLE_COLUMN_WIDTHS = {
  name: 200,
  path: 250,
  status: 100,
  daysLeft: 100,
  users: 100,
  reward: 100,
  ends: 100,
};

interface ReferralStep {
  src: string;
  titleTx: TxString;
  descriptionTx: TxString;
}

const referralSteps: ReferralStep[] = [
  {
    src: assets.referrals.outbound,
    titleTx: 'label.referrals.empty-state.step-1-header',
    descriptionTx: 'label.referrals.empty-state.step-1-description',
  },
  {
    src: assets.referrals.inbound,
    titleTx: 'label.referrals.empty-state.step-2-header',
    descriptionTx: 'label.referrals.empty-state.step-2-description',
  },
  {
    src: assets.referrals.cash,
    titleTx: 'label.referrals.empty-state.step-3-header',
    descriptionTx: 'label.referrals.empty-state.step-3-description',
  },
];

const renderReferralStep = (referralStep: ReferralStep, index: number) => (
  <Box width={250} key={index} flex flexDirection="column">
    <Image width={175} height={157} src={referralStep.src} alt="" />
    <Text
      tx={referralStep.titleTx}
      variant="bodyMdBold"
      pt={16}
      pb={4}
      color="referralsEmptyTableStateText"
    />
    <Text
      tx={referralStep.descriptionTx}
      variant="bodyMd"
      color="referralsEmptyTableStateDescription"
    />
  </Box>
);

function getReferralStatus(referral: Referral): [TxString, StatusVariant] {
  if (referral.rewardCompletedAt) {
    return ['label.referrals.referrals-table-body.completed', 'success'];
  }

  if (referral.rewardFailedAt) {
    return ['label.referrals.referrals-table-body.failed', 'warning'];
  }

  return ['label.referrals.referrals-table-body.active', 'info'];
}

function daysLeft(referral: Referral) {
  if (referral.rewardCompletedAt || referral.rewardFailedAt) {
    return 0;
  }

  const timePassed = Date.now() - referral.createdAt;
  const timeLeft = DEPARTMENT_REFERRAL_REWARD_PERIOD - timePassed;

  if (timeLeft < 0) {
    return 0;
  }

  return Math.floor(timeLeft / Time.Day);
}

const renderReferralTableRow = (referral: Referral) => {
  const [tx, variant] = getReferralStatus(referral);

  return (
    <TableRow key={referral.referralKey}>
      <TableCell width={TABLE_COLUMN_WIDTHS.name}>
        <Box flex gap={8} flexAlign="center">
          <Avatar
            src={referral.referredDepartment.logo?.thumbnail64.url}
            fallbackLetter={referral.referredDepartment.name}
            variant={getAvatarVariantFromString(
              referral.referredDepartment.departmentKey,
            )}
          />
          <Text
            as="span"
            variant="bodySm"
            text={referral.referredDepartment.name}
          />
        </Box>
      </TableCell>

      <TableCell
        width={TABLE_COLUMN_WIDTHS.path}
        text={joinOrgNodeBranchNames([
          ...referral.referredDepartment.orgNodeBranch,
        ])}
      />

      <TableCell width={TABLE_COLUMN_WIDTHS.status}>
        <Status variant={variant} tx={tx} />
      </TableCell>

      <TableCell
        width={TABLE_COLUMN_WIDTHS.daysLeft}
        text={daysLeft(referral).toString()}
      />

      <TableCell
        width={TABLE_COLUMN_WIDTHS.users}
        text={referral.referredDepartment.rewardUserCount}
      />

      <TableCell
        width={TABLE_COLUMN_WIDTHS.reward}
        text={referral.referredDepartment.rewardUserCount * 5}
      />

      <TableCell
        width={TABLE_COLUMN_WIDTHS.ends}
        text={parseTimestamp(
          referral.createdAt + DEPARTMENT_REFERRAL_REWARD_PERIOD,
          'DD MMM YYYY',
        )}
      />
    </TableRow>
  );
};

function ReferralsTable({
  maxReward,
  rewardPerUser,
}: {
  maxReward: string;
  rewardPerUser: string;
}) {
  const referrals = useSelector(ReferralsSelector.selectData);

  if (referrals?.length === 0) {
    const steps = referralSteps.map(renderReferralStep);

    const title = (
      <Text
        color="pageTitle"
        tx="label.referrals.empty-state.title"
        variant="titleMd"
        as="h1"
      />
    );

    return (
      <ResponsiveBox
        sm={
          <Box flex flexDirection="column" gap={32}>
            <Divider />

            {title}

            <Box flex gap={16}>
              {steps}
            </Box>

            <Divider />
          </Box>
        }
      >
        <Box flex flexDirection="column" gap={32} width="100%">
          <Divider />

          {title}

          <Box flex gap={32} flexDirection="column">
            {steps}
          </Box>

          <Divider />
        </Box>
      </ResponsiveBox>
    );
  }

  if (referrals === null) {
    return (
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead tx="label.referrals.referrals-table-header.name" />
            <TableHead tx="label.referrals.referrals-table-header.path" />
            <TableHead tx="label.referrals.referrals-table-header.status" />
            <TableHead tx="label.referrals.referrals-table-header.days-left" />
            <TableHead
              tx="label.referrals.referrals-table-header.users"
              tooltip={{
                placement: 'bottom',
                titleTx: 'label.referrals.referrals-table-header.users-tooltip',
              }}
            />
            <TableHead
              tx="label.referrals.referrals-table-header.reward"
              tooltip={{
                placement: 'bottom',
                titleTx:
                  'label.referrals.referrals-table-header.reward-tooltip',
                titleTxArgs: { rewardPerUser, maxReward },
              }}
            />
            <TableHead tx="label.referrals.referrals-table-header.ends" />
          </TableRow>
        </TableHeader>

        <TableBody>
          <TablePlaceholderRows
            rowCount={6}
            layout={Object.values(TABLE_COLUMN_WIDTHS)}
          />
        </TableBody>
      </Table>
    );
  }

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead tx="label.referrals.referrals-table-header.name" />
          <TableHead tx="label.referrals.referrals-table-header.path" />
          <TableHead tx="label.referrals.referrals-table-header.status" />
          <TableHead tx="label.referrals.referrals-table-header.days-left" />
          <TableHead
            tx="label.referrals.referrals-table-header.users"
            tooltip={{
              placement: 'bottom',
              titleTx: 'label.referrals.referrals-table-header.users-tooltip',
            }}
          />
          <TableHead
            tx="label.referrals.referrals-table-header.reward"
            tooltip={{
              placement: 'bottom',
              titleTx: 'label.referrals.referrals-table-header.reward-tooltip',
              titleTxArgs: { rewardPerUser, maxReward },
            }}
          />
          <TableHead tx="label.referrals.referrals-table-header.ends" />
        </TableRow>
      </TableHeader>
      <TableBody>{referrals.map(renderReferralTableRow)}</TableBody>
    </Table>
  );
}

function ReferralsInfo({ maxReward }: { maxReward: string }) {
  const departmentKey = useSelector(AccountSelector.selectCurrentWorkspaceKey);

  const [hasCopied, setHasCopied] = React.useState(false);

  const department_name = useSelector(DepartmentSelector.selectName);
  const fullName = useSelector(AccountSelector.selectFullName);
  const email = useSelector(AccountSelector.selectEmail);

  if (!departmentKey) {
    return null;
  }

  const departmentReferralUrl = DEPARTMENT_REFERRAL_BASE_URL + departmentKey;

  const handleOpenLinkClick = () => {
    Analytics.track('click_referrals_open_link', {
      param1: department_name,
      param2: fullName ?? undefined,
      param3: email,
    });
  };

  const handleReadMoreLinkClick = () => {
    Analytics.track('click_referrals_read_more', {
      param1: department_name,
      param2: fullName ?? undefined,
      param3: email,
    });
  };

  const copyReferralUrlToClipboard = () => {
    Analytics.track('click_referrals_copy_link', {
      param1: department_name,
      param2: fullName ?? undefined,
      param3: email,
    });

    setHasCopied(true);

    navigator.clipboard.writeText(departmentReferralUrl);

    window.setTimeout(() => {
      setHasCopied(false);
    }, 2000);
  };

  return (
    <Box flex flexDirection="column" gap={16}>
      <Text color="referralsInfoText">
        <Trans
          i18nKey="label.referrals.referral-info.description.text"
          values={{ maxReward }}
          components={{
            bold: (
              <Text as="span" variant="titleXs" color="referralsInfoTextBold" />
            ),
          }}
        />
      </Text>

      <Text color="referralsInfoText">
        <Trans
          i18nKey="label.referrals.referral-info.description.sign-up"
          components={{
            link: (
              <Box
                flex
                flexAlign="center"
                gap={4}
                style={{ display: 'inline-flex' }}
              >
                <Link
                  to={departmentReferralUrl}
                  tx="label.referrals.referral-info.description.this-link"
                  target="_blank"
                  onClick={handleOpenLinkClick}
                />
                <Button
                  ml={8}
                  variant="primary"
                  tx="button.referrals.copy-referral-url"
                  iconPlacement="right"
                  onClick={copyReferralUrlToClipboard}
                />
                <Icon
                  color={
                    hasCopied
                      ? 'copyToClipBoardIconActive'
                      : 'copyToClipBoardIcon'
                  }
                  name="check-circle-solid"
                />
              </Box>
            ),
          }}
        />
      </Text>

      <Text variant="bodyMd" color="referralsInfoText">
        <Trans
          i18nKey="label.referrals.referral-info.description.orbi-pay"
          values={{
            freeMonths: Math.round(
              ORBI_PAY_FREE_PERIOD_FOR_REFERRALS / (30 * Time.Day),
            ),
          }}
          components={{
            bold: (
              <Text as="span" variant="titleXs" color="referralsInfoTextBold" />
            ),
            link: (
              <Link
                style={{ display: 'inline-flex' }}
                to={DEPARTMENT_REFERRAL_KNOWLEDGE_URL}
                tx="label.referrals.referral-info.description.here"
                target="_blank"
                onClick={handleReadMoreLinkClick}
              />
            ),
          }}
        />
      </Text>
    </Box>
  );
}

function ReferralsContent() {
  const currency = useSelector(OrbiPaySettingsSelector.selectCurrency);

  const rewardAmountsInCurrency = getReferralRewardAmountsInCurrency(currency);

  return (
    <Box flex flexDirection="column" gap={32}>
      <Text
        color="pageTitle"
        tx="title.referrals.dashboard"
        variant="titleMd"
        as="h1"
      />

      <ReferralsInfo
        maxReward={rewardAmountsInCurrency.maxRewardWithCurrency}
      />

      <ReferralsTable
        maxReward={rewardAmountsInCurrency.maxRewardWithCurrency}
        rewardPerUser={rewardAmountsInCurrency.rewardPerUser}
      />
    </Box>
  );
}

function ReferralsToolbar() {
  return (
    <Toolbar backgroundColor="tabHeaderBackground" zIndex={1}>
      <ToolbarContentContainer>
        <BreadCrumbs>
          <BreadCrumb tx="label.breadcrumbs.referrals.referrals" isLast />
        </BreadCrumbs>
      </ToolbarContentContainer>
    </Toolbar>
  );
}

export function Referrals() {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getReferralsThunk());
  }, [dispatch]);

  return (
    <PageContainer>
      <ReferralsToolbar />

      <InnerPageContainer>
        <ContentContainer>
          <InnerContentContainer>
            <ReferralsContent />
          </InnerContentContainer>
        </ContentContainer>
      </InnerPageContainer>
    </PageContainer>
  );
}
