import Joi from 'joi';

import { BoolSchema, MIN_PRICE } from '../../shared';
import { CreateActivityForm, UpdateActivityForm } from '../activity.model';
import { DiscountCodeItemValidation } from './discount-code';
import { MembershipTypeDiscountValidation } from './membership-discount';
import { RequiredMembershipsSchema } from './required-memberships';
import {
  TICKET_MAX_COUNT,
  TICKET_MIN_COUNT,
  TICKET_TYPE_NAME_MAX_LENGTH,
} from './ticket-types.constants';
import { TicketTypeErrors } from './ticket-types.errors';
import { MinTicketCounts, TicketType } from './ticket-types.model';

namespace TicketTypeUtils {
  export const price = (
    price: number,
    helpers: Joi.CustomHelpers<CreateActivityForm>,
  ) => {
    const ticketType: TicketType = helpers.state.ancestors[0];

    const membershipDiscounts = ticketType.membershipTypeDiscounts ?? [];
    const discounts = ticketType.discountCodes ?? [];

    for (let i = 0; i < membershipDiscounts.length; i++) {
      const membershipDiscount = membershipDiscounts[i];
      const discountedPrice = price - membershipDiscount.discount;

      if (discountedPrice < 0) {
        return helpers.error(
          TicketTypeErrors.customErrors.ticketPriceWithDiscountBelowMin,
        );
      }

      if (discountedPrice > 0 && discountedPrice < MIN_PRICE) {
        return helpers.error(
          TicketTypeErrors.customErrors.ticketPriceWithDiscountBelowMin,
        );
      }
    }

    for (let i = 0; i < discounts.length; i++) {
      const membershipDiscount = discounts[i];
      const discountedPrice = price - membershipDiscount.discount;

      if (discountedPrice < 0) {
        return helpers.error(
          TicketTypeErrors.customErrors.ticketPriceWithDiscountBelowMin,
        );
      }

      if (discountedPrice > 0 && discountedPrice < MIN_PRICE) {
        return helpers.error(
          TicketTypeErrors.customErrors.ticketPriceWithDiscountBelowMin,
        );
      }
    }

    if (price === 0) {
      return price;
    }

    if (price < MIN_PRICE) {
      return helpers.error(TicketTypeErrors.customErrors.ticketMinPrice);
    }

    return price;
  };

  export const endDate = (
    endDate: number,
    helpers: Joi.CustomHelpers<CreateActivityForm>,
  ) => {
    const ancestors = helpers.state.ancestors;

    const ticketType: TicketType = ancestors[0];
    const createActivityForm: CreateActivityForm = ancestors[3];

    if (createActivityForm.description.endDate < endDate) {
      return helpers.error(TicketTypeErrors.customErrors.endDateEvent);
    }

    if (ticketType.startDate > endDate) {
      return helpers.error(TicketTypeErrors.customErrors.startDate);
    }

    return endDate;
  };

  export const updateTicketCount =
    (minTicketCounts: MinTicketCounts) =>
    (ticketCount: number, helpers: Joi.CustomHelpers<UpdateActivityForm>) => {
      const ticketType: TicketType = helpers.state.ancestors[0];

      const minTicketCount = minTicketCounts[ticketType.ticketTypeKey];

      if (ticketType.ticketCount > TICKET_MAX_COUNT) {
        return helpers.error(TicketTypeErrors.customErrors.ticketCountAboveMax);
      }

      if (ticketType.ticketCount < 1) {
        return helpers.error(TicketTypeErrors.customErrors.ticketCountZero);
      }

      if (ticketType.ticketCount < minTicketCount) {
        return helpers.error(TicketTypeErrors.customErrors.ticketCount);
      }

      return ticketCount;
    };
}

export namespace TicketTypeSchema {
  export const ticketTypeKey = Joi.string().required();

  export const isTransferable = BoolSchema.required();

  export const isNew = BoolSchema.required();

  export const description = Joi.string()
    .required()
    .allow(null, '')
    .messages(TicketTypeErrors.description);

  export const price = Joi.number()
    .integer()
    .custom(TicketTypeUtils.price)
    .required()
    .messages(TicketTypeErrors.price);

  export const ticketCount = Joi.number()
    .integer()
    .min(TICKET_MIN_COUNT)
    .max(TICKET_MAX_COUNT)
    .required()
    .messages(TicketTypeErrors.ticketCount);

  export const maxTicketCountPerUser = Joi.number()
    .integer()
    .min(1)
    .max(Joi.ref('ticketCount'))
    .required()
    .messages(TicketTypeErrors.maxTicketCountPerUser);

  export const startDate = Joi.number()
    .integer()
    .min(Date.now())
    .required()
    .messages(TicketTypeErrors.startDate);

  export const endDateTicketForm = (activityEndDate: number) =>
    Joi.number()
      .integer()
      .min(Joi.ref('startDate'))
      .max(activityEndDate)
      .required()
      .messages(TicketTypeErrors.endDate);

  export const endDate = Joi.number()
    .integer()
    .min(Joi.ref('startDate'))
    .custom(TicketTypeUtils.endDate)
    .required()
    .messages(TicketTypeErrors.endDate);

  export const discountCodes = Joi.array()
    .items(DiscountCodeItemValidation)
    .allow(null)
    .required()
    .messages(TicketTypeErrors.discountCodes);

  export const membershipTypeDiscounts = Joi.array()
    .items(MembershipTypeDiscountValidation)
    .allow(null)
    .required()
    .messages(TicketTypeErrors.membershipTypeDiscounts);

  export const requiredMembershipTypes = Joi.object({
    combinationType: RequiredMembershipsSchema.combinationType,
    membershipApplicationRequirements:
      RequiredMembershipsSchema.membershipApplicationRequirements,
  })
    .allow(null)
    .required();

  export const updateStartDate = Joi.number()
    .required()
    .messages(TicketTypeErrors.startDate);

  export const updateTicketCount = (minTicketCounts: MinTicketCounts) =>
    Joi.number()
      .custom(TicketTypeUtils.updateTicketCount(minTicketCounts))
      .required()
      .messages(TicketTypeErrors.updateTicketCount);

  export const ticketTypeName = Joi.string()
    .max(TICKET_TYPE_NAME_MAX_LENGTH)
    .required()
    .messages(TicketTypeErrors.ticketTypeName);
}
