import {
  Box,
  Button,
  ContentSidebar,
  ContentSidebarContentContainer,
  Icon,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Text,
  Tooltip,
  parseTimestamp,
} from '@orbiapp/components';
import React from 'react';

import { QrScanToken } from '../../../../../models';
import {
  CreateQrScanTokenSelector,
  GetQrScanTokensSelector,
  createQrScanTokenThunk,
  deleteQrScanTokenThunk,
  useDispatch,
  useSelector,
} from '../../../../../store';
import { ShareScanLinkSidebarContext } from './share-scan-link-sidebar.context';

function GenerateLinkButton() {
  const createQrScanTokenStatus = useSelector(
    CreateQrScanTokenSelector.selectStatus,
  );

  const dispatch = useDispatch();

  const generateLink = () => {
    dispatch(createQrScanTokenThunk());
  };

  return (
    <Button
      isLoading={createQrScanTokenStatus === 'pending'}
      mx="auto"
      onClick={generateLink}
      tx="button.generate-scan-url"
      variant="primary"
    />
  );
}

function CopyToClipboard(props: { url: string }) {
  const { url } = props;

  const [hasCopied, setHasCopied] = React.useState(false);

  const copyToClipboard = () => {
    setHasCopied(true);
    navigator.clipboard.writeText(url);

    window.setTimeout(() => {
      setHasCopied(false);
    }, 2000);
  };

  return (
    <Tooltip placement="left" titleTx="label.general.copy-to-clipboard">
      {hasCopied ? (
        <Icon name="check-circle-solid" color="copyToClipBoardIconActive" />
      ) : (
        <IconButton icon="clipboard-outline" onClick={copyToClipboard} />
      )}
    </Tooltip>
  );
}

export function ShareScanLinkSidebar() {
  const { shareLinkSidebarIsOpen, closeSidebar } = React.useContext(
    ShareScanLinkSidebarContext,
  );

  const qrScanTokens = useSelector(GetQrScanTokensSelector.selectAll);

  const dispatch = useDispatch();

  const renderTableRow = (qrScanToken: QrScanToken) => {
    const deleteShareScanLink = () => {
      dispatch(deleteQrScanTokenThunk(qrScanToken.qrScanTokenKey));
    };

    return (
      <TableRow key={qrScanToken.qrScanTokenKey}>
        <TableCell
          width={140}
          text={parseTimestamp(qrScanToken.createdAt, 'DD MMM YYYY HH:mm')}
        />

        <TableCell width={130} text={qrScanToken.url} />

        <TableCell width={100} fixedRight>
          <Box width="100%" flexJustify="end" gap={16} flex>
            <CopyToClipboard url={qrScanToken.url} />

            <IconButton icon="trash-outline" onClick={deleteShareScanLink} />
          </Box>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <ContentSidebar
      width={470}
      isOpen={shareLinkSidebarIsOpen}
      onClose={closeSidebar}
    >
      <ContentSidebarContentContainer>
        <Box flex flexDirection="column" gap={4}>
          <Text
            variant="titleMd"
            tx="label.scan-qr-codes.scan-links-sidebar.title"
          />

          <Text
            variant="bodyMd"
            tx="label.scan-qr-codes.scan-links-sidebar.subtitle"
          />
        </Box>

        {qrScanTokens.length > 0 && (
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead tx="label.scan-qr-codes.scan-links-sidebar.table.created-at" />
                <TableHead tx="label.scan-qr-codes.scan-links-sidebar.table.url" />
                <TableHead fixedRight />
              </TableRow>
            </TableHeader>

            <TableBody>{qrScanTokens.map(renderTableRow)}</TableBody>
          </Table>
        )}

        <GenerateLinkButton />
      </ContentSidebarContentContainer>
    </ContentSidebar>
  );
}
