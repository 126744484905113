import { getFeatureFlagsThunk } from './feature-flags.actions';
import { FeatureFlagsBuilder } from './feature-flags.types';

const getFeatureFlagsBuild = (builder: FeatureFlagsBuilder) => {
  return builder
    .addCase(getFeatureFlagsThunk.fulfilled, (state, action) => {
      state.featureFlags.status = 'idle';
      state.featureFlags.data = action.payload;
    })
    .addCase(getFeatureFlagsThunk.pending, (state) => {
      state.featureFlags.status = 'pending';
      state.featureFlags.error = null;
    })
    .addCase(getFeatureFlagsThunk.rejected, (state, action) => {
      state.featureFlags.status = 'idle';
      state.featureFlags.error = action.error;
      state.featureFlags.data = {
        STUDENT_DASHBOARD_MEMLIST_MEMBERSHIP: false,
        STUDENT_DASHBOARD_SHOW_QUESTIONS: false,
        ACTIVITY_DRAFTS_ON_BACKEND: false,
      };
    });
};

export const extraReducers = (builder: FeatureFlagsBuilder) => {
  getFeatureFlagsBuild(builder);
};
