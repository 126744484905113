import {
  Avatar,
  Banner,
  Box,
  Card,
  Chip,
  ContentContainer,
  Icon,
  Image,
  InnerContentContainer,
  InnerPageContainer,
  List,
  ListItem,
  Status,
  StatusVariant,
  Text,
  Time,
  Tooltip,
  getAvatarVariantFromString,
  parseCurrency,
  parseTimestamp,
  translate,
} from '@orbiapp/components';
import React from 'react';

import {
  ActivityCategory,
  CoHost,
  CohostRequestStatus,
} from '../../../../../models';
import {
  ActivityDataSelector,
  ActivitySelector,
  DepartmentSelector,
  DynamicActivityLinkSelector,
  OrbiPaySettingsSelector,
  useSelector,
} from '../../../../../store';
import {
  getActivityStatusOptions,
  getCoHostingStatusTx,
  joinOrgNodeBranchNames,
} from '../../../../../utils';
import { EventActions } from '../components';

function getCoHostingStatusVariant(status: CohostRequestStatus): StatusVariant {
  switch (status) {
    case 'accepted':
      return 'success';
    case 'rejected':
      return 'error';
    case 'canceled':
      return 'warning';
    default:
      return 'info';
  }
}

function ActivityStatusListItem() {
  const startDate = useSelector(ActivityDataSelector.selectStartDate);
  const endDate = useSelector(ActivityDataSelector.selectEndDate);

  if (startDate === null || endDate === null) {
    return null;
  }

  return (
    <ListItem>
      <Box flex flexDirection="column">
        <Text tx="label.view-activity.details-list.status" variant="bodyMd" />
        <Status {...getActivityStatusOptions({ startDate, endDate })} />
      </Box>
    </ListItem>
  );
}

function ActivityCategories() {
  const categories = useSelector(ActivityDataSelector.selectCategories);

  if (!categories) {
    return null;
  }

  const renderCategory = (category: ActivityCategory) => {
    return (
      <Chip variant="secondary" key={category.name} text={category.name} />
    );
  };

  return (
    <ListItem>
      <Box flex flexDirection="column">
        <Text
          tx="label.view-activity.details-list.categories"
          variant="bodyMd"
        />
        <Box flex gap={8}>
          {categories.map(renderCategory)}
        </Box>
      </Box>
    </ListItem>
  );
}

function StartEndDateListItem() {
  const startDate = useSelector(ActivityDataSelector.selectStartDate);
  const endDate = useSelector(ActivityDataSelector.selectEndDate);

  if (
    startDate === null ||
    endDate === null ||
    (startDate === 0 && endDate === 0)
  ) {
    return null;
  }

  return (
    <ListItem
      titleTx="label.view-activity.details-list.start-end-date"
      subtitle={`${parseTimestamp(
        startDate,
        'DD MMM YYYY HH:mm',
      )} - ${parseTimestamp(endDate, 'DD MMM YYYY HH:mm')}`}
    />
  );
}

function ActivityContactEmailListItem() {
  const contactDetails = useSelector(ActivityDataSelector.selectContactDetails);

  if (!contactDetails) {
    return null;
  }

  return (
    <ListItem
      titleTx="label.view-activity.details-list.email"
      subtitle={contactDetails.email}
    />
  );
}

function ActivityLocationListItem() {
  const location = useSelector(ActivityDataSelector.selectLocation);

  if (!location) {
    return (
      <ListItem
        titleTx="label.view-activity.details-list.location"
        subtitleTx="placeholder.empty-location"
      />
    );
  }

  return (
    <ListItem
      titleTx="label.view-activity.details-list.location"
      subtitle={location.label}
    />
  );
}

function RevenueListItem() {
  const currency = useSelector(OrbiPaySettingsSelector.selectCurrency);
  const totalRevenue = useSelector(ActivityDataSelector.selectTotalRevenue);
  const hasPaidTickets = useSelector(
    ActivityDataSelector.selectHasPayedTickets,
  );

  if (!hasPaidTickets) {
    return null;
  }

  return (
    <ListItem
      subtitle={parseCurrency(totalRevenue, currency)}
      titleTx="label.view-activity.details-list.revenue"
    />
  );
}

function TicketsListItem() {
  const totalTicketCount = useSelector(
    ActivityDataSelector.selectTotalTicketCount,
  );
  const numberOfSoldTickets = useSelector(
    ActivityDataSelector.selectNumberOfSoldTickets,
  );
  const ticketCount = useSelector(ActivityDataSelector.selectTotalTicketCount);

  if (ticketCount === 0) {
    return null;
  }

  return (
    <ListItem
      subtitle={`${numberOfSoldTickets}/${totalTicketCount}`}
      titleTx="label.view-activity.details-list.tickets"
    />
  );
}

function NumberOfSavedListItem() {
  const numberOfSaved = useSelector(ActivityDataSelector.selectNumberOfSaved);

  return (
    <ListItem
      subtitle={numberOfSaved.toString()}
      titleTx="label.view-activity.details-list.saved"
    />
  );
}

function CoverImage() {
  const activityCoverImageUrl = useSelector(
    ActivityDataSelector.selectCoverImageUrl,
  );

  if (!activityCoverImageUrl) {
    return null;
  }

  return (
    <Box height="fit-content" width={500} maxWidth="100%">
      <Image height="100%" width="100%" r={8} src={activityCoverImageUrl} />
    </Box>
  );
}

function CopyToClipboardListItem() {
  const dynamicLink = useSelector(
    DynamicActivityLinkSelector.selectDynamicLink,
  );

  const [hasCopied, setHasCopied] = React.useState(false);

  if (!dynamicLink) {
    return null;
  }

  const copyDynamicLinkToClipboard = () => {
    setHasCopied(true);

    navigator.clipboard.writeText(dynamicLink);

    window.setTimeout(() => {
      setHasCopied(false);
    }, 2000);
  };

  return (
    <ListItem>
      <Box flex flexDirection="column">
        <Text
          tx="label.view-activity.details-list.activity-url"
          variant="bodyMd"
        />

        <Box
          cursor="pointer"
          flex
          flexAlign="center"
          gap={8}
          onClick={copyDynamicLinkToClipboard}
          relative
        >
          <Tooltip titleTx="label.general.copy-to-clipboard" placement="top">
            <Text text={dynamicLink} variant="bodySm" />
          </Tooltip>

          <Icon
            color={
              hasCopied ? 'copyToClipBoardIconActive' : 'copyToClipBoardIcon'
            }
            name="check-circle-solid"
            size={16}
          />
        </Box>
      </Box>
    </ListItem>
  );
}

function ActivityDescriptionHeader() {
  const activityDescription = useSelector(
    ActivityDataSelector.selectDescription,
  );

  return (
    <Box flex flexDirection="column" gap={16}>
      <Box
        flex
        flexAlign="start"
        flexJustify="between"
        flexWrap="wrap"
        gap={16}
      >
        <Box flex flexDirection="column" gap={4}>
          <Text
            as="h1"
            color="pageTitle"
            tx="label.view-activity.tabs.description"
            variant="titleMd"
          />

          {activityDescription && (
            <Text
              maxWidth="100ch"
              wordBreak="pre-wrap"
              text={activityDescription}
              variant="bodyMd"
              whiteSpace="break-spaces"
            />
          )}
        </Box>

        <EventActions />
      </Box>
    </Box>
  );
}

function renderActivityCoHostCard(coHost: CoHost) {
  return (
    <Card key={coHost.departmentKey}>
      <Box gap={16} flex>
        <Avatar
          src={coHost.logoUrl ?? undefined}
          fallbackLetter={coHost.name[0]}
          variant={getAvatarVariantFromString(coHost.departmentKey)}
        />

        <Box flex flexDirection="column" gap={4}>
          <Text maxWidth="40ch" text={coHost.name} />
          <Text
            variant="bodySm"
            maxWidth="40ch"
            text={joinOrgNodeBranchNames([...coHost.orgNodesBranch])}
          />
        </Box>
      </Box>

      <Status
        tx={getCoHostingStatusTx(coHost.cohostRequestStatus)}
        variant={getCoHostingStatusVariant(coHost.cohostRequestStatus)}
      />
    </Card>
  );
}

function ActivityCoHosts() {
  const activityCoHosts = useSelector(ActivityDataSelector.selectCohosts);

  if (!activityCoHosts) {
    return null;
  }

  return (
    <Box flex gap={16} flexDirection="column">
      {activityCoHosts.map(renderActivityCoHostCard)}
    </Box>
  );
}

function Segmentation() {
  const segmentation = useSelector(ActivityDataSelector.selectSegmentation);
  const segmentationType = useSelector(
    DepartmentSelector.selectSegmentationType,
  );

  const renderSegmentationValue = (segmentationValue: number) => {
    return (
      <Chip
        variant="primary"
        key={segmentationValue}
        tx={segmentationType === 'semester' ? 'units.semester' : 'units.year'}
        txArgs={{ [segmentationType]: segmentationValue }}
      />
    );
  };

  const segmentationValues =
    segmentationType === 'semester'
      ? segmentation
      : segmentation
          .filter((value) => value % 2 === 0)
          .map((value) => value / 2);

  return (
    <Box gap={8} flexWrap="wrap" flex>
      {segmentationValues.map(renderSegmentationValue)}
    </Box>
  );
}

function formatStartDate(startDate: number) {
  const diff = startDate - Date.now();
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((diff % (1000 * 60)) / 1000);

  const parts: string[] = [];

  if (days > 0) {
    parts.push(`${days}d`);
  }

  if (hours > 0) {
    parts.push(`${hours}h`);
  }

  if (minutes > 0) {
    parts.push(`${minutes}m`);
  }

  if (seconds > 0) {
    parts.push(`${seconds}s`);
  }

  return parts.join(' ');
}

function useCountDownValue<T>(
  getValue: () => T,
  options?: {
    defaultValue?: T;
    interval?: number;
    unmountWhen?: () => boolean;
  },
) {
  const [value, setValue] = React.useState<T | undefined>(
    options?.defaultValue,
  );

  const intervalRef = React.useRef<number>();

  React.useEffect(() => {
    if (options?.unmountWhen?.()) {
      window.clearInterval(intervalRef.current);
      return;
    }

    intervalRef.current = window.setInterval(
      () => setValue(getValue()),
      options?.interval ?? Time.Second,
    );

    return () => window.clearInterval(intervalRef.current);
  }, [getValue, options, options?.interval]);

  return value;
}

function ScanTicketsBanner() {
  const activityKey = useSelector(ActivityDataSelector.selectActivityKey);
  const endDate = useSelector(ActivityDataSelector.selectEndDate);
  const startDate = useSelector(ActivityDataSelector.selectStartDate);

  const getEventIsOnGoing = () => {
    if (!startDate || !endDate) {
      return false;
    }

    const now = Date.now();
    const eventIsOnGoing = now >= startDate - Time.Second && now < endDate;

    return eventIsOnGoing;
  };

  const getStartDateStr = () => {
    if (!startDate || !endDate) {
      return '';
    }

    const eventIsOnGoing = getEventIsOnGoing();

    return translate(
      eventIsOnGoing
        ? 'label.scan-qr-codes.scan-qr-codes-alert.event-countdown.ongoing'
        : `label.scan-qr-codes.scan-qr-codes-alert.event-countdown.upcoming`,
      {
        date: formatStartDate(startDate),
      },
    );
  };

  const startDateStr = useCountDownValue(getStartDateStr, {
    defaultValue: getStartDateStr(),
    unmountWhen: getEventIsOnGoing,
  });

  const showConsumeTicketsAlert =
    startDate &&
    endDate &&
    new Date().getTime() >= startDate - Time.Hour &&
    new Date().getTime() < endDate;

  if (!showConsumeTicketsAlert) {
    return null;
  }

  return (
    <Banner
      buttonTo={`/activities/${activityKey}/consume-tickets/pick-scan-device`}
      buttonTx="label.scan-qr-codes.scan-qr-codes-alert.scan-tickets"
      message={startDateStr}
      messageTxArgs={{ date: formatStartDate(startDate ?? 0) }}
      titleTx="label.scan-qr-codes.scan-qr-codes-alert.label"
      variant="information"
    />
  );
}

function NotOwnerBanner() {
  const isActivityOwner = useSelector(ActivitySelector.selectIsActivityOwner);

  if (isActivityOwner) {
    return null;
  }

  return (
    <Banner
      variant="information"
      messageTx="label.view-activity.info.not-owner"
    />
  );
}

export function ViewActivityDescription() {
  return (
    <InnerPageContainer>
      <ContentContainer>
        <InnerContentContainer>
          <NotOwnerBanner />

          <ScanTicketsBanner />

          <ActivityDescriptionHeader />

          <Segmentation />

          <Box flexWrap="wrap" gap={32} flex>
            <Box maxWidth="100%" flex flexDirection="column" gap={32}>
              <CoverImage />

              <ActivityCoHosts />
            </Box>

            <List minWidth={300} maxWidth="100%" width="30%">
              <ActivityStatusListItem />
              <ActivityCategories />
              <StartEndDateListItem />
              <ActivityLocationListItem />
              <ActivityContactEmailListItem />
              <RevenueListItem />
              <TicketsListItem />
              <NumberOfSavedListItem />
              <CopyToClipboardListItem />
            </List>
          </Box>
        </InnerContentContainer>
      </ContentContainer>
    </InnerPageContainer>
  );
}
