import { GeneralApiResultWithData } from '@orbiapp/components';

import {
  CreateActivityForm,
  CreateActivityResponse,
} from '../../../../../models';
import { Logger } from '../../../../logger';
import { apisauce } from '../../../api';
import { CUSTOM_TIMEOUTS } from '../../../api.constants';
import { getApiLogExtras, getGeneralApiProblem } from '../../../api.utils';
import { CreateActivityResponseDto } from './create-activity.dto';
import { createActivityMapper } from './create-activity.mappers';

export const createActivity = async (
  createActivityForm: CreateActivityForm,
): Promise<GeneralApiResultWithData<CreateActivityResponse>> => {
  const res = await apisauce.post<CreateActivityResponseDto, any>(
    '/v1/activities',
    createActivityMapper(createActivityForm),
    {
      timeout: CUSTOM_TIMEOUTS.createActivity,
    },
  );

  if (!res.ok) {
    Logger.warning('createActivity', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data: CreateActivityResponse = {
      activityKey: res.data.activityKey,
      coverImage: res.data.coverImage,
    };

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('createActivity', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};
