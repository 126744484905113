import {
  Box,
  Button,
  Confirm,
  ContentContainer,
  InnerContentContainer,
  InnerPageContainer,
  Link,
  PageContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TablePlaceholderRows,
  TableRow,
  Text,
  parseTimestamp,
  useConfirm,
} from '@orbiapp/components';
import React from 'react';
import { Navigate } from 'react-router';

import { ActivityDraft } from '../../../models';
import {
  DepartmentSelector,
  FeatureFlagsSelector,
  LocalActivityDraftsSelector,
  convertLocalActivityDraftThunk,
  deleteLocalActivityDraftThunk,
  useDispatch,
  useSelector,
} from '../../../store';

const TABLE_COLUMN_WIDTHS = {
  updatedAtDate: 100,
  title: 100,
  startDate: 100,
  description: 150,
  actions: 200,
};

const DeleteActivityDraftConfirmContext = React.createContext<
  ReturnType<typeof useConfirm<{ activityDraftKey: string; title: string }>>
>({
  closeConfirm: () => {},
  confirmValue: null,
  isOpen: false,
  openConfirm: () => {},
});

const ConvertActivityDraftConfirmContext = React.createContext<
  ReturnType<typeof useConfirm<{ activityDraftKey: string; title: string }>>
>({
  closeConfirm: () => {},
  confirmValue: null,
  isOpen: false,
  openConfirm: () => {},
});

function DeleteActivityDraftProvider(props: React.PropsWithChildren) {
  const { children } = props;

  const confirmState = useConfirm<{
    activityDraftKey: string;
    title: string;
  }>();

  return (
    <DeleteActivityDraftConfirmContext.Provider value={confirmState}>
      {children}
    </DeleteActivityDraftConfirmContext.Provider>
  );
}

function ConvertActivityDraftProvider(props: React.PropsWithChildren) {
  const { children } = props;

  const confirmState = useConfirm<{
    activityDraftKey: string;
    title: string;
  }>();

  return (
    <ConvertActivityDraftConfirmContext.Provider value={confirmState}>
      {children}
    </ConvertActivityDraftConfirmContext.Provider>
  );
}

function DeleteActivityDraftConfirm() {
  const { confirmValue, closeConfirm, isOpen } = React.useContext(
    DeleteActivityDraftConfirmContext,
  );

  const dispatch = useDispatch();

  const [deleteActivityDraftIsLoading, setDeleteActivityDraftIsLoading] =
    React.useState(false);

  if (!confirmValue) {
    return null;
  }

  const deleteActivityDraft = async () => {
    setDeleteActivityDraftIsLoading(true);

    const res = await dispatch(
      deleteLocalActivityDraftThunk({
        draftId: confirmValue.activityDraftKey,
      }),
    );

    setDeleteActivityDraftIsLoading(false);

    if (res.meta.requestStatus === 'fulfilled') {
      closeConfirm();
    }
  };

  return (
    <Confirm
      cancelTx="prompt.delete-activity-draft.cancel"
      confirmTx="prompt.delete-activity-draft.confirm"
      isOpen={isOpen}
      messageTx="prompt.delete-activity-draft.message-with-title"
      messageTxArgs={{ title: confirmValue.title }}
      onCancel={closeConfirm}
      onConfirm={deleteActivityDraft}
      titleTx="prompt.delete-activity-draft.title"
      isLoading={deleteActivityDraftIsLoading}
    />
  );
}

function ConvertActivityDraftConfirm() {
  const { confirmValue, closeConfirm, isOpen } = React.useContext(
    ConvertActivityDraftConfirmContext,
  );

  const dispatch = useDispatch();

  const [convertActivityDraftIsLoading, setConvertActivityDraftIsLoading] =
    React.useState(false);

  if (!confirmValue) {
    return null;
  }

  const convertActivityDraft = async () => {
    setConvertActivityDraftIsLoading(true);

    const res = await dispatch(
      convertLocalActivityDraftThunk(confirmValue.activityDraftKey),
    );

    setConvertActivityDraftIsLoading(false);

    if (res.meta.requestStatus === 'fulfilled') {
      closeConfirm();
    }
  };

  return (
    <Confirm
      cancelTx="prompt.convert-activity-draft.cancel"
      confirmTx="prompt.convert-activity-draft.confirm"
      isOpen={isOpen}
      messageTx="prompt.convert-activity-draft.message-with-title"
      messageTxArgs={{ title: confirmValue.title }}
      onCancel={closeConfirm}
      onConfirm={convertActivityDraft}
      titleTx="prompt.convert-activity-draft.title"
      confirmButttonVariant="primary"
      isLoading={convertActivityDraftIsLoading}
    />
  );
}

function ActivityDraftTableRow(
  props: ActivityDraft & { activityDraftKey: string },
) {
  const { activityDraftKey, data, lastUpdated } = props;

  const { openConfirm: openDeleteDraftConfirm } = React.useContext(
    DeleteActivityDraftConfirmContext,
  );
  const { openConfirm: openConvertDraftConfirm } = React.useContext(
    ConvertActivityDraftConfirmContext,
  );

  const openConfirmDeleteDraft = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();

    openDeleteDraftConfirm({
      activityDraftKey,
      title:
        data.description.title.length > 0
          ? `"${data.description.title}"`
          : `"draft updated at ${parseTimestamp(
              lastUpdated,
              'DD MMM YYYY HH:mm',
            )}"`,
    });
  };

  const openConfirmConvertDraft = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();

    openConvertDraftConfirm({
      activityDraftKey,
      title:
        data.description.title.length > 0
          ? `"${data.description.title}"`
          : `"draft updated at ${parseTimestamp(
              lastUpdated,
              'DD MMM YYYY HH:mm',
            )}"`,
    });
  };

  return (
    <TableRow>
      <TableCell
        width={TABLE_COLUMN_WIDTHS.updatedAtDate}
        text={parseTimestamp(lastUpdated, 'DD MMM YYYY HH:mm')}
      />

      <TableCell
        width={TABLE_COLUMN_WIDTHS.title}
        text={data.description.title}
      />

      <TableCell
        width={TABLE_COLUMN_WIDTHS.startDate}
        text={parseTimestamp(data.description.startDate, 'DD MMM YYYY HH:mm')}
      />
      <TableCell
        width={TABLE_COLUMN_WIDTHS.description}
        text={data.description.description}
      />

      <TableCell width={TABLE_COLUMN_WIDTHS.actions}>
        <Box flex gap={16} width="100%" flexJustify="end">
          <Button
            tx="button.delete"
            onClick={openConfirmDeleteDraft}
            variant="destructive"
          />
          <Button
            tx="button.convert"
            onClick={openConfirmConvertDraft}
            variant="secondary"
          />
        </Box>
      </TableCell>
    </TableRow>
  );
}

function renderActivityDraftTableRow(activityDraft: ActivityDraft) {
  return (
    <ActivityDraftTableRow
      activityDraftKey={activityDraft.key}
      {...activityDraft}
    />
  );
}

function ConvertDraftsTable() {
  const localActivityDrafts = useSelector(
    LocalActivityDraftsSelector.selectData,
  );

  if (localActivityDrafts === null) {
    return (
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead tx="label.convert-drafts.table.updated-at" />
            <TableHead tx="label.convert-drafts.table.title" />
            <TableHead tx="label.convert-drafts.table.start-date" />
            <TableHead tx="label.convert-drafts.table.description" />
            <TableHead />
          </TableRow>
        </TableHeader>

        <TableBody>
          <TablePlaceholderRows
            rowCount={10}
            layout={Object.values(TABLE_COLUMN_WIDTHS)}
          />
        </TableBody>
      </Table>
    );
  }

  if (localActivityDrafts?.length === 0) {
    return (
      <Box
        flex
        flexJustify="center"
        gap={8}
        flexDirection="column"
        flexAlign="start"
      >
        <Text
          tx="label.convert-drafts.no-drafts"
          variant="bodyMd"
          color="convertDraftsText"
        />
        <Link to="/activities" tx="link.general.go-to-my-events" />
      </Box>
    );
  }

  return (
    <React.Fragment>
      <DeleteActivityDraftConfirm />

      <ConvertActivityDraftConfirm />

      <Table>
        <TableHeader>
          <TableRow>
            <TableHead tx="label.convert-drafts.table.updated-at" />
            <TableHead tx="label.convert-drafts.table.title" />
            <TableHead tx="label.convert-drafts.table.start-date" />
            <TableHead tx="label.convert-drafts.table.description" />
            <TableHead />
          </TableRow>
        </TableHeader>

        <TableBody>
          {localActivityDrafts.map(renderActivityDraftTableRow)}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}

function ConvertDraftsContent() {
  const localActivityDrafts = useSelector(
    LocalActivityDraftsSelector.selectData,
  );

  const departmentName = useSelector(DepartmentSelector.selectName);

  return (
    <Box flex flexDirection="column" gap={32}>
      <Text
        color="pageTitle"
        tx="title.convert-drafts.dashboard"
        variant="titleMd"
        as="h1"
      />

      {!!localActivityDrafts?.length && (
        <Box flex gap={8} flexDirection="column">
          <Text
            tx="label.convert-drafts.found-drafts"
            txArgs={{ drafts: `${localActivityDrafts?.length ?? ''}` }}
            variant="bodyMd"
            color="convertDraftsText"
          />
          <Text
            tx="label.convert-drafts.description"
            txArgs={{ departmentName: departmentName ?? '' }}
            variant="bodyMd"
            color="convertDraftsText"
          />
        </Box>
      )}

      <ConvertDraftsTable />
    </Box>
  );
}

export function ConvertDrafts() {
  const useBackendStorage = useSelector(
    FeatureFlagsSelector.selectUseBackendStorage,
  );

  if (!useBackendStorage) {
    return <Navigate to="/activities" />;
  }

  return (
    <DeleteActivityDraftProvider>
      <ConvertActivityDraftProvider>
        <PageContainer>
          <InnerPageContainer>
            <ContentContainer>
              <InnerContentContainer>
                <ConvertDraftsContent />
              </InnerContentContainer>
            </ContentContainer>
          </InnerPageContainer>
        </PageContainer>
      </ConvertActivityDraftProvider>
    </DeleteActivityDraftProvider>
  );
}
