import {
  Image,
  Link,
  Toolbar,
  ToolbarContentContainer,
} from '@orbiapp/components';

import { assets } from '../../../assets';

export function SignedOutToolbar() {
  return (
    <Toolbar backgroundColor="toolbarBackground">
      <ToolbarContentContainer py={8} flex flexJustify="between">
        <Link plain to="/sign-in">
          <Image alt="" src={assets.orbiLogoOrange} />
        </Link>
      </ToolbarContentContainer>
    </Toolbar>
  );
}
