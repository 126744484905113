import {
  Avatar,
  Box,
  BreadCrumb,
  BreadCrumbs,
  Confirm,
  ContentContainer,
  EmptyState,
  IconButton,
  InnerContentContainer,
  InnerPageContainer,
  PageContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TablePlaceholderRows,
  TableRow,
  Text,
  Toolbar,
  ToolbarContentContainer,
  Tooltip,
  translate,
  useConfirm,
} from '@orbiapp/components';
import React from 'react';

import { JobListItem } from '../../../models';
import {
  ExternalJobsSelector,
  HideJobSelector,
  getExternalJobsThunk,
  hideJobThunk,
  useDispatch,
  useSelector,
} from '../../../store';

const HideJobContext = React.createContext<
  ReturnType<typeof useConfirm<string>>
>({
  isOpen: false,
  openConfirm: () => {},
  closeConfirm: () => {},
  confirmValue: null,
});

function HideJobProvider(props: React.PropsWithChildren) {
  const { children } = props;

  const confirmState = useConfirm<string>();

  return (
    <HideJobContext.Provider value={confirmState}>
      {children}
    </HideJobContext.Provider>
  );
}

function ExternalJobsHeader() {
  return (
    <TableHeader>
      <TableRow>
        <TableHead tx="label.jobs.table.name" />
        <TableHead tx="label.jobs.table.title" />
        <TableHead tx="label.jobs.table.clicks" />
        <TableHead tx="label.jobs.table.hidden" />
        <TableHead fixedRight />
      </TableRow>
    </TableHeader>
  );
}

const TABLE_COLUMN_WIDTHS = {
  companyName: 250,
  title: 300,
  clicks: 100,
  isHidden: 100,
  actions: 60,
};

function ExternalJobsTableRow(props: JobListItem) {
  const { jobKey, companyName, logo, title, stats, isHidden } = props;

  const { openConfirm } = React.useContext(HideJobContext);

  const handleHideJobClick = () => {
    openConfirm(jobKey);
  };

  return (
    <React.Fragment>
      <TableRow key={jobKey}>
        <TableCell width={TABLE_COLUMN_WIDTHS.companyName}>
          <Box flex gap={8} flexAlign="center">
            <Avatar src={logo.thumbnail64.url} />
            <Text as="span" variant="bodySm" text={companyName} />
          </Box>
        </TableCell>
        <TableCell width={TABLE_COLUMN_WIDTHS.title} text={title} />
        <TableCell
          width={TABLE_COLUMN_WIDTHS.clicks}
          text={stats.clicks || 0}
        />
        <TableCell
          width={TABLE_COLUMN_WIDTHS.isHidden}
          text={
            isHidden
              ? translate('label.boolean.yes')
              : translate('label.boolean.no')
          }
        />
        <TableCell width={TABLE_COLUMN_WIDTHS.actions} hoverCell fixedRight>
          {!isHidden && (
            <Box flex flexJustify="end" width="100%">
              <Tooltip
                placement="left"
                titleTx="label.jobs.table.actions.hide-job"
              >
                <IconButton
                  onClick={handleHideJobClick}
                  icon="eye-slash-outline"
                />
              </Tooltip>
            </Box>
          )}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function HideJobConfirm() {
  const hideJobStatus = useSelector(HideJobSelector.selectStatus);

  const dispatch = useDispatch();

  const { closeConfirm, isOpen, confirmValue } =
    React.useContext(HideJobContext);

  const hideJob = async () => {
    if (!confirmValue) return;

    await dispatch(hideJobThunk(confirmValue));

    closeConfirm();
  };

  return (
    <Confirm
      cancelTx="prompt.hide-job.cancel"
      confirmTx="prompt.hide-job.confirm"
      isLoading={hideJobStatus === 'pending'}
      isOpen={isOpen}
      messageTx="prompt.hide-job.message"
      onCancel={closeConfirm}
      onConfirm={hideJob}
      titleTx="prompt.hide-job.title"
    />
  );
}

function renderExternalJobTableRow(job: JobListItem) {
  return <ExternalJobsTableRow key={job.jobKey} {...job} />;
}

function ExternalJobs() {
  const externalJobs = useSelector(ExternalJobsSelector.selectAll);
  const externalJobsStatus = useSelector(ExternalJobsSelector.selectStatus);
  const isLoading = externalJobsStatus === 'pending';

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getExternalJobsThunk());
  }, [dispatch]);

  if (externalJobs.length === 0 && !isLoading) {
    return (
      <Box flex flexDirection="column" gap={16}>
        <Text tx="title.jobs.external-jobs" variant="titleSm" />

        <EmptyState titleTx="label.jobs.external-jobs-empty-state.title" />
      </Box>
    );
  }

  if (externalJobs.length === 0 && isLoading) {
    return (
      <Table>
        <ExternalJobsHeader />
        <TableBody>
          <TablePlaceholderRows
            rowCount={10}
            layout={Object.values(TABLE_COLUMN_WIDTHS)}
          />
        </TableBody>
      </Table>
    );
  }

  return (
    <React.Fragment>
      <HideJobConfirm />

      <Table>
        <ExternalJobsHeader />
        <TableBody>{externalJobs.map(renderExternalJobTableRow)}</TableBody>
      </Table>
    </React.Fragment>
  );
}

function JobsToolbar() {
  return (
    <Toolbar backgroundColor="tabHeaderBackground" zIndex={1}>
      <ToolbarContentContainer>
        <BreadCrumbs>
          <BreadCrumb tx="label.breadcrumbs.jobs.jobs" isLast />
        </BreadCrumbs>
      </ToolbarContentContainer>
    </Toolbar>
  );
}

export function Jobs() {
  return (
    <HideJobProvider>
      <PageContainer>
        <JobsToolbar />

        <InnerPageContainer>
          <ContentContainer>
            <InnerContentContainer>
              <Text
                color="pageTitle"
                tx="title.jobs.dashboard"
                variant="titleMd"
                as="h1"
              />

              <ExternalJobs />
            </InnerContentContainer>
          </ContentContainer>
        </InnerPageContainer>
      </PageContainer>
    </HideJobProvider>
  );
}
