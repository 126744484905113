import {
  Banner,
  Box,
  Button,
  EmulateUserSessionStorage,
  ImageSliderModal,
  ImageSliderModalSlide,
  InternetStatusBanner,
  MainContainer,
  OrbiLoader,
  Text,
  Trans,
  WorkspacesSessionStorage,
  translate,
} from '@orbiapp/components';
import i18next from 'i18next';
import React from 'react';
import { Outlet } from 'react-router-dom';

import { assets } from '../../assets';
import { ConvertDraftsBanner, OrbiPayBanner } from '../../components';
import { useTracking } from '../../helpers';
import {
  AccountSelector,
  AuthAccountSelector,
  AuthStateSelector,
  DepartmentSelector,
  FeatureFlagsSelector,
  OrbiPaySettingsSelector,
  authActions,
  getAccountThunk,
  getAuthAccountThunk,
  getDepartmentThunk,
  getFeatureFlagsThunk,
  getOrbiPaySettingsThunk,
  useDispatch,
  useSelector,
} from '../../store';
import { isAnyPending } from '../../utils';

function EmulateUserBanner() {
  const userEmail = useSelector(AccountSelector.selectEmail);
  const isEmulating = useSelector(AuthStateSelector.selectIsEmulating);

  const dispatch = useDispatch();

  const [isClosed, setIsClosed] = React.useState(false);

  if (isClosed || !userEmail || !isEmulating) {
    return null;
  }

  const stopEmulatingUser = async () => {
    dispatch(authActions.setIsEmulating(false));

    EmulateUserSessionStorage.endSession();
    WorkspacesSessionStorage.endSession();

    await dispatch(getAuthAccountThunk());
    await dispatch(getAccountThunk());
    await dispatch(getFeatureFlagsThunk());
    dispatch(getDepartmentThunk());
  };

  const closeBanner = () => {
    setIsClosed(true);
  };

  return (
    <Banner
      buttonOnClick={stopEmulatingUser}
      buttonTx="button.cancel"
      message={userEmail}
      onClose={closeBanner}
      titleTx="label.emulate-user-banner.title"
      variant="warning"
      zIndex={200}
    />
  );
}

const membershipsBulkInviteToolModalSlides: ImageSliderModalSlide[] = [
  {
    type: 'image',
    src: assets.membershipsBulkInviteToolModal.membershipsBulkInviteToolModal1,
    alt: translate(
      'label.memberships-bulk-invite-tool-modal.slide-1-message-1',
    ),
    descriptionElement: (
      <Box flex flexDirection="column" gap={4} flexAlign="center">
        <Text variant="bodyMd" mt={24}>
          <Trans
            i18nKey="label.memberships-bulk-invite-tool-modal.slide-1-message-1"
            components={{
              bold: <Text as="span" variant="bodyMdBold" />,
            }}
          />
        </Text>
        <Text
          variant="bodyMd"
          tx="label.memberships-bulk-invite-tool-modal.slide-1-message-2"
        />
      </Box>
    ),
  },
  {
    type: 'image',
    src: assets.membershipsBulkInviteToolModal.membershipsBulkInviteToolModal2,
    alt: translate('label.memberships-bulk-invite-tool-modal.slide-2-message'),
    descriptionElement: (
      <Box flex flexDirection="column" flexAlign="center">
        <Text variant="bodyMd" mt={24}>
          <Trans
            i18nKey="label.memberships-bulk-invite-tool-modal.slide-2-message"
            components={{
              bold: <Text as="span" variant="bodyMdBold" />,
            }}
          />
        </Text>
      </Box>
    ),
  },
  {
    type: 'image',
    src: assets.membershipsBulkInviteToolModal.membershipsBulkInviteToolModal3,
    alt: translate('label.memberships-bulk-invite-tool-modal.slide-3-message'),
    descriptionElement: (
      <Box flex flexDirection="column" flexAlign="center">
        <Text variant="bodyMd" mt={24}>
          <Trans
            i18nKey="label.memberships-bulk-invite-tool-modal.slide-3-message"
            components={{
              bold: <Text as="span" variant="bodyMdBold" />,
            }}
          />
        </Text>
      </Box>
    ),
  },
];

function MembershipsBulkInviteToolModal() {
  const [isOpen, setIsOpen] = React.useState(
    !localStorage.getItem('membershipsInviteModal'),
  );

  const authenticated = useSelector(AuthStateSelector.selectAuthenticated);

  if (!authenticated) {
    return null;
  }

  const handleClose = () => {
    setIsOpen(false);
    localStorage.setItem('membershipsInviteModal', 'false');
  };

  return (
    <ImageSliderModal
      width={700}
      isOpen={isOpen}
      onClose={handleClose}
      slides={membershipsBulkInviteToolModalSlides}
      titleTx="label.memberships-bulk-invite-tool-modal.title"
      footerElement={
        <Button
          onClick={handleClose}
          tx="button.explore-memberships"
          variant="primary"
          to="/memberships"
        />
      }
    />
  );
}

/**
 * Every route in the app is rendered within the `MainOutlet` component.
 */
export function MainOutlet() {
  useTracking();

  const initializing = useSelector(AuthStateSelector.selectInitializing);
  const authenticated = useSelector(AuthStateSelector.selectAuthenticated);
  const authAccountStatus = useSelector(AuthAccountSelector.selectStatus);

  const language = useSelector(AccountSelector.selectLanguage);
  const accountStatus = useSelector(AccountSelector.selectStatus);
  const hasCorrectRole = useSelector(AccountSelector.selectHasCorrectRole);

  const featureFlags = useSelector(FeatureFlagsSelector.selectFeatureFlags);
  const featureFlagsStatus = useSelector(FeatureFlagsSelector.selectStatus);

  const departmentStatus = useSelector(DepartmentSelector.selectStatus);

  const orbiPayStatus = useSelector(OrbiPaySettingsSelector.selectStatus);

  const dispatch = useDispatch();

  const isLoadingEssentials = isAnyPending(
    featureFlagsStatus,
    accountStatus,
    departmentStatus,
    authAccountStatus,
    orbiPayStatus,
  );

  const getEssentials = React.useCallback(async () => {
    if (!authenticated) return;
    await dispatch(getAccountThunk());
    dispatch(getFeatureFlagsThunk());
    dispatch(getAuthAccountThunk());
  }, [authenticated, dispatch]);

  React.useEffect(() => {
    if (!hasCorrectRole) return;
    dispatch(getDepartmentThunk());
    dispatch(getOrbiPaySettingsThunk());
  }, [hasCorrectRole, dispatch]);

  React.useEffect(() => {
    getEssentials();
  }, [getEssentials]);

  React.useEffect(() => {
    if (!language) return;
    i18next.changeLanguage(language);
  }, [language]);

  if (
    initializing ||
    isLoadingEssentials ||
    (authenticated && !featureFlags) ||
    (authenticated && accountStatus !== 'completed') ||
    (hasCorrectRole && departmentStatus !== 'completed')
  ) {
    return <OrbiLoader />;
  }

  return (
    <MainContainer>
      <InternetStatusBanner
        offlineMessageTx="label.internet-status.offline-message"
        recentlyOnlineMessageTx="label.internet-status.recently-online-message"
      />
      <EmulateUserBanner />
      <OrbiPayBanner />
      <ConvertDraftsBanner />
      <MembershipsBulkInviteToolModal />

      <Outlet />
    </MainContainer>
  );
}
