import {
  Avatar,
  Box,
  BreadCrumb,
  BreadCrumbs,
  Confirm,
  ContentContainer,
  EmptyState,
  IconButton,
  InnerContentContainer,
  InnerPageContainer,
  LoadingContainer,
  OptionProps,
  PageContainer,
  Paginator,
  Select,
  Status,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TablePlaceholderRows,
  TableRow,
  Text,
  TinySelect,
  Toolbar,
  ToolbarContentContainer,
  Tooltip,
  getAvatarVariantFromString,
  paginatorOptions,
  parseTimestamp,
  useConfirm,
} from '@orbiapp/components';
import React from 'react';
import { useParams } from 'react-router-dom';

import { useDataGridPagination } from '../../../../helpers';
import {
  MembershipInviteListItem,
  MembershipInviteStatus,
  membershipInviteStatuses,
  membershipInvitesSortableKeys,
} from '../../../../models';
import {
  DeleteMembershipInvitationSelector,
  INITIAL_PAGINATION_STATE,
  MembershipInvitesSelector,
  MembershipTypeSelector,
  getMembershipInvitesThunk,
  getMembershipTypeThunk,
  membershipsActions,
  useDispatch,
  useSelector,
  withdrawInviteThunk,
} from '../../../../store';
import { NotFound } from '../../../shared';
import {
  InviteMembersButton,
  InviteMembersModal,
  InviteMembersProvider,
} from '../components/invite-members-modal';
import { GetStatusPillOptionsReturn } from './invites.types';

const ALLOWED_WITHDRAW_STATUSES: MembershipInviteStatus[] = [
  'pending_review',
  'needs_more_info',
  'awaiting_completion',
];

const getInviteStatusOptionTx = (
  status: MembershipInviteStatus | null,
): TxString => {
  switch (status) {
    case 'awaiting_completion':
      return 'label.memberships.review-applications.application-status.awaiting-completion';

    case 'completed':
      return 'label.memberships.review-applications.application-status.completed';

    case 'needs_more_info':
      return 'label.memberships.review-applications.application-status.needs-more-info';

    case 'pending_review':
      return 'label.memberships.review-applications.application-status.pending-review';

    case 'rejected':
      return 'label.memberships.review-applications.application-status.rejected';

    case 'withdrawn':
      return 'label.memberships.review-applications.application-status.withdrawn';

    case 'bounced':
      return 'label.memberships.review-applications.application-status.bounced';

    default:
      return 'label.memberships.review-applications.application-status.all';
  }
};

function getStatusPillOptions(
  status: MembershipInviteStatus,
): GetStatusPillOptionsReturn {
  const tx = getInviteStatusOptionTx(status);

  switch (status) {
    case 'awaiting_completion':
      return { variant: 'info', tx };

    case 'completed':
      return { variant: 'success', tx };

    case 'needs_more_info':
    case 'pending_review':
      return { variant: 'warning', tx };

    case 'rejected':
    case 'withdrawn':
    case 'bounced':
      return { variant: 'error', tx };
  }
}

function MembershipInvitesEmptyState() {
  return <EmptyState titleTx="label.memberships.invites.empty-state.title" />;
}

const TABLE_COLUMN_WIDTHS = {
  fullName: 200,
  email: 200,
  status: 200,
  createdAt: 200,
  actions: 40,
};

const APPLICATION_STATUS_OPTIONS: OptionProps[] = [
  {
    tx: getInviteStatusOptionTx(null),
    value: 'all',
  },
  ...membershipInviteStatuses.map((status) => ({
    tx: getInviteStatusOptionTx(status),
    value: status,
  })),
];

const SelectInviteStatus = () => {
  const dispatch = useDispatch();

  const includeStatus = useSelector(
    MembershipInvitesSelector.selectIncludeStatus,
  );

  const pagination = useSelector(MembershipInvitesSelector.selectPagination);

  const handleApplicationStatusChange: React.ChangeEventHandler<
    HTMLSelectElement
  > = (e) => {
    dispatch(membershipsActions.clearMembershipInvites());
    dispatch(
      membershipsActions.setMembershipInvitesIncludeStatus(
        e.target.value === 'all'
          ? null
          : (e.target.value as MembershipInviteStatus),
      ),
    );
    dispatch(
      getMembershipInvitesThunk({
        ...INITIAL_PAGINATION_STATE,
        orderBy: pagination.orderBy,
        sortOrder: pagination.sortOrder,
      }),
    );
  };

  return (
    <Select
      keepOpenOnSelect
      width={300}
      labelTx="label.memberships.invites.invite-status"
      value={includeStatus ?? 'all'}
      onChange={handleApplicationStatusChange}
      options={APPLICATION_STATUS_OPTIONS}
    />
  );
};

function MembershipInvitesContent() {
  const membershipInvites = useSelector(MembershipInvitesSelector.selectAll);
  const membershipInvitesStatus = useSelector(
    MembershipInvitesSelector.selectStatus,
  );
  const deleteMembershipInviteStatus = useSelector(
    DeleteMembershipInvitationSelector.selectStatus,
  );
  const membershipInvitesPagination = useSelector(
    MembershipInvitesSelector.selectPagination,
  );

  const dispatch = useDispatch();

  const { confirmValue, closeConfirm, isOpen, openConfirm } =
    useConfirm<string>();

  const { rows, paginatorProps, onPageSizeChange, onPaginate, onSort } =
    useDataGridPagination({
      data: membershipInvites,
      pagination: membershipInvitesPagination,
      reset: membershipsActions.clearMembershipInvites,
      thunk: getMembershipInvitesThunk,
    });

  const isLoading = membershipInvitesStatus === 'pending';

  if ((!membershipInvites || membershipInvites.length === 0) && isLoading) {
    return (
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead tx="label.memberships.view-membership.invites.full-name" />
            <TableHead tx="label.memberships.view-membership.invites.email" />
            <TableHead tx="label.memberships.view-membership.invites.status" />
            <TableHead tx="label.memberships.view-membership.invites.sent-at" />
            <TableHead fixedRight />
          </TableRow>
        </TableHeader>
        <TableBody>
          <TablePlaceholderRows
            rowCount={10}
            layout={Object.values(TABLE_COLUMN_WIDTHS)}
          />
        </TableBody>
      </Table>
    );
  }

  if (membershipInvites.length === 0 && !isLoading) {
    return <MembershipInvitesEmptyState />;
  }

  const renderMembershipInviteTableRow = (
    membershipInviteListItem: MembershipInviteListItem,
  ) => {
    const disableWithdrawInvite = !ALLOWED_WITHDRAW_STATUSES.includes(
      membershipInviteListItem.status,
    );

    const stageInviteForRemove = () => {
      openConfirm(membershipInviteListItem.membershipApplicationKey);
    };

    return (
      <TableRow key={membershipInviteListItem.membershipApplicationKey}>
        <TableCell width={TABLE_COLUMN_WIDTHS.fullName}>
          <Avatar
            fallbackLetter={membershipInviteListItem.fullName?.charAt(0)}
            minWidth={40}
            src={
              membershipInviteListItem.profilePicture?.thumbnail64.url ??
              undefined
            }
            variant={
              membershipInviteListItem.userKey
                ? getAvatarVariantFromString(membershipInviteListItem.userKey)
                : undefined
            }
          />
          <Text
            ml={8}
            variant="bodySm"
            text={membershipInviteListItem.fullName ?? undefined}
            as="span"
          />
        </TableCell>

        <TableCell
          width={TABLE_COLUMN_WIDTHS.email}
          text={membershipInviteListItem.email}
        />
        <TableCell width={TABLE_COLUMN_WIDTHS.status}>
          <Status {...getStatusPillOptions(membershipInviteListItem.status)} />
        </TableCell>
        <TableCell
          width={TABLE_COLUMN_WIDTHS.createdAt}
          text={parseTimestamp(
            membershipInviteListItem.createdAt,
            'DD MMM YYYY HH:mm',
          )}
        />
        <TableCell width={TABLE_COLUMN_WIDTHS.actions} hoverCell fixedRight>
          <Box flex width="100%" flexJustify="end">
            <Tooltip
              placement="left"
              titleTx={
                disableWithdrawInvite
                  ? 'label.memberships.view-membership.invites.cannot-withdraw-invite'
                  : 'label.memberships.view-membership.invites.withdraw-invite'
              }
            >
              <IconButton
                disabled={disableWithdrawInvite}
                icon="x-mark"
                onClick={stageInviteForRemove}
              />
            </Tooltip>
          </Box>
        </TableCell>
      </TableRow>
    );
  };

  const withdrawInvite = async () => {
    if (!confirmValue) return;

    const res = await dispatch(withdrawInviteThunk(confirmValue));

    if (res.meta.requestStatus === 'fulfilled') {
      closeConfirm();
    }
  };

  return (
    <React.Fragment>
      <Table>
        <TableHeader
          onSort={onSort}
          orderBy={membershipInvitesPagination.orderBy}
          sortableColumns={Object.values(membershipInvitesSortableKeys)}
          sortOrder={membershipInvitesPagination.sortOrder}
        >
          <TableRow>
            <TableHead
              tx="label.memberships.view-membership.invites.full-name"
              name={membershipInvitesSortableKeys.fullName}
            />
            <TableHead
              tx="label.memberships.view-membership.invites.email"
              name={membershipInvitesSortableKeys.email}
            />
            <TableHead
              tx="label.memberships.view-membership.invites.status"
              name={membershipInvitesSortableKeys.status}
            />
            <TableHead
              tx="label.memberships.view-membership.invites.sent-at"
              name={membershipInvitesSortableKeys.createdAt}
            />
            <TableHead fixedRight />
          </TableRow>
        </TableHeader>
        <TableBody>{rows.map(renderMembershipInviteTableRow)}</TableBody>
      </Table>

      <Box flexJustify="end" gap={24} flexAlign="center" flex>
        <Box flex gap={16} flexAlign="center">
          <Text
            tx="label.general.rows-per-page"
            variant="bodySm"
            whiteSpace="nowrap"
          />

          <TinySelect
            invertMenu
            onChange={onPageSizeChange}
            options={paginatorOptions}
            value={paginatorProps.pageSize}
          />
        </Box>

        <Paginator
          currentPage={paginatorProps.currentPage}
          hasNextPage={paginatorProps.hasNextPage}
          hasPrevPage={paginatorProps.hasPrevPage}
          onPaginate={onPaginate}
        />
      </Box>

      <Confirm
        cancelTx="prompt.withdraw-invite.cancel"
        confirmTx="prompt.withdraw-invite.confirm"
        isLoading={deleteMembershipInviteStatus === 'pending'}
        isOpen={isOpen}
        messageTx="prompt.withdraw-invite.message"
        onCancel={closeConfirm}
        onConfirm={withdrawInvite}
        titleTx="prompt.withdraw-invite.title"
      />
    </React.Fragment>
  );
}

function InvitesToolbar() {
  const membershipTypeKey = useSelector(
    MembershipTypeSelector.selectMembershipTypeKey,
  );
  const membershipTypeName = useSelector(
    MembershipTypeSelector.selectMembershipTypeName,
  );

  if (!membershipTypeName) {
    return null;
  }

  return (
    <Toolbar backgroundColor="tabHeaderBackground" zIndex={1}>
      <ToolbarContentContainer flexJustify="between">
        <BreadCrumbs flexGrow={0.4}>
          <BreadCrumb
            to="/memberships"
            tx="label.breadcrumbs.memberships.memberships"
          />

          <BreadCrumb
            maxWidth="28ch"
            overflow="hidden"
            text={membershipTypeName}
            textOverflow="ellipsis"
            to={`/memberships/${membershipTypeKey}`}
            whiteSpace="nowrap"
          />

          <BreadCrumb isLast tx="label.breadcrumbs.memberships.invitations" />
        </BreadCrumbs>
      </ToolbarContentContainer>
    </Toolbar>
  );
}

export function MembershipInvites() {
  const membershipTypeStatus = useSelector(MembershipTypeSelector.selectStatus);
  const membershipTypeError = useSelector(MembershipTypeSelector.selectError);
  const isIntegrated = useSelector(MembershipTypeSelector.selectIsIntegrated);

  const { membershipTypeKey } = useParams<{
    membershipTypeKey: string;
  }>();

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!membershipTypeKey) return;

    dispatch(getMembershipTypeThunk(membershipTypeKey));

    return () => {
      dispatch(membershipsActions.clearMembership());
    };
  }, [dispatch, membershipTypeKey]);

  if (membershipTypeError) {
    return <NotFound continueTo="/memberships" />;
  }

  if (membershipTypeStatus === 'pending' || isIntegrated) {
    return <LoadingContainer />;
  }

  return (
    <InviteMembersProvider>
      <PageContainer>
        <InvitesToolbar />

        <InnerPageContainer>
          <ContentContainer>
            <InnerContentContainer>
              <Box flex flexJustify="between" flexWrap="wrap" gap={16}>
                <Box flexAlign="center" gap={16} flexWrap="wrap" flex>
                  <IconButton
                    icon="chevron-left"
                    to={`/memberships/${membershipTypeKey}/overview`}
                  />

                  <Text
                    color="pageTitle"
                    as="h1"
                    tx="title.memberships.invites"
                    variant="titleMd"
                  />
                </Box>

                <InviteMembersButton />
              </Box>

              <Box flex flexDirection="column" gap={8}>
                <SelectInviteStatus />

                <MembershipInvitesContent />
              </Box>
            </InnerContentContainer>
          </ContentContainer>
        </InnerPageContainer>
      </PageContainer>

      <InviteMembersModal />
    </InviteMembersProvider>
  );
}
