import { GeneralApiResultWithData } from '@orbiapp/components';

import {
  GeneralInfoForm,
  UpdateMembershipTypeGeneralInfoResponse,
} from '../../../../../../models';
import { Logger } from '../../../../../logger';
import { apisauce } from '../../../../api';
import { getApiLogExtras, getGeneralApiProblem } from '../../../../api.utils';
import { UpdateMembershipTypeGeneralInfoResponseDto } from './general-info.dto';
import { updateMembershipTypeInfoMapper } from './general-info.mappers';

export const updateGeneralInfo = async (params: {
  membershipTypeKey: string;
  generalInfo: GeneralInfoForm;
}): Promise<
  GeneralApiResultWithData<UpdateMembershipTypeGeneralInfoResponse>
> => {
  const { membershipTypeKey, generalInfo } = params;

  const res = await apisauce.put<
    UpdateMembershipTypeGeneralInfoResponseDto,
    any
  >(
    `/v2/membership-types/${membershipTypeKey}/general-info`,
    updateMembershipTypeInfoMapper(generalInfo),
  );

  if (!res.ok) {
    Logger.warning('updateGeneralInfo', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data: UpdateMembershipTypeGeneralInfoResponse = {
      backgroundImage: res.data.backgroundImage,
      logo: res.data.logo,
    };

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('updateGeneralInfo', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};
