import {
  GeneralApiResult,
  GeneralApiResultWithData,
} from '@orbiapp/components';

import {
  CreateInvitationForm,
  DepartmentInvitation,
  PendingDepartmentInvitation,
  UpdateUserParams,
  User,
} from '../../../../../models';
import { Logger } from '../../../../logger';
import { apisauce } from '../../../api';
import { getApiLogExtras, getGeneralApiProblem } from '../../../api.utils';
import {
  DepartmentInvitationDto,
  PendingDepartmentInvitationDto,
  UserDto,
} from './team.dto';
import {
  departmentInvitationMapper,
  pendingDepartmentInvitationMapper,
  userMapper,
} from './team.mappers';

export const createInvitation = async (
  createInvitationParams: CreateInvitationForm,
): Promise<GeneralApiResultWithData<string>> => {
  const res = await apisauce.post<{ departmentInvitationKey: string }, any>(
    '/v2/team/invitations',
    createInvitationParams,
  );

  if (!res.ok) {
    Logger.warning('createInvitation', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = res.data.departmentInvitationKey;

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('createInvitation', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export const getPendingInvitations = async (): Promise<
  GeneralApiResultWithData<PendingDepartmentInvitation[]>
> => {
  const res = await apisauce.get<PendingDepartmentInvitationDto[], any>(
    '/v2/team/invitations/pending',
  );

  if (!res.ok) {
    Logger.warning('getPendingInvitations', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = res.data.map(pendingDepartmentInvitationMapper);

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('getPendingInvitations', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export const getCompanyInvitationThunk = async (
  departmentInvitationKey: string,
): Promise<GeneralApiResultWithData<DepartmentInvitation>> => {
  const res = await apisauce.get<DepartmentInvitationDto, any>(
    `/v2/team/invitations/${departmentInvitationKey}`,
  );

  if (!res.ok) {
    Logger.warning('getCompanyInvitationThunk', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = departmentInvitationMapper(res.data);

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('getCompanyInvitationThunk', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export const deleteInvitation = async (
  departmentInvitationKey: string,
): Promise<GeneralApiResult> => {
  const res = await apisauce.delete<undefined, any>(
    `/v2/team/invitations/${departmentInvitationKey}`,
  );

  if (!res.ok) {
    Logger.warning('deleteInvitation', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  return { kind: 'ok' };
};

export const getUsers = async (): Promise<GeneralApiResultWithData<User[]>> => {
  const res = await apisauce.get<UserDto[], any>('/v2/team/users');

  if (!res.ok) {
    Logger.warning('getUsers', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = res.data.map(userMapper);

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('getUsers', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export const removeUser = async (
  userKey: string,
): Promise<GeneralApiResult> => {
  const res = await apisauce.patch<undefined, any>(
    `/v2/team/users/${userKey}`,
    { remove: true },
  );

  if (!res.ok) {
    Logger.warning('removeUser', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  return { kind: 'ok' };
};

export const updateUser = async (
  updateUserParams: UpdateUserParams,
): Promise<GeneralApiResult> => {
  const { userKey, fullName, ...rest } = updateUserParams;

  const res = await apisauce.patch<undefined, any>(
    `/v2/team/users/${userKey}`,
    rest,
  );

  if (!res.ok) {
    Logger.warning('updateUser', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  return { kind: 'ok' };
};
