import {
  Base64File,
  Box,
  CropState,
  FileType,
  ImageCropper,
  LOGO_CRITERIA,
  Text,
  Upload,
  UploadError,
  UploadResult,
  constructBase64,
  getObjectValue,
  isTxString,
  removeBase64Meta,
  useModalState,
} from '@orbiapp/components';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { GlobalUiStateAlert } from '../../models';
import { getAlert, imageMeetsCriteria } from '../../utils';

interface UploadLogoProps {
  name: string;
}

export function UploadLogo(props: UploadLogoProps) {
  const { name } = props;

  const { formState, watch, setValue } = useFormContext();

  const { closeModal, isOpen, openModal } = useModalState();

  const [uploadError, setUploadError] =
    React.useState<GlobalUiStateAlert | null>(null);

  const logo: Base64File | null = watch(name);

  const uploadResultRef = React.useRef<UploadResult | null>(
    logo
      ? {
          height: 0,
          width: 0,
          base64: logo.base64,
          size: 0,
          type: logo.fileType,
          name: logo.fileName,
        }
      : null,
  );

  const [cropState, setCropState] = React.useState<CropState>({
    aspect: 1,
    base64File: logo,
    x: 0,
    y: 0,
    zoom: 1,
  });

  const handleUpload = (uploadResult: UploadResult) => {
    setUploadError(null);

    uploadResultRef.current = uploadResult;

    openModal();

    setCropState({
      aspect: 1,
      base64File: {
        base64: removeBase64Meta(uploadResult.base64),
        fileName: uploadResult.name,
        fileType: uploadResult.type as FileType,
      },
      x: 0,
      y: 0,
      zoom: 1,
    });
  };

  const saveLogo = (newCropState: CropState | null) => {
    if (!newCropState) return;

    setCropState(newCropState);

    setValue(name, newCropState.base64File, {
      shouldValidate: formState.isSubmitted,
      shouldDirty: true,
    });

    closeModal();
  };

  const handleUploadError = (err: UploadError) => {
    if (err.message === 'accept') {
      setUploadError({
        alertType: 'file-type-not-allowed',
      });
      return;
    }

    const error = imageMeetsCriteria(LOGO_CRITERIA, {
      height: err.height,
      width: err.width,
      size: err.size,
    });

    setUploadError(error);
  };

  const errorTx =
    getObjectValue<any>(formState.errors, name)?.message ??
    getObjectValue<any>(formState.errors, `${name}.fileType`)?.message;

  return (
    <Box flexAlign="center" flexWrap="wrap" flex gap={32}>
      <ImageCropper
        onClose={closeModal}
        isOpen={isOpen}
        onSave={saveLogo}
        cropState={cropState}
        closeTx="button.close"
        saveTx="button.save"
      />

      <Box maxWidth="100%" flex flexDirection="column" gap={8}>
        <Upload
          accept={Object.values(FileType).join(', ')}
          aspectRatio="1"
          buttonTx={logo ? 'button.upload-new' : 'button.upload-image'}
          maxHeight={LOGO_CRITERIA.maxHeight}
          maxSize={LOGO_CRITERIA.maxSize}
          maxWidth={LOGO_CRITERIA.maxWidth}
          minHeight={LOGO_CRITERIA.minHeight}
          minWidth={LOGO_CRITERIA.minWidth}
          onError={handleUploadError}
          onUpload={handleUpload}
          src={constructBase64(logo)}
          titleTx="label.upload.min-size"
          width={256}
          height={256}
        />

        {uploadError ? (
          <Text
            color="errorLabel"
            tx={getAlert(uploadError.alertType).subtitleTx}
            txArgs={uploadError.args}
            variant="bodyMd"
            textAlign="center"
          />
        ) : isTxString(errorTx) ? (
          <Text
            color="errorLabel"
            tx={errorTx}
            variant="bodyMd"
            textAlign="center"
            txArgs={{
              allowedFileTypes: Object.values(FileType)
                .map((fileType) => fileType.split('/')[1])
                .join(', '),
            }}
          />
        ) : null}
      </Box>
    </Box>
  );
}
