import {
  Avatar,
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  Confirm,
  ContentContainer,
  ContentSidebar,
  ContentSidebarContentContainer,
  ContentSidebarFooterContainer,
  DatasetListItem,
  EmptyState,
  Icon,
  IconButton,
  InnerContentContainer,
  InnerPageContainer,
  LeadingInputBox,
  List,
  ListItem,
  Paginator,
  PieChart,
  SearchInput,
  Spinner,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TablePlaceholderRows,
  TableRow,
  Text,
  TinySelect,
  Tooltip,
  TrailingInputBox,
  getAvatarVariantFromString,
  paginatorOptions,
  parseCurrency,
  parseTimestamp,
  translate,
  useConfirm,
  useDebounce,
} from '@orbiapp/components';
import React from 'react';
import { Navigate } from 'react-router-dom';

import { useDataGridPagination } from '../../../../../helpers';
import {
  ActivityCheckboxAnswer,
  ActivityFreetextAnswer,
  ActivityMultichoiceAnswer,
  CheckboxListItem,
  FreeTextListItem,
  FreeTextListItemsOrderByKey,
  MultiChoiceListItem,
  RequestedInfoAnswer,
  RequestedInfoType,
  TicketListItem,
  TicketListItemOrderByKey,
  TicketTransferHistoryItem,
  freeTextListItemsSortableKeys,
  soldTicketsSortableKeys,
} from '../../../../../models';
import {
  ActivityDataSelector,
  ActivityDiscountStatsSelector,
  ActivitySelector,
  ConsumeTicketSelector,
  FreeTextSelector,
  OrbiPaySettingsSelector,
  RefundTicketSelector,
  SelectedTicketDetailsSelector,
  SelectedTicketListItemSelector,
  TicketListItemsSelector,
  activityActions,
  consumeTicketThunk,
  getFreeTextListItemsThunk,
  getTicketDetailsThunk,
  getTicketListItemsThunk,
  refundTicketThunk,
  unconsumeTicketThunk,
  useDispatch,
  useSelector,
} from '../../../../../store';
import { EventActions } from '../components';
import { Styled } from './tickets.styled';

function FreetextAnswerCard(props: ActivityFreetextAnswer) {
  const { answer, question } = props;

  return (
    <Card>
      <Box flex flexDirection="column" gap={4}>
        <Text text={question} />
        <Text variant="bodySm" text={answer} />
      </Box>

      <Chip
        tx="label.view-activity.tickets.requested-info.free-text"
        variant="primary"
      />
    </Card>
  );
}

function CheckboxAnswerCard(props: ActivityCheckboxAnswer) {
  const { isChecked, question } = props;

  return (
    <Card>
      <Box flex flexDirection="column" gap={4}>
        <Text text={question} />
        <Text
          variant="bodySm"
          tx={isChecked ? 'label.boolean.yes' : 'label.boolean.no'}
        />
      </Box>

      <Chip
        tx="label.view-activity.tickets.requested-info.checkbox"
        variant="primary"
      />
    </Card>
  );
}

function MultichoiceAnswerCard(props: ActivityMultichoiceAnswer) {
  const { label, priceIncrement, question } = props;

  const currency = useSelector(OrbiPaySettingsSelector.selectCurrency);

  return (
    <Card>
      <Box flex flexDirection="column" gap={4}>
        <Text text={question} />
        <Text variant="bodySm" text={label} />
      </Box>

      <Box flex gap={8}>
        <Chip
          tx="label.view-activity.tickets.requested-info.multi-choice"
          variant="primary"
        />

        <Chip
          text={parseCurrency(priceIncrement, currency)}
          variant="primary"
        />
      </Box>
    </Card>
  );
}

function renderRequestedInfoAnswer(answer: RequestedInfoAnswer, index: number) {
  switch (answer.type) {
    case RequestedInfoType.Checkbox:
      return (
        <CheckboxAnswerCard
          key={`requested-info-answer-${index}`}
          {...answer}
        />
      );

    case RequestedInfoType.FreeText:
      return (
        <FreetextAnswerCard
          key={`requested-info-answer-${index}`}
          {...answer}
        />
      );

    case RequestedInfoType.MultiChoice:
      return (
        <MultichoiceAnswerCard
          key={`requested-info-answer-${index}`}
          {...answer}
        />
      );
  }
}

function RefundTicketButton() {
  const refundTicketStatus = useSelector(RefundTicketSelector.selectStatus);
  const soldTicketData = useSelector(SelectedTicketListItemSelector.selectData);
  const activityKey = useSelector(ActivityDataSelector.selectActivityKey);
  const isTransferred = useSelector(
    SelectedTicketDetailsSelector.selectedIsTransferred,
  );
  const isActivityOwner = useSelector(ActivitySelector.selectIsActivityOwner);
  const userIsDeleted = useSelector(
    SelectedTicketListItemSelector.selectUserIsDeleted,
  );
  const isConsumed = useSelector(
    SelectedTicketListItemSelector.selectIsConsumed,
  );

  const dispatch = useDispatch();

  const { closeConfirm, isOpen, openConfirm } = useConfirm();

  const isLoading = refundTicketStatus === 'pending';

  if (!isActivityOwner) {
    return (
      <Tooltip
        placement="left"
        titleTx="label.view-ticket.tooltips.refund-not-owner"
      >
        <Button disabled variant="destructive" tx="button.ticket.refund" />
      </Tooltip>
    );
  }

  if (userIsDeleted) {
    return (
      <Tooltip
        placement="left"
        titleTx="label.view-ticket.tooltips.refund-user-deleted"
      >
        <Button
          disabled
          tx="button.ticket.refund"
          variant="destructive"
          whiteSpace="nowrap"
        />
      </Tooltip>
    );
  }

  if (isConsumed) {
    return (
      <Tooltip
        placement="left"
        titleTx="label.view-ticket.tooltips.refund-ticket-consumed"
      >
        <Button
          disabled
          tx="button.ticket.refund"
          variant="destructive"
          whiteSpace="nowrap"
        />
      </Tooltip>
    );
  }

  if (isTransferred) {
    return (
      <Tooltip
        placement="left"
        titleTx="label.view-ticket.tooltips.refund-ticket-transferred"
      >
        <Button
          disabled
          tx="button.ticket.refund"
          variant="destructive"
          whiteSpace="nowrap"
        />
      </Tooltip>
    );
  }

  const refundTicket = async () => {
    if (!soldTicketData || !activityKey) return;

    await dispatch(
      refundTicketThunk({ activityKey, ticketKey: soldTicketData?.ticketKey }),
    );

    closeConfirm();
  };

  return (
    <React.Fragment>
      <Confirm
        cancelTx="prompt.refund-ticket.cancel"
        confirmTx="prompt.refund-ticket.confirm"
        isLoading={isLoading}
        isOpen={isOpen}
        messageTx="prompt.refund-ticket.message"
        onCancel={closeConfirm}
        onConfirm={refundTicket}
        titleTx="prompt.refund-ticket.title"
      />

      <Button
        isLoading={isLoading}
        onClick={openConfirm}
        tx="button.ticket.refund"
        variant="destructive"
      />
    </React.Fragment>
  );
}

function ConsumeTicketButton() {
  const soldTicketData = useSelector(SelectedTicketListItemSelector.selectData);
  const activityKey = useSelector(ActivityDataSelector.selectActivityKey);
  const consumeTicketStatus = useSelector(ConsumeTicketSelector.selectStatus);
  const isActivityOwner = useSelector(ActivitySelector.selectIsActivityOwner);
  const userIsDeleted = useSelector(
    SelectedTicketListItemSelector.selectUserIsDeleted,
  );
  const isLoading = consumeTicketStatus === 'pending';

  const { closeConfirm, isOpen, openConfirm } = useConfirm();

  const dispatch = useDispatch();

  if (!isActivityOwner) {
    return (
      <Tooltip
        placement="left"
        titleTx="label.view-ticket.tooltips.consume-not-owner"
      >
        <Button
          disabled
          tx="button.ticket.consume"
          variant="secondary"
          whiteSpace="nowrap"
        />
      </Tooltip>
    );
  }

  if (userIsDeleted) {
    return (
      <Tooltip
        placement="left"
        titleTx="label.view-ticket.tooltips.consume-user-deleted"
      >
        <Button
          disabled
          tx="button.ticket.consume"
          variant="secondary"
          whiteSpace="nowrap"
        />
      </Tooltip>
    );
  }

  const consumeTicket = async () => {
    if (!soldTicketData || !activityKey) return;

    await dispatch(
      consumeTicketThunk({ activityKey, ticketKey: soldTicketData?.ticketKey }),
    );

    closeConfirm();
  };

  return (
    <React.Fragment>
      <Confirm
        cancelTx="prompt.consume-ticket.cancel"
        confirmTx="prompt.consume-ticket.confirm"
        isOpen={isOpen}
        messageTx="prompt.consume-ticket.message"
        onCancel={closeConfirm}
        onConfirm={consumeTicket}
        titleTx="prompt.consume-ticket.title"
        isLoading={isLoading}
      />

      <Button
        isLoading={isLoading}
        onClick={openConfirm}
        tx="button.ticket.consume"
        variant="secondary"
        whiteSpace="nowrap"
      />
    </React.Fragment>
  );
}

function UndoConsumeTicketButton() {
  const soldTicketData = useSelector(SelectedTicketListItemSelector.selectData);
  const activityKey = useSelector(ActivityDataSelector.selectActivityKey);
  const isActivityOwner = useSelector(ActivitySelector.selectIsActivityOwner);
  const unconsumeTicketStatus = useSelector(ConsumeTicketSelector.selectStatus);
  const userIsDeleted = useSelector(
    SelectedTicketListItemSelector.selectUserIsDeleted,
  );

  const { closeConfirm, isOpen, openConfirm } = useConfirm();
  const dispatch = useDispatch();

  if (!isActivityOwner) {
    return (
      <Tooltip
        placement="left"
        titleTx="label.view-ticket.tooltips.consume-not-owner"
      >
        <Button
          disabled
          tx="button.ticket.undo-consume"
          variant="secondary"
          whiteSpace="nowrap"
        />
      </Tooltip>
    );
  }

  if (userIsDeleted) {
    return (
      <Tooltip
        placement="left"
        titleTx="label.view-ticket.tooltips.consume-user-deleted"
      >
        <Button
          disabled
          tx="button.ticket.undo-consume"
          variant="secondary"
          whiteSpace="nowrap"
        />
      </Tooltip>
    );
  }

  const unconsumeTicket = async () => {
    if (!activityKey || !soldTicketData?.ticketKey) return;

    await dispatch(
      unconsumeTicketThunk({
        activityKey,
        ticketKey: soldTicketData?.ticketKey,
      }),
    );

    closeConfirm();
  };

  return (
    <React.Fragment>
      <Confirm
        cancelTx="prompt.undo-ticket-consume.cancel"
        confirmTx="prompt.undo-ticket-consume.confirm"
        isLoading={unconsumeTicketStatus === 'pending'}
        isOpen={isOpen}
        messageTx="prompt.undo-ticket-consume.message"
        onCancel={closeConfirm}
        onConfirm={unconsumeTicket}
        titleTx="prompt.undo-ticket-consume.title"
      />

      <Button
        onClick={openConfirm}
        tx="button.ticket.undo-consume"
        variant="secondary"
        whiteSpace="nowrap"
      />
    </React.Fragment>
  );
}

function ToggleIsConsumedButton() {
  const isConsumed = useSelector(
    SelectedTicketListItemSelector.selectIsConsumed,
  );

  if (isConsumed) {
    return <UndoConsumeTicketButton />;
  }

  return <ConsumeTicketButton />;
}

function renderTicketTransferHistoryCard(
  ticketTransferHistoryItem: TicketTransferHistoryItem,
  index: number,
) {
  return (
    <Card key={`ticket-transfer-history-item-${index}`}>
      <Box flex flexDirection="column" gap={4}>
        <Text text={ticketTransferHistoryItem.email ?? '-'} />
        <Text
          variant="bodySm"
          text={ticketTransferHistoryItem.fullName ?? '-'}
        />
      </Box>

      <Chip
        text={parseTimestamp(
          ticketTransferHistoryItem.transferredAt,
          'DD MMM YYYY HH:mm',
        )}
        variant="primary"
      />
    </Card>
  );
}

function TicketTransferHistory() {
  const ticketTransferHistory = useSelector(
    SelectedTicketDetailsSelector.selectTicketTransferHistory,
  );

  if (!ticketTransferHistory) {
    return null;
  }

  return (
    <Box flex flexDirection="column" gap={16}>
      <Text
        variant="titleSm"
        tx="label.view-activity.tickets.sold-tickets.ticket-meta-data.transfer-history"
      />

      {ticketTransferHistory.map(renderTicketTransferHistoryCard)}
    </Box>
  );
}

function TicketSidebar() {
  const dispatch = useDispatch();

  const currency = useSelector(OrbiPaySettingsSelector.selectCurrency);
  const soldTicketData = useSelector(SelectedTicketListItemSelector.selectData);

  const closeSidebar = () =>
    dispatch(activityActions.clearSelectedTicketListItem());

  return (
    <ContentSidebar
      width={470}
      isOpen={!!soldTicketData}
      onClose={closeSidebar}
    >
      <ContentSidebarContentContainer>
        <Box flex flexDirection="column" gap={4}>
          <Text
            variant="titleSm"
            tx="label.view-activity.tickets.sold-tickets.ticket-meta-data.title"
          />

          <List>
            <ListItem
              titleTx="label.view-activity.tickets.sold-tickets.ticket-meta-data.owner"
              subtitle={
                soldTicketData?.fullName ??
                translate('placeholder.deleted-user')
              }
            />

            <ListItem
              titleTx="label.view-activity.tickets.table.email"
              subtitle={
                soldTicketData?.email ?? translate('placeholder.deleted-user')
              }
            />

            <ListItem
              titleTx="label.view-activity.tickets.table.ticket-type-name"
              subtitle={soldTicketData?.ticketTypeName}
            />

            {typeof soldTicketData?.details?.paidAmount !== 'number' ? (
              <ListItem>
                <Box flex flexDirection="column">
                  <Text
                    tx="label.view-activity.tickets.table.paid-amount"
                    variant="bodyMd"
                  />

                  <Spinner size={21} />
                </Box>
              </ListItem>
            ) : (
              <ListItem
                titleTx="label.view-activity.tickets.table.paid-amount"
                subtitle={parseCurrency(
                  soldTicketData.details.paidAmount,
                  currency,
                )}
              />
            )}

            {soldTicketData?.details?.usedDiscount && (
              <React.Fragment>
                <ListItem
                  titleTx={
                    soldTicketData.details.usedDiscount.discountSource ===
                    'code'
                      ? 'label.view-activity.tickets.table.discount-code'
                      : 'label.view-activity.tickets.table.membership-discount'
                  }
                  subtitle={parseCurrency(
                    soldTicketData.details.usedDiscount.discount,
                    currency,
                  )}
                />

                {soldTicketData.details.usedDiscount.discountSource ===
                'code' ? (
                  <ListItem
                    titleTx="label.view-activity.tickets.used-discount-code"
                    subtitle={
                      soldTicketData.details.usedDiscount.discountCode ??
                      translate(
                        'label.view-activity.tickets.deleted-discount-code',
                      )
                    }
                  />
                ) : (
                  <ListItem
                    titleTx="label.view-activity.tickets.used-membership"
                    subtitle={
                      soldTicketData.details.usedDiscount.membershipTypeName ??
                      translate(
                        'label.view-activity.tickets.deleted-membership',
                      )
                    }
                  />
                )}
              </React.Fragment>
            )}

            <ListItem
              titleTx="label.view-activity.tickets.sold-tickets.ticket-meta-data.food-preferences"
              subtitle={
                soldTicketData?.foodPreferences ??
                translate('placeholder.no-preference')
              }
            />

            {soldTicketData?.claimedAt && (
              <ListItem
                titleTx="label.view-activity.tickets.sold-tickets.ticket-meta-data.claimed-at"
                subtitle={parseTimestamp(
                  soldTicketData?.claimedAt,
                  'DD MMM YYYY HH:mm',
                )}
              />
            )}

            <ListItem
              titleTx="label.view-activity.tickets.sold-tickets.ticket-meta-data.consumed-at"
              subtitle={
                soldTicketData?.consumedAt
                  ? parseTimestamp(
                      soldTicketData.consumedAt,
                      'DD MMM YYYY HH:mm',
                    )
                  : translate('placeholder.not-consumed')
              }
            />
          </List>
        </Box>

        {soldTicketData?.details?.requestedInfoAnswers && (
          <Box flex flexDirection="column" gap={16}>
            <Text
              variant="titleSm"
              tx="label.view-activity.tickets.requested-info.requested-info-answers"
            />

            <Box flex flexDirection="column" gap={16}>
              {soldTicketData?.details?.requestedInfoAnswers.map(
                renderRequestedInfoAnswer,
              )}
            </Box>
          </Box>
        )}

        <TicketTransferHistory />
      </ContentSidebarContentContainer>

      <ContentSidebarFooterContainer>
        <RefundTicketButton />

        <ToggleIsConsumedButton />
      </ContentSidebarFooterContainer>
    </ContentSidebar>
  );
}

const FREE_TEXT_ANSWERS_TABLE_COLUMN_WIDTHS = {
  fullName: 200,
  answeredAt: 200,
  question: 200,
  answer: 200,
  email: 200,
  ticketTypeName: 200,
  actions: 50,
};

function renderFreetextAnswerTableRow(freetextAnswer: FreeTextListItem) {
  return (
    <TableRow key={freetextAnswer.freetextListItemKey}>
      <TableCell
        width={FREE_TEXT_ANSWERS_TABLE_COLUMN_WIDTHS.fullName}
        text={freetextAnswer.fullName ?? ''}
      />
      <TableCell
        width={FREE_TEXT_ANSWERS_TABLE_COLUMN_WIDTHS.answeredAt}
        text={parseTimestamp(freetextAnswer.answeredAt, 'DD MMM YYYY HH:mm')}
      />
      <TableCell
        width={FREE_TEXT_ANSWERS_TABLE_COLUMN_WIDTHS.question}
        text={freetextAnswer.question}
      />
      <TableCell
        width={FREE_TEXT_ANSWERS_TABLE_COLUMN_WIDTHS.answer}
        text={freetextAnswer.answer}
      />
      <TableCell
        width={FREE_TEXT_ANSWERS_TABLE_COLUMN_WIDTHS.email}
        text={freetextAnswer.email ?? ''}
      />
      <TableCell
        width={FREE_TEXT_ANSWERS_TABLE_COLUMN_WIDTHS.ticketTypeName}
        text={freetextAnswer.ticketTypeName}
      />

      <TableCell
        width={FREE_TEXT_ANSWERS_TABLE_COLUMN_WIDTHS.actions}
        fixedRight
      />
    </TableRow>
  );
}

function FreeTextAnswersTable() {
  const freetextListItems = useSelector(FreeTextSelector.selectAll);
  const status = useSelector(FreeTextSelector.selectFreeTextListItemsStatus);
  const pagination = useSelector(FreeTextSelector.selectPagination);

  const { rows, paginatorProps, onPageSizeChange, onPaginate, onSort } =
    useDataGridPagination<FreeTextListItem, FreeTextListItemsOrderByKey>({
      data: freetextListItems,
      pagination,
      reset: activityActions.clearFreetextListItems,
      thunk: getFreeTextListItemsThunk,
    });

  if (status === 'pending' && rows.length === 0) {
    return (
      <Box flex flexDirection="column" gap={16}>
        <Text
          variant="titleSm"
          tx="label.view-activity.tickets.requested-info.free-text-questions"
        />

        <Table>
          <TableHeader>
            <TableRow>
              <TableHead tx="label.view-activity.tickets.table.owner" />
              <TableHead tx="label.view-activity.tickets.table.answered-at" />
              <TableHead tx="label.view-activity.tickets.table.question" />
              <TableHead tx="label.view-activity.tickets.table.answer" />
              <TableHead tx="label.view-activity.tickets.table.email" />
              <TableHead tx="label.view-activity.tickets.table.ticket-type-name" />
              <TableHead fixedRight />
            </TableRow>
          </TableHeader>
          <TableBody>
            <TablePlaceholderRows
              rowCount={10}
              layout={Object.values(FREE_TEXT_ANSWERS_TABLE_COLUMN_WIDTHS)}
            />
          </TableBody>
        </Table>
      </Box>
    );
  }

  if (rows.length === 0) {
    return (
      <Box flex flexDirection="column" gap={16}>
        <Text
          variant="titleSm"
          tx="label.view-activity.tickets.requested-info.free-text-questions"
        />

        <EmptyState titleTx="placeholder.table.free-text" />
      </Box>
    );
  }

  return (
    <Box flex flexDirection="column" gap={16}>
      <Text
        variant="titleSm"
        tx="label.view-activity.tickets.requested-info.free-text-questions"
      />

      <Table>
        <TableHeader
          onSort={onSort}
          orderBy={pagination.orderBy}
          sortableColumns={Object.values(freeTextListItemsSortableKeys)}
          sortOrder={pagination.sortOrder}
        >
          <TableRow>
            <TableHead
              tx="label.view-activity.tickets.table.owner"
              name={freeTextListItemsSortableKeys.fullName}
            />
            <TableHead
              tx="label.view-activity.tickets.table.answered-at"
              name={freeTextListItemsSortableKeys.answeredAt}
            />
            <TableHead
              tx="label.view-activity.tickets.table.question"
              name={freeTextListItemsSortableKeys.question}
            />
            <TableHead
              tx="label.view-activity.tickets.table.answer"
              name={freeTextListItemsSortableKeys.answer}
            />
            <TableHead
              tx="label.view-activity.tickets.table.email"
              name={freeTextListItemsSortableKeys.email}
            />
            <TableHead
              tx="label.view-activity.tickets.table.ticket-type-name"
              name={freeTextListItemsSortableKeys.ticketTypeName}
            />

            <TableHead fixedRight />
          </TableRow>
        </TableHeader>
        <TableBody>{rows.map(renderFreetextAnswerTableRow)}</TableBody>
      </Table>

      <Box flexJustify="end" gap={24} flexAlign="center" flex>
        <Box flex gap={16} flexAlign="center">
          <Text
            tx="label.general.rows-per-page"
            variant="bodySm"
            whiteSpace="nowrap"
          />

          <TinySelect
            invertMenu
            onChange={onPageSizeChange}
            options={paginatorOptions}
            value={paginatorProps.pageSize}
          />
        </Box>

        <Paginator
          currentPage={paginatorProps.currentPage}
          hasNextPage={paginatorProps.hasNextPage}
          hasPrevPage={paginatorProps.hasPrevPage}
          onPaginate={onPaginate}
        />
      </Box>
    </Box>
  );
}

function SearchTickets() {
  const search = useSelector(TicketListItemsSelector.selectSearch);
  const pagination = useSelector(TicketListItemsSelector.selectPagination);
  const soldTicketCount = useSelector(
    ActivityDataSelector.selectSoldTicketCount,
  );

  const dispatch = useDispatch();
  const debounce = useDebounce();

  if (!soldTicketCount) {
    return null;
  }

  const handleSearch: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    dispatch(activityActions.setTicketListItemsSearch(e.target.value));

    debounce(() => {
      if (!e.target.value) {
        dispatch(activityActions.clearTicketListItems());
        dispatch(activityActions.setTicketListItemsSearch(''));
      }

      dispatch(getTicketListItemsThunk(pagination));
    });
  };

  const cancelSearch = () => {
    dispatch(activityActions.clearTicketListItems());
    dispatch(activityActions.setTicketListItemsSearch(''));

    dispatch(getTicketListItemsThunk(pagination));
  };

  return (
    <Box width={300}>
      <SearchInput
        onChange={handleSearch}
        value={search}
        placeholderTx="placeholder.search-name-or-email"
        leadingElement={
          <LeadingInputBox>
            <Icon name="magnifying-glass" />
          </LeadingInputBox>
        }
        trailingElement={
          <TrailingInputBox>
            {search.length > 0 && (
              <IconButton icon="x-mark" onClick={cancelSearch} />
            )}
          </TrailingInputBox>
        }
      />
    </Box>
  );
}

const TICKETS_TABLE_COLUMN_WIDTHS = {
  fullName: 200,
  email: 200,
  ticketTypeName: 200,
  foodPreferences: 200,
  claimedAt: 200,
  consumedAt: 200,
  actions: 50,
};

function TicketsTable() {
  const ticketListItems = useSelector(TicketListItemsSelector.selectAll);
  const status = useSelector(TicketListItemsSelector.selectStatus);
  const pagination = useSelector(TicketListItemsSelector.selectPagination);
  const soldTicketData = useSelector(SelectedTicketListItemSelector.selectData);

  const dispatch = useDispatch();

  const { rows, paginatorProps, onPageSizeChange, onPaginate, onSort } =
    useDataGridPagination<TicketListItem, TicketListItemOrderByKey>({
      data: ticketListItems,
      pagination,
      reset: activityActions.clearTicketListItems,
      thunk: getTicketListItemsThunk,
    });

  const renderTicketTableRow = (ticket: TicketListItem) => {
    const selectTicket = () => {
      dispatch(activityActions.setSoldTicket(ticket));
      dispatch(getTicketDetailsThunk());
    };

    return (
      <TableRow
        highlight={soldTicketData?.ticketKey === ticket.ticketKey}
        key={ticket.ticketKey}
        onClick={selectTicket}
      >
        <TableCell width={TICKETS_TABLE_COLUMN_WIDTHS.fullName}>
          <Avatar
            fallbackLetter={ticket.fullName?.charAt(0)}
            minWidth={40}
            src={ticket.profilePicture?.thumbnail64.url ?? undefined}
            variant={
              ticket.userKey
                ? getAvatarVariantFromString(ticket.userKey)
                : undefined
            }
          />
          <Text
            ml={8}
            variant="bodySm"
            text={ticket.fullName ?? ''}
            as="span"
          />
        </TableCell>

        <TableCell
          text={ticket.email ?? ''}
          width={TICKETS_TABLE_COLUMN_WIDTHS.email}
        />
        <TableCell
          width={TICKETS_TABLE_COLUMN_WIDTHS.ticketTypeName}
          text={ticket.ticketTypeName}
        />
        <TableCell
          width={TICKETS_TABLE_COLUMN_WIDTHS.foodPreferences}
          text={ticket.foodPreferences ?? ''}
        />
        <TableCell
          width={TICKETS_TABLE_COLUMN_WIDTHS.claimedAt}
          text={parseTimestamp(ticket.claimedAt, 'DD MMM YYYY HH:mm')}
        />
        <TableCell
          width={TICKETS_TABLE_COLUMN_WIDTHS.consumedAt}
          text={
            ticket.consumedAt
              ? parseTimestamp(ticket.consumedAt, 'DD MMM YYYY HH:mm')
              : ''
          }
        />

        <TableCell width={TICKETS_TABLE_COLUMN_WIDTHS.actions} fixedRight />
      </TableRow>
    );
  };

  if (status === 'pending') {
    return (
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead tx="label.view-activity.tickets.table.owner" />
            <TableHead tx="label.view-activity.tickets.table.email" />
            <TableHead tx="label.view-activity.tickets.table.ticket-type-name" />
            <TableHead tx="label.view-activity.tickets.table.food-preferences" />
            <TableHead tx="label.view-activity.tickets.table.claimed-at" />
            <TableHead tx="label.view-activity.tickets.table.consumed-at" />
            <TableHead fixedRight />
          </TableRow>
        </TableHeader>
        <TableBody>
          <TablePlaceholderRows
            rowCount={10}
            layout={Object.values(TICKETS_TABLE_COLUMN_WIDTHS)}
          />
        </TableBody>
      </Table>
    );
  }

  if (rows.length === 0) {
    return <EmptyState titleTx="placeholder.table.sold-tickets" />;
  }

  return (
    <React.Fragment>
      <Table>
        <TableHeader
          onSort={onSort}
          orderBy={pagination.orderBy}
          sortableColumns={Object.values(soldTicketsSortableKeys)}
          sortOrder={pagination.sortOrder}
        >
          <TableRow>
            <TableHead
              tx="label.view-activity.tickets.table.owner"
              name={soldTicketsSortableKeys.fullName}
            />
            <TableHead
              tx="label.view-activity.tickets.table.email"
              name={soldTicketsSortableKeys.email}
            />
            <TableHead
              tx="label.view-activity.tickets.table.ticket-type-name"
              name={soldTicketsSortableKeys.ticketTypeName}
            />
            <TableHead
              tx="label.view-activity.tickets.table.food-preferences"
              name={soldTicketsSortableKeys.foodPreferences}
            />
            <TableHead
              tx="label.view-activity.tickets.table.claimed-at"
              name={soldTicketsSortableKeys.claimedAt}
            />
            <TableHead
              tx="label.view-activity.tickets.table.consumed-at"
              name={soldTicketsSortableKeys.consumedAt}
            />

            <TableHead fixedRight />
          </TableRow>
        </TableHeader>
        <TableBody>{rows.map(renderTicketTableRow)}</TableBody>
      </Table>

      <Box flexJustify="end" gap={24} flexAlign="center" flex>
        <Box flex gap={16} flexAlign="center">
          <Text
            tx="label.general.rows-per-page"
            variant="bodySm"
            whiteSpace="nowrap"
          />

          <TinySelect
            invertMenu
            onChange={onPageSizeChange}
            options={paginatorOptions}
            value={paginatorProps.pageSize}
          />
        </Box>

        <Paginator
          currentPage={paginatorProps.currentPage}
          hasNextPage={paginatorProps.hasNextPage}
          hasPrevPage={paginatorProps.hasPrevPage}
          onPaginate={onPaginate}
        />
      </Box>
    </React.Fragment>
  );
}

function RevenuePieChart() {
  const currency = useSelector(OrbiPaySettingsSelector.selectCurrency);
  const ticketTypeStats = useSelector(
    ActivityDataSelector.selectTicketTypeStats,
  );

  const { data, labels, listItems } = ticketTypeStats.reduce<{
    data: number[];
    labels: string[];
    listItems: DatasetListItem[];
  }>(
    (prev, curr) => ({
      data: [...prev.data, curr.incomeSum],
      labels: [...prev.labels, curr.name],
      listItems: [
        ...prev.listItems,
        {
          label: curr.name,
          value: parseCurrency(curr.incomeSum, currency),
        },
      ],
    }),
    { data: [], labels: [], listItems: [] },
  );

  return (
    <PieChart
      datasets={[{ data }]}
      labels={labels}
      titleTx="label.view-activity.details-list.revenue"
      listItems={listItems}
      placeholderTitleTx="placeholder.revenue-data"
      unitPosition="right"
      unit={currency}
      subtitleText={parseCurrency(
        ticketTypeStats.reduce((prev, curr) => prev + curr.incomeSum, 0),
        currency,
      ).replace(currency, '')}
    />
  );
}

function TicketsPieChart() {
  const ticketTypeStats = useSelector(
    ActivityDataSelector.selectTicketTypeStats,
  );
  const totalTicketCount = useSelector(
    ActivityDataSelector.selectTotalTicketCount,
  );

  const numberOfSoldTickets = useSelector(
    ActivityDataSelector.selectNumberOfSoldTickets,
  );

  const { data, labels, listItems } = ticketTypeStats.reduce<{
    data: number[];
    labels: string[];
    listItems: DatasetListItem[];
  }>(
    (prev, curr) => ({
      data: [...prev.data, curr.numberOfSoldTickets],
      labels: [...prev.labels, curr.name],
      listItems: [
        ...prev.listItems,
        {
          label: curr.name,
          value: curr.numberOfSoldTickets,
        },
      ],
    }),
    { data: [], labels: [], listItems: [] },
  );

  return (
    <PieChart
      datasets={[{ data }]}
      labels={labels}
      titleTx="label.view-activity.details-list.tickets"
      listItems={listItems}
      placeholderTitleTx="placeholder.sold-ticket-data"
      subtitleText={`${numberOfSoldTickets}/${totalTicketCount}`}
    />
  );
}

const renderMultichoicePieChart = (listItem: MultiChoiceListItem) => {
  const { answers, question } = listItem;

  const { labels, data, listItems } = answers.reduce<{
    labels: string[];
    data: number[];
    listItems: DatasetListItem[];
  }>(
    (prev, curr) => ({
      labels: [...prev.labels, curr.label],
      data: [...prev.data, curr.numberOfAnswers],
      listItems: [
        ...prev.listItems,
        {
          label: curr.label,
          value: curr.numberOfAnswers,
        },
      ],
    }),
    { labels: [], data: [], listItems: [] },
  );

  return (
    <PieChart
      datasets={[{ data }]}
      key={listItem.id}
      title={question}
      labels={labels}
      listItems={listItems}
      placeholderTitleTx="placeholder.no-data"
      subtitleText={data.reduce((prev, curr) => prev + curr, 0)}
      unit={translate(
        'label.view-activity.tickets.requested-info.answers-count',
      )}
    />
  );
};

const renderCheckboxPieChart = (listItem: CheckboxListItem) => {
  const { answers, question } = listItem;

  return (
    <PieChart
      listItems={[
        { label: translate('label.boolean.yes'), value: answers.yes },
        { label: translate('label.boolean.no'), value: answers.no },
      ]}
      datasets={[{ data: [answers.yes, answers.no] }]}
      key={listItem.id}
      placeholderTitleTx="placeholder.no-data"
      title={question}
      subtitleText={`${answers.yes + answers.no}`}
      labels={[translate('label.boolean.yes'), translate('label.boolean.no')]}
      unit={translate(
        'label.view-activity.tickets.requested-info.answers-count',
      )}
    />
  );
};

function RequestedInfoPieCharts() {
  const requestedInfoStats = useSelector(
    ActivitySelector.selectRequestedInfoStats,
  );

  if (!requestedInfoStats) {
    return null;
  }

  return (
    <React.Fragment>
      {requestedInfoStats.multichoiceStats.map(renderMultichoicePieChart)}
      {requestedInfoStats.checkboxStats.map(renderCheckboxPieChart)}
    </React.Fragment>
  );
}

function DiscountsPieChart() {
  const currency = useSelector(OrbiPaySettingsSelector.selectCurrency);
  const discountsStats = useSelector(ActivityDiscountStatsSelector.selectData);

  if (!discountsStats?.length) {
    return null;
  }

  const { data, labels, listItems } = (discountsStats ?? []).reduce<{
    data: number[];
    labels: string[];
    listItems: DatasetListItem[];
  }>(
    (prev, curr) => {
      switch (curr.type) {
        case 'discount_code':
          const discountCode = `${curr.code ?? ''} (${curr.discountCount})`;

          return {
            data: [...prev.data, curr.discountSum],
            labels: [...prev.labels, discountCode],
            listItems: [
              ...prev.listItems,
              {
                label: discountCode,
                value: parseCurrency(curr.discountSum, currency),
              },
            ],
          };

        case 'membership':
          const membershipName = `${curr.name ?? ''} (${curr.discountCount})`;

          return {
            data: [...prev.data, curr.discountSum],
            labels: [...prev.labels, membershipName],
            listItems: [
              ...prev.listItems,
              {
                label: membershipName,
                value: parseCurrency(curr.discountSum, currency),
              },
            ],
          };

        default:
          return prev;
      }
    },
    { data: [], labels: [], listItems: [] },
  );

  return (
    <PieChart
      datasets={[{ data }]}
      labels={labels}
      titleTx="label.view-activity.details-list.discounts"
      listItems={listItems}
      placeholderTitleTx="placeholder.discount-data"
      unitPosition="right"
      unit={currency}
      subtitleText={parseCurrency(
        (discountsStats ?? []).reduce(
          (prev, curr) => prev + curr.discountSum,
          0,
        ),
        currency,
      ).replace(currency, '')}
    />
  );
}

function TicketStatsCards() {
  const ticketCount = useSelector(ActivityDataSelector.selectTicketCount);
  const consumedTicketCount = useSelector(
    ActivityDataSelector.selectConsumedTicketCount,
  );
  const soldTicketCount = useSelector(
    ActivityDataSelector.selectSoldTicketCount,
  );

  return (
    <React.Fragment>
      <Card textAlign="center">
        <CardHeader
          flexAlign="center"
          titleTx="label.view-activity.cards.total-ticket-count"
          subtitleText={ticketCount}
        />
      </Card>

      <Card textAlign="center">
        <CardHeader
          flexAlign="center"
          titleTx="label.view-activity.cards.unconsumed-ticket-count"
          subtitleText={soldTicketCount - consumedTicketCount}
        />
      </Card>

      <Card textAlign="center">
        <CardHeader
          flexAlign="center"
          titleTx="label.view-activity.cards.consumed-ticket-count"
          subtitleText={consumedTicketCount}
        />
      </Card>
    </React.Fragment>
  );
}

function TicketsContent() {
  return (
    <React.Fragment>
      <Box flex flexWrap="wrap" gap={16} flexJustify="between">
        <Text
          as="h1"
          color="pageTitle"
          tx="label.view-activity.tabs.tickets"
          variant="titleMd"
        />

        <EventActions />
      </Box>

      <Box flex flexDirection="column" gap={16}>
        <Styled.ResponsiveGrid>
          <TicketStatsCards />
        </Styled.ResponsiveGrid>

        <Styled.ResponsiveGrid>
          <RevenuePieChart />

          <TicketsPieChart />

          <DiscountsPieChart />

          <RequestedInfoPieCharts />
        </Styled.ResponsiveGrid>
      </Box>

      <Box flex flexDirection="column" gap={16}>
        <Text
          variant="titleSm"
          tx="label.view-activity.tickets.sold-tickets.label"
        />

        <SearchTickets />

        <TicketsTable />
      </Box>

      <FreeTextAnswersTable />
    </React.Fragment>
  );
}

export function ViewActivityTickets() {
  const activityKey = useSelector(ActivityDataSelector.selectActivityKey);
  const isActivityOwner = useSelector(ActivitySelector.selectIsActivityOwner);

  if (!isActivityOwner) {
    return <Navigate to={`/activities/${activityKey}/description`} />;
  }

  return (
    <InnerPageContainer>
      <ContentContainer>
        <InnerContentContainer>
          <TicketsContent />
        </InnerContentContainer>
      </ContentContainer>

      <TicketSidebar />
    </InnerPageContainer>
  );
}
