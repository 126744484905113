import { GeneralApiResultWithData } from '@orbiapp/components';

import {
  Department,
  DepartmentForm,
  UpdateDepartmentResponse,
} from '../../../../../models';
import { Logger } from '../../../../logger';
import { apisauce } from '../../../api';
import { getApiLogExtras, getGeneralApiProblem } from '../../../api.utils';
import { DepartmentDto } from './department.dto';
import { departmentMapper, updateDepartmentMapper } from './department.mappers';

export const getDepartment = async (): Promise<
  GeneralApiResultWithData<Department>
> => {
  const res = await apisauce.get<DepartmentDto, any>('/v2/department');

  if (!res.ok) {
    Logger.warning('getDepartment', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = departmentMapper(res.data);

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('getDepartment', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export const updateDepartment = async (
  departmentForm: DepartmentForm,
): Promise<GeneralApiResultWithData<UpdateDepartmentResponse>> => {
  const res = await apisauce.put<UpdateDepartmentResponse, any>(
    '/v2/department',
    updateDepartmentMapper(departmentForm),
  );

  if (!res.ok) {
    Logger.warning('updateDepartment', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    return { kind: 'ok', data: res.data };
  } catch (err) {
    Logger.error('updateDepartment', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};
