import { createAsyncThunk } from '@reduxjs/toolkit';

import { FeatureFlags } from '../../models';
import { OrbiApi, v1 } from '../../services';
import { ThunkApiConfig } from '../store.types';

export const getFeatureFlagsThunk = createAsyncThunk<
  FeatureFlags,
  undefined,
  ThunkApiConfig
>('feature-flags/get', async (_, thunkAPI) => {
  const state = thunkAPI.getState();
  if (state.featureFlags.featureFlags.data) {
    return state.featureFlags.featureFlags.data;
  }

  const res = await OrbiApi.call(v1.featureFlags.getFeatureFlags, undefined);

  if (res.kind === 'ok') {
    return res.data;
  }

  return thunkAPI.rejectWithValue(res);
});
