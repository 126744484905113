import {
  GeneralApiResultWithData,
  countryToCurrency,
} from '@orbiapp/components';

import {
  CreateOrbiPayAccount,
  CreateStripeAccountLink,
  OrbiPaySettings,
  Payout,
  PayoutListItem,
  PayoutsFiltering,
} from '../../../../../models';
import { Logger } from '../../../../logger';
import { apisauce } from '../../../api';
import { CUSTOM_TIMEOUTS } from '../../../api.constants';
import { getApiLogExtras, getGeneralApiProblem } from '../../../api.utils';
import {
  OrbiPaySettingsDto,
  PayoutDto,
  PayoutListItemDto,
} from './orbi-pay.dto';
import {
  orbiPaySettingsMapper,
  payoutListItemMapper,
  payoutMapper,
} from './orbi-pay.mappers';

export const getOrbiPaySettings = async (): Promise<
  GeneralApiResultWithData<OrbiPaySettings>
> => {
  const res = await apisauce.get<OrbiPaySettingsDto, any>(
    '/v1/orbi-pay',
    undefined,
    { timeout: CUSTOM_TIMEOUTS.getOrbiPaySettings },
  );

  if (!res.ok) {
    Logger.warning('getOrbiPaySettings', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data: OrbiPaySettings = orbiPaySettingsMapper(res.data);

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('getOrbiPaySettings', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export const createOrbiPayAccount = async (
  createOrbiPayAccount: CreateOrbiPayAccount,
): Promise<GeneralApiResultWithData<string>> => {
  const {
    email,
    vatPercent,
    countryCode,
    businessType,
    bankAccountTokenId,
    acceptedTermsOfService,
  } = createOrbiPayAccount;

  const res = await apisauce.post<{ orbiPayAdminKey: string }, any>(
    '/v1/orbi-pay',
    {
      email,
      vatPercent: Number(vatPercent),
      countryCode,
      businessType,
      bankAccountTokenId,
      acceptedTermsOfService,
      currency: countryToCurrency(countryCode),
    },
  );

  if (!res.ok) {
    Logger.warning('createOrbiPayAccount', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = res.data.orbiPayAdminKey;

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('createOrbiPayAccount', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export const getStripeAccountLink = async (
  createStripeAccountLink: CreateStripeAccountLink,
): Promise<GeneralApiResultWithData<string>> => {
  const res = await apisauce.post<{ accountLink: string }, any>(
    '/v1/orbi-pay/account-link',
    createStripeAccountLink,
  );

  if (!res.ok) {
    Logger.warning('getStripeAccountLink', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data: string = res.data.accountLink;

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('getStripeAccountLink', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export const getPayouts = async (
  payoutsFiltering: PayoutsFiltering,
): Promise<GeneralApiResultWithData<PayoutListItem[]>> => {
  const res = await apisauce.get<PayoutListItemDto[], any>(
    '/v1/orbi-pay/payouts/listed',
    payoutsFiltering,
  );

  if (!res.ok) {
    Logger.warning('getPayouts', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data: PayoutListItem[] = res.data.map(payoutListItemMapper);

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('getPayouts', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export const getPayout = async (
  payoutId: string,
): Promise<GeneralApiResultWithData<Payout>> => {
  const res = await apisauce.get<PayoutDto, any>(
    `/v1/orbi-pay/payouts/payout/${payoutId}`,
    undefined,
    { timeout: CUSTOM_TIMEOUTS.getPayout },
  );

  if (!res.ok) {
    Logger.warning('getPayout', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data: Payout = payoutMapper(res.data);

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('getPayout', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export const getPublicStripeKey = async (): Promise<
  GeneralApiResultWithData<string>
> => {
  const res = await apisauce.get<{ publicStripeKey: string }, any>(
    '/v1/orbi-pay/stripe-key',
  );

  if (!res.ok) {
    Logger.warning('getPublicStripeKey', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    return { kind: 'ok', data: res.data.publicStripeKey };
  } catch (err) {
    Logger.error('getPublicStripeKey', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};
