import { ENV } from './config';

import { Time, reportWebVitals } from '@orbiapp/components';
import * as Sentry from '@sentry/react';
import React from 'react';
import { createRoot } from 'react-dom/client';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import App from './app';
import { openDatabase } from './services';

const container = document.getElementById('app');
const root = createRoot(container!);

Sentry.init({
  dsn: import.meta.env.VITE_APP_SENTRY_DSN,
  environment: ENV,
  tracesSampleRate: ENV === 'production' ? 0.2 : 0,
  replaysOnErrorSampleRate: ENV === 'production' ? 1 : 0,
  replaysSessionSampleRate: ENV === 'production' ? 0.25 : 0,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
    Sentry.inboundFiltersIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
      maskAllInputs: false,
      maskAttributes: ['password', 'credit_card'],
      minReplayDuration: Time.Minute,
      networkDetailAllowUrls: [/https:\/\/data.orbiapp.io\/.*/],
      networkRequestHeaders: [
        'Authorization',
        'X-Orb-Department',
        'X-Orb-Trace',
      ],
    }),
    Sentry.replayCanvasIntegration(),
    Sentry.breadcrumbsIntegration(),
  ],
});

openDatabase('orbi');

if (ENV === 'testing') {
  reportWebVitals(console.log);
}

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
