import { Base64File, stringHasLengthOrNull, toScu } from '@orbiapp/components';

import {
  CreateMembershipTypeForm,
  MembershipApplicationRequirements,
  MembershipMultichoiceOptionForm,
  MembershipPeriodForm,
  RequestedInfoType,
} from '../../../../../models';
import {
  CreateMembershipTypeDto,
  MembershipApplicationRequirementsDto,
  MembershipCheckboxQuestionDto,
  MembershipFreetextQuestionDto,
  MembershipMultichoiceOptionDto,
  MembershipMultichoiceQuestionDto,
  MembershipPeriodDto,
} from './create-membership-type.dto';

const multichoiceOptionMapper = (
  option: MembershipMultichoiceOptionForm,
  index: number,
): MembershipMultichoiceOptionDto => ({
  index,
  label: option.label,
});

const periodMapper = (period: MembershipPeriodForm): MembershipPeriodDto => {
  return {
    name: period.name,
    price: toScu(period.price),
    saleEnd: period.saleEnd,
    saleStart: period.saleStart,
    validFrom: period.lifetime ? null : period.validFrom,
    validTo: period.lifetime ? null : period.validTo,
  };
};

const requiredMembershipTypesMapper = (
  requiredMembershipTypes: MembershipApplicationRequirements | null,
): MembershipApplicationRequirementsDto | null => {
  if (!requiredMembershipTypes?.membershipApplicationRequirements.length) {
    return null;
  }

  return {
    combinationType: requiredMembershipTypes.combinationType,
    membershipTypeKeys:
      requiredMembershipTypes.membershipApplicationRequirements.map(
        (requirement) => requirement.membershipTypeKey,
      ),
  };
};

export const createMembershipTypeMapper = (
  createMembershipType: CreateMembershipTypeForm,
): CreateMembershipTypeDto => {
  const questions = createMembershipType.createMembershipQuestions?.reduce<{
    checkboxQuestions: MembershipCheckboxQuestionDto[];
    freetextQuestions: MembershipFreetextQuestionDto[];
    multichoiceQuestions: MembershipMultichoiceQuestionDto[];
  }>(
    (acc, question, index) => {
      switch (question.type) {
        case RequestedInfoType.Checkbox:
          acc.checkboxQuestions.push({
            index,
            isRequired: question.isRequired,
            question: question.question,
          });
          break;

        case RequestedInfoType.FreeText:
          acc.freetextQuestions.push({
            index,
            isRequired: question.isRequired,
            question: question.question,
          });
          break;

        case RequestedInfoType.MultiChoice:
          acc.multichoiceQuestions.push({
            index,
            isRequired: question.isRequired,
            options: question.options.map(multichoiceOptionMapper),
            question: question.question,
          });
          break;
      }

      return acc;
    },
    {
      checkboxQuestions: [],
      freetextQuestions: [],
      multichoiceQuestions: [],
    },
  );

  const logo: Base64File = {
    base64: createMembershipType.logo!.base64,
    fileName: createMembershipType.logo!.fileName,
    fileType: createMembershipType.logo!.fileType,
  };

  const backgroundImage: Base64File = {
    base64: createMembershipType.backgroundImage!.base64,
    fileName: createMembershipType.backgroundImage!.fileName,
    fileType: createMembershipType.backgroundImage!.fileType,
  };

  const periods = createMembershipType.periods?.map(periodMapper) ?? null;

  const createMembershipTypeDto: CreateMembershipTypeDto = {
    backgroundImage,
    checkboxQuestions: questions?.checkboxQuestions.length
      ? questions.checkboxQuestions
      : null,
    description: createMembershipType.description,
    freetextQuestions: questions?.freetextQuestions.length
      ? questions.freetextQuestions
      : null,
    logo,
    contactDetails: {
      email: createMembershipType.contactDetails.email!,
      name: createMembershipType.contactDetails.name!,
      phone: stringHasLengthOrNull(createMembershipType.contactDetails.phone),
    },
    multichoiceQuestions: questions?.multichoiceQuestions.length
      ? questions.multichoiceQuestions
      : null,
    name: createMembershipType.name,
    periods,
    requiredMembershipTypes: requiredMembershipTypesMapper(
      createMembershipType.requiredMembershipTypes,
    ),
    type: createMembershipType.type,
  };

  return createMembershipTypeDto;
};
