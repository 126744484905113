import {
  GeneralApiResult,
  GeneralApiResultWithData,
  Pagination,
} from '@orbiapp/components';

import {
  MembershipApplication,
  MembershipApplicationListItem,
  MembershipApplicationOrderByKey,
  MembershipApplicationStatus,
  RejectMembershipApplicationForm,
  RequestMoreInfoOnMembershipApplicationForm,
} from '../../../../../../models';
import { Logger } from '../../../../../logger';
import { apisauce } from '../../../../api';
import { getApiLogExtras, getGeneralApiProblem } from '../../../../api.utils';
import {
  MembershipApplicationDto,
  MembershipApplicationListItemDto,
} from './applications.dto';
import {
  membershipApplicationListItemMapper,
  membershipApplicationMapper,
  rejectMembershipApplicationMapper,
  requestMoreInfoOnMembershipApplicationMapper,
} from './applications.mappers';

export const getMembershipApplications = async (params: {
  membershipTypeKey: string;
  pagination: Pagination<MembershipApplicationOrderByKey>;
  includeStatus: MembershipApplicationStatus | null;
}): Promise<GeneralApiResultWithData<MembershipApplicationListItem[]>> => {
  const { membershipTypeKey, includeStatus, pagination } = params;

  const res = await apisauce.get<MembershipApplicationListItemDto[], any>(
    `/v2/membership-types/${membershipTypeKey}/applications`,
    { ...pagination, status: includeStatus ?? undefined },
  );

  if (!res.ok) {
    Logger.warning('getMembershipApplications', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data: MembershipApplicationListItem[] = res.data.map(
      membershipApplicationListItemMapper,
    );

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('getMembershipApplications', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export const getMembershipApplication = async (params: {
  membershipTypeKey: string;
  membershipApplicationKey: string;
}): Promise<GeneralApiResultWithData<MembershipApplication>> => {
  const { membershipTypeKey, membershipApplicationKey } = params;

  const res = await apisauce.get<MembershipApplicationDto, any>(
    `/v2/membership-types/${membershipTypeKey}/applications/${membershipApplicationKey}`,
  );

  if (!res.ok) {
    Logger.warning('getMembershipApplication', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data: MembershipApplication = membershipApplicationMapper(res.data);

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('getMembershipApplication', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export const acceptMembershipApplication = async (params: {
  membershipTypeKey: string;
  membershipApplicationKey: string;
}): Promise<GeneralApiResult> => {
  const { membershipTypeKey, membershipApplicationKey } = params;

  const res = await apisauce.put<void, any>(
    `/v2/membership-types/${membershipTypeKey}/applications/${membershipApplicationKey}/accept`,
  );

  if (!res.ok) {
    Logger.warning('acceptMembershipApplication', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  return { kind: 'ok' };
};

export const rejectMembershipApplication = async (params: {
  membershipTypeKey: string;
  rejectMembershipApplicationForm: RejectMembershipApplicationForm;
}): Promise<GeneralApiResult> => {
  const { membershipTypeKey, rejectMembershipApplicationForm } = params;

  const res = await apisauce.put<void, any>(
    `/v2/membership-types/${membershipTypeKey}/applications/${rejectMembershipApplicationForm.membershipApplicationKey}/reject`,
    rejectMembershipApplicationMapper(rejectMembershipApplicationForm),
  );

  if (!res.ok) {
    Logger.warning('rejectMembershipApplication', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  return { kind: 'ok' };
};

export const requestMoreInfoOnMembershipApplication = async (params: {
  membershipTypeKey: string;
  requestedMoreInfoOnMembershipApplicationForm: RequestMoreInfoOnMembershipApplicationForm;
}): Promise<GeneralApiResult> => {
  const { membershipTypeKey, requestedMoreInfoOnMembershipApplicationForm } =
    params;

  const res = await apisauce.put<void, any>(
    `/v2/membership-types/${membershipTypeKey}/applications/${requestedMoreInfoOnMembershipApplicationForm.membershipApplicationKey}/request-more-info`,
    requestMoreInfoOnMembershipApplicationMapper(
      requestedMoreInfoOnMembershipApplicationForm,
    ),
  );

  if (!res.ok) {
    Logger.warning('requestMoreInfoOnMembershipApplication', {
      res: JSON.stringify(res),
    });
    return getGeneralApiProblem(res);
  }

  return { kind: 'ok' };
};
