import { Time, useAnchoredMenu, useModalState } from '@orbiapp/components';
import React from 'react';

export const QuickActionsContext = React.createContext<{
  createMembershipModalState: ReturnType<typeof useModalState>;
  createPostModalState: ReturnType<typeof useModalState>;
}>({
  createMembershipModalState: {
    closeModal: () => {},
    isOpen: false,
    openModal: () => {},
  },
  createPostModalState: {
    closeModal: () => {},
    isOpen: false,
    openModal: () => {},
  },
});

export function QuickActionsProvider(props: React.PropsWithChildren) {
  const { children } = props;

  const createMembershipModalState = useModalState();
  const createPostModalState = useModalState();

  return (
    <QuickActionsContext.Provider
      value={{
        createMembershipModalState,
        createPostModalState,
      }}
    >
      {children}
    </QuickActionsContext.Provider>
  );
}

interface ProfileMenuContextType extends ReturnType<typeof useAnchoredMenu> {
  anchorRef: React.RefObject<HTMLDivElement>;
  clickOutsideRef: React.RefObject<HTMLDivElement>;
  menuClickOutsideRef: React.RefObject<HTMLDivElement>;
}

export const ProfileMenuContext = React.createContext<ProfileMenuContextType>({
  anchorRef: { current: null },
  clickOutsideRef: { current: null },
  closeMenu: () => {},
  isOpen: false,
  menuClickOutsideRef: { current: null },
  menuRef: { current: null },
  openMenu: () => {},
  toggleMenu: () => {},
});

export function ProfileMenuProvider(props: React.PropsWithChildren) {
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const clickOutsideRef = React.useRef<HTMLDivElement>(null);
  const menuClickOutsideRef = React.useRef<HTMLDivElement>(null);

  const profileMenuState = useAnchoredMenu({
    anchorRef,
    placement: 'bottom-end',
    autoCloseDuration: 5 * Time.Second,
    clickOutsideRef: [menuClickOutsideRef, anchorRef],
  });

  return (
    <ProfileMenuContext.Provider
      value={{
        ...profileMenuState,
        anchorRef,
        clickOutsideRef,
        menuClickOutsideRef,
      }}
      {...props}
    />
  );
}
