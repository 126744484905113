import { stringHasLengthOrNull } from '@orbiapp/components';

import {
  Department,
  DepartmentForm,
  DepartmentLocation,
  PartialOrgNode,
} from '../../../../../models';
import {
  DepartmentDto,
  OrgNodeDto,
  UpdateDepartmentDto,
} from './department.dto';

const orgNodeMapper = (orgNode: OrgNodeDto): PartialOrgNode => ({
  name: orgNode.name,
  orgNodeKey: orgNode.orgNodeKey,
  parentKey: orgNode.parentKey,
  type: orgNode.type,
});

const LINKOPING_KEY = '-KqgTRAYh2diOyp5pUPg';

const LINKOPING_LOCATIONS: DepartmentLocation[] = [
  {
    address: 'Kårhuset Kollektivet',
    coordinates: { latitude: 58.41187906515532, longitude: 15.61943905094524 },
    placeId: 'ChIJ0ZXK1SRvWUYRafdXEjMkzXQ',
  },
  {
    address: 'Kårhus Ryds Herrgård',
    coordinates: { latitude: 58.41003499136027, longitude: 15.563404678080254 },
    placeId: 'ChIJ3eDhdmBvWUYRJiX4rY4CGU4',
  },
  {
    address: 'Kårhus Örat',
    coordinates: { latitude: 58.40404966081969, longitude: 15.622587869720476 },
    placeId: 'ChIJaSfYGCJvWUYRLw8j6cEaLsM',
  },
  {
    address: 'Kårhuset Trappan',
    coordinates: { latitude: 58.59019855860517, longitude: 16.178083424609135 },
    placeId: 'ChIJUQvrrco7WUYRLDZ_POb1UDM',
  },
  {
    address: 'Kårhus Kårallen',
    coordinates: { latitude: 58.40415627792384, longitude: 15.585900704735373 },
    placeId:
      'EipTdHJhdG9tdGF2w6RnZW4sIDU4MyAzMCBMaW5rw7ZwaW5nLCBTd2VkZW4iLiosChQKEglt35traW9ZRhFEocmbBBX5YhIUChIJKd8nPX1vWUYRQkmmGPP-ABM',
  },
  {
    address: 'Kårhus Kårhusett',
    coordinates: { latitude: 58.40061669476713, longitude: 15.576538835432805 },
    placeId:
      'Ei5IYW5zIE1laWplcnMgdsOkZyAxLCA1ODMgMzAgTGlua8O2cGluZywgU3dlZGVuIjASLgoUChIJGarPb29vWUYRTTJMn2JysnAQASoUChIJ-Yoqe29vWUYRoLGO-qL5RaY',
  },
];

const getIsLinkoping = (orgNodesBranch: OrgNodeDto[]) =>
  orgNodesBranch.some((orgNode) => orgNode.orgNodeKey === LINKOPING_KEY);

export const departmentMapper = (departmentDto: DepartmentDto): Department => {
  const schoolName =
    departmentDto.orgNodesBranch.find((orgNode) => orgNode.type === 'school')
      ?.name ?? '';

  const isLinkoping = getIsLinkoping(departmentDto.orgNodesBranch);

  const departmentSettings: Department = {
    about: departmentDto.about,
    coverImage: departmentDto.coverImage,
    departmentKey: departmentDto.departmentKey,
    email: departmentDto.email,
    enableTags: isLinkoping,
    facebookUrl: departmentDto.facebookUrl,
    followerCount: departmentDto.followerCount,
    instagramUrl: departmentDto.instagramUrl,
    isCareerAdmin: departmentDto.isCareerAdmin,
    locations: isLinkoping ? LINKOPING_LOCATIONS : null,
    logo: departmentDto.logo,
    name: departmentDto.name,
    orgNodesBranch: departmentDto.orgNodesBranch.map(orgNodeMapper),
    phone: departmentDto.phone,
    referredAt: departmentDto.referredAt,
    schoolName,
    segmentationType: departmentDto.segmentation,
    twitterUrl: departmentDto.twitterUrl,
    websiteUrl: departmentDto.websiteUrl,
    youtubeUrl: departmentDto.youtubeUrl,
  };

  return departmentSettings;
};

export const updateDepartmentMapper = (
  departmentSettingsForm: DepartmentForm,
): UpdateDepartmentDto => {
  const updateDepartmentDto: UpdateDepartmentDto = {
    about: stringHasLengthOrNull(departmentSettingsForm.about),
    coverImage: departmentSettingsForm.coverImage!,
    email: stringHasLengthOrNull(departmentSettingsForm.email),
    facebookUrl: stringHasLengthOrNull(departmentSettingsForm.facebookUrl),
    instagramUrl: stringHasLengthOrNull(departmentSettingsForm.instagramUrl),
    logo: departmentSettingsForm.logo!,
    name: departmentSettingsForm.name,
    phone: stringHasLengthOrNull(departmentSettingsForm.phone),
    twitterUrl: stringHasLengthOrNull(departmentSettingsForm.twitterUrl),
    youtubeUrl: stringHasLengthOrNull(departmentSettingsForm.youtubeUrl),
    websiteUrl: stringHasLengthOrNull(departmentSettingsForm.websiteUrl),
  };

  return updateDepartmentDto;
};
