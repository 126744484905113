import { joiResolver } from '@hookform/resolvers/joi';
import {
  Box,
  Button,
  Checkbox,
  ControlledSelect,
  ControlledTextarea,
  Modal,
  ModalBodyContainer,
  ModalContentContainer,
  ModalFooterContainer,
  ModalHeaderContainer,
  ModalTitle,
  Select,
  Spinner,
  Text,
  flattenFieldErrorsObject,
} from '@orbiapp/components';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import {
  ACTIVITY_POST_MESSAGE_MAX_LENGTH,
  AudienceType,
  CreateActivityPost,
  CreateActivityPostValidation,
  CreateDepartmentPost,
  CreateDepartmentPostValidation,
  DEPARTMENT_POST_MESSAGE_MAX_LENGTH,
  Semester,
  semesters,
} from '../../../../models';
import { Logger } from '../../../../services';
import {
  ActivityRecordsSelector,
  CreateActivityPostSelector,
  CreateDepartmentPostSelector,
  DepartmentSelector,
  createActivityPostThunk,
  createDepartmentPostThunk,
  getActivityRecordsThunk,
  useDispatch,
  useSelector,
} from '../../../../store';
import { getOptionalLabelText } from '../../../../utils';
import { QuickActionsContext } from '../signed-in.context';
import { Styled } from './create-post-modal.styled';
import { CreatePostContextType } from './create-post-modal.types';

const CreatePostContext = React.createContext<CreatePostContextType>({
  setState: () => {},
  state: { mode: 'pick', postType: 'department' },
});

function CreatePostProvider(props: React.PropsWithChildren) {
  const { children } = props;

  const [state, setState] = React.useState<CreatePostContextType['state']>({
    mode: 'pick',
    postType: 'department',
  });

  return (
    <CreatePostContext.Provider value={{ state, setState }}>
      {children}
    </CreatePostContext.Provider>
  );
}

function SelectionCard(props: {
  disabled?: boolean;
  isLoading?: boolean;
  onClick: () => void;
  subtitleTx: TxString;
  titleTx: TxString;
  toggled: boolean;
}) {
  const { disabled, isLoading, onClick, subtitleTx, titleTx, toggled } = props;

  return (
    <Styled.SelectPostTypeCard
      aria-disabled={disabled}
      disabled={disabled || isLoading}
      onClick={onClick}
      toggled={toggled}
      flexGrow={1}
      flexBasis={0}
      minWidth={300}
    >
      {isLoading && (
        <Box
          flex
          flexJustify="center"
          flexAlign="center"
          zIndex={5}
          absolute
          inset
        >
          <Spinner />
        </Box>
      )}

      <Text
        tx={titleTx}
        variant="bodyMdBold"
        color={toggled ? 'selectionCardTitleToggled' : 'selectionCardTitle'}
      />
      <Text
        maxWidth="32ch"
        tx={subtitleTx}
        variant="bodyMd"
        color={toggled ? 'selectionCardLabelToggled' : 'selectionCardLabel'}
      />
    </Styled.SelectPostTypeCard>
  );
}

function PickEventSelect() {
  const activityRecords = useSelector(ActivityRecordsSelector.selectData);

  if (!activityRecords?.length) {
    return (
      <Select
        labelTx="label.posts.create-post-modal.event"
        value=""
        options={[
          {
            notPickable: true,
            tx: 'placeholder.no-upcoming-events',
            value: ' ',
          },
        ]}
      />
    );
  }

  return (
    <ControlledSelect
      name="activityKey"
      labelTx="label.posts.create-post-modal.event"
      options={activityRecords.map((activityRecord) => ({
        text: activityRecord.activityTitle,
        value: activityRecord.activityKey,
      }))}
    />
  );
}

function CreateEventPost() {
  const activityRecordsCount = useSelector(ActivityRecordsSelector.selectCount);

  const createEventPostStatus = useSelector(
    CreateActivityPostSelector.selectStatus,
  );

  const dispatch = useDispatch();

  const { createPostModalState } = React.useContext(QuickActionsContext);
  const createPostContext = React.useContext(CreatePostContext);

  const cancelCreatePost = () => {
    createPostContext.setState({
      mode: 'pick',
      postType: 'event',
    });
  };

  const isLoading = createEventPostStatus === 'pending';

  const formMethods = useForm<CreateActivityPost>({
    resolver: joiResolver(CreateActivityPostValidation),
    defaultValues: {
      activityKey: '',
      message: '',
      audiences: [],
    },
  });

  const createPost = formMethods.handleSubmit(
    async (data) => {
      const res = await dispatch(createActivityPostThunk(data));

      if (res.meta.requestStatus === 'fulfilled') {
        formMethods.reset();

        createPostModalState.closeModal();
        createPostContext.setState({
          mode: 'pick',
          postType: 'department',
        });
      }
    },
    (err) => {
      Logger.warning('createEventPost Validation', {
        err: flattenFieldErrorsObject(err),
      });
    },
  );

  const audiences = formMethods.watch('audiences');

  const attendeesIsSelected = audiences?.includes('attendees') ?? false;
  const savesIsSelected = audiences?.includes('saves') ?? false;
  const allIsSelected =
    attendeesIsSelected &&
    savesIsSelected &&
    semesters.every((semester) => audiences?.includes(semester) ?? false);

  const selectAll = () => {
    if (allIsSelected) {
      formMethods.setValue('audiences', []);
    } else {
      formMethods.setValue('audiences', ['attendees', 'saves', ...semesters]);
    }
  };

  const selectAttendees = () => {
    if (attendeesIsSelected) {
      formMethods.setValue(
        'audiences',
        audiences?.filter((audience) => audience !== 'attendees') ?? [],
      );
    } else {
      formMethods.setValue('audiences', [...(audiences ?? []), 'attendees']);
    }
  };

  const selectSaved = () => {
    if (savesIsSelected) {
      formMethods.setValue(
        'audiences',
        audiences?.filter((audience) => audience !== 'saves') ?? [],
      );
    } else {
      formMethods.setValue('audiences', [...(audiences ?? []), 'saves']);
    }
  };

  return (
    <ModalContentContainer>
      <ModalHeaderContainer>
        <ModalTitle tx="label.posts.create-post-modal.new-event-post" />
      </ModalHeaderContainer>

      <ModalBodyContainer>
        <FormProvider {...formMethods}>
          <PickEventSelect />

          <ControlledTextarea
            disabled={isLoading}
            labelTx="label.posts.create-post.message"
            maxLength={ACTIVITY_POST_MESSAGE_MAX_LENGTH}
            name="message"
          />

          <Text
            variant="bodyMdBold"
            text={getOptionalLabelText('label.posts.create-post.audiences')}
          />

          <Checkbox
            checked={allIsSelected}
            onChange={selectAll}
            tx="label.view-activity.posts.create-post.all"
          />

          <Checkbox
            checked={attendeesIsSelected}
            disabled={allIsSelected}
            onChange={selectAttendees}
            tx="label.view-activity.posts.create-post.attendees"
          />

          <Checkbox
            checked={savesIsSelected}
            disabled={allIsSelected}
            onChange={selectSaved}
            tx="label.view-activity.posts.create-post.saves"
          />
        </FormProvider>
      </ModalBodyContainer>

      <ModalFooterContainer>
        <Button
          onClick={cancelCreatePost}
          tx="button.cancel"
          variant="tertiary"
        />
        <Button
          isLoading={isLoading}
          onClick={createPost}
          tx="button.posts.create"
          variant="primary"
          disabled={activityRecordsCount === 0}
        />
      </ModalFooterContainer>
    </ModalContentContainer>
  );
}

function CreateDepartmentPostModalContent() {
  const createDepartmentPostStatus = useSelector(
    CreateDepartmentPostSelector.selectStatus,
  );
  const segmentationType = useSelector(
    DepartmentSelector.selectSegmentationType,
  );
  const isSemester = useSelector(DepartmentSelector.selectIsSemester);

  const dispatch = useDispatch();

  const { createPostModalState } = React.useContext(QuickActionsContext);
  const createPostContext = React.useContext(CreatePostContext);

  const isLoading = createDepartmentPostStatus === 'pending';

  const cancelCreatePost = () => {
    createPostContext.setState({
      mode: 'pick',
      postType: 'department',
    });
  };

  const formMethods = useForm<CreateDepartmentPost>({
    resolver: joiResolver(CreateDepartmentPostValidation),
    defaultValues: {
      message: '',
      audiences: [],
    },
  });

  const audiences = formMethods.watch('audiences') ?? [];

  const createPost = formMethods.handleSubmit(
    async (data) => {
      const res = await dispatch(createDepartmentPostThunk(data));

      if (res.meta.requestStatus === 'fulfilled') {
        formMethods.reset();

        createPostModalState.closeModal();
        createPostContext.setState({
          mode: 'pick',
          postType: 'department',
        });
      }
    },
    (err) => {
      Logger.warning('createDepartmentPost Validation', {
        err: flattenFieldErrorsObject(err),
      });
    },
  );

  const segmentationValues = isSemester
    ? semesters
    : semesters.slice(0, semesters.length / 2);

  const renderCheckbox = (semester: Semester, index: number) => {
    const semestersToToggle: AudienceType[] = isSemester
      ? [semester]
      : [semesters[index * 2], semesters[index * 2 + 1]];

    const isChecked = semestersToToggle.every((semester) =>
      audiences?.includes(semester),
    );

    const selectAudienceType = () => {
      formMethods.setValue(
        'audiences',
        isChecked
          ? audiences.filter(
              (audience) => !semestersToToggle.includes(audience),
            )
          : [...audiences, ...semestersToToggle],
      );
    };

    const tx = isSemester
      ? 'label.posts.create-post.semester'
      : 'label.posts.create-post.year';

    return (
      <Checkbox
        checked={isChecked}
        onChange={selectAudienceType}
        tx={tx}
        key={`semester-${semester}`}
        txArgs={{ index: (index + 1).toString() }}
      />
    );
  };

  const allSemestersAreSelected = semesters.every((semester) =>
    audiences?.includes(semester),
  );

  const toggleAll = () => {
    const newState: AudienceType[] = allSemestersAreSelected
      ? audiences.filter((audience) => !semesters.includes(audience as any))
      : [...audiences, ...semesters];

    formMethods.setValue('audiences', newState);
  };

  return (
    <FormProvider {...formMethods}>
      <ModalContentContainer>
        <ModalHeaderContainer>
          <ModalTitle tx="label.posts.create-post-modal.new-department-post" />
        </ModalHeaderContainer>

        <ModalBodyContainer>
          <ControlledTextarea
            disabled={isLoading}
            labelTx="label.posts.create-post.message"
            maxLength={DEPARTMENT_POST_MESSAGE_MAX_LENGTH}
            name="message"
          />

          <Text
            variant="bodyMdBold"
            text={getOptionalLabelText('label.posts.create-post.audiences')}
          />

          <Checkbox
            onChange={toggleAll}
            tx={
              segmentationType === 'semester'
                ? 'label.posts.create-post.all-semesters'
                : 'label.posts.create-post.all-years'
            }
            checked={allSemestersAreSelected}
          />

          <Styled.CheckboxGrid>
            {segmentationValues.map(renderCheckbox)}
          </Styled.CheckboxGrid>
        </ModalBodyContainer>

        <ModalFooterContainer>
          <Button
            onClick={cancelCreatePost}
            tx="button.cancel"
            variant="tertiary"
          />
          <Button
            isLoading={isLoading}
            onClick={createPost}
            tx="button.posts.create"
            variant="primary"
          />
        </ModalFooterContainer>
      </ModalContentContainer>
    </FormProvider>
  );
}

function PickPostType() {
  const getActivityRecordsStatus = useSelector(
    ActivityRecordsSelector.selectStatus,
  );

  const { createPostModalState } = React.useContext(QuickActionsContext);
  const createPostContext = React.useContext(CreatePostContext);

  const selectPostType =
    (postType: CreatePostContextType['state']['postType']) => () => {
      createPostContext.setState((prevState) => ({
        ...prevState,
        postType,
      }));
    };

  const continueToForm = () => {
    createPostContext.setState((prevState) => ({
      ...prevState,
      mode: 'form',
    }));
  };

  return (
    <ModalContentContainer>
      <ModalHeaderContainer>
        <ModalTitle
          textAlign="center"
          tx="label.posts.create-post-modal.select-type-of-post"
        />
      </ModalHeaderContainer>

      <ModalBodyContainer>
        <Box gap={24} flex flexJustify="center" flexWrap="wrap">
          <SelectionCard
            onClick={selectPostType('department')}
            subtitleTx="label.posts.create-post-modal.department-post-subtitle"
            titleTx="label.posts.create-post-modal.department-post-title"
            toggled={createPostContext.state.postType === 'department'}
          />

          <SelectionCard
            isLoading={getActivityRecordsStatus === 'pending'}
            onClick={selectPostType('event')}
            subtitleTx="label.posts.create-post-modal.event-post-subtitle"
            titleTx="label.posts.create-post-modal.event-post-title"
            toggled={createPostContext.state.postType === 'event'}
          />
        </Box>
      </ModalBodyContainer>

      <ModalFooterContainer>
        <Button
          tx="button.cancel"
          variant="tertiary"
          onClick={createPostModalState.closeModal}
        />

        <Button
          onClick={continueToForm}
          tx="button.continue"
          variant="primary"
        />
      </ModalFooterContainer>
    </ModalContentContainer>
  );
}

function ModalContent() {
  const createPostContext = React.useContext(CreatePostContext);

  if (createPostContext.state.mode === 'pick') {
    return <PickPostType />;
  }

  if (createPostContext.state.postType === 'department') {
    return <CreateDepartmentPostModalContent />;
  }

  return <CreateEventPost />;
}

export function CreatePostModal() {
  const { createPostModalState } = React.useContext(QuickActionsContext);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getActivityRecordsThunk());
  }, [dispatch]);

  return (
    <CreatePostProvider>
      <Modal
        width={850}
        isOpen={createPostModalState.isOpen}
        onClose={createPostModalState.closeModal}
      >
        <ModalContent />
      </Modal>
    </CreatePostProvider>
  );
}
