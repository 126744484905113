import { joiResolver } from '@hookform/resolvers/joi';
import {
  Box,
  Button,
  ControlledInput,
  Form,
  IconButton,
  ResetPasswordForm,
  SplitScreenContainer,
  SplitScreenContentContainer,
  SplitScreenLeftSideAlt,
  Text,
  TrailingInputBox,
  flattenFieldErrorsObject,
} from '@orbiapp/components';
import React from 'react';
import { useForm } from 'react-hook-form';

import { SignedOutToolbar } from '../../../components';
import { ResetPasswordValidation } from '../../../models';
import { Logger } from '../../../services';
import {
  AuthStateSelector,
  authActions,
  resetPasswordThunk,
  useDispatch,
  useSelector,
} from '../../../store';

function ResetPasswordContent() {
  const dispatch = useDispatch();

  const authStateStatus = useSelector(AuthStateSelector.selectStatus);

  const isLoading = authStateStatus === 'pending';

  const resetPasswordFormMethods = useForm<ResetPasswordForm>({
    defaultValues: { email: '' },
    resolver: joiResolver(ResetPasswordValidation),
  });

  const email = resetPasswordFormMethods.watch('email');

  const clearEmail = () => {
    resetPasswordFormMethods.setValue('email', '');
    resetPasswordFormMethods.setFocus('email');
  };

  const resetPassword = resetPasswordFormMethods.handleSubmit(
    (data) => dispatch(resetPasswordThunk(data.email)),
    (err) => {
      Logger.warning('resetPassword Validation', {
        err: flattenFieldErrorsObject(err),
      });
    },
  );

  React.useEffect(() => {
    return () => {
      dispatch(authActions.clearError());
    };
  }, [dispatch]);

  return (
    <React.Fragment>
      <Box
        flex
        flexAlign="center"
        flexDirection="column"
        gap={16}
        textAlign="center"
      >
        <Text
          variant="titleMd"
          tx="label.auth.reset-password.title"
          color="signInRightSideTitle"
        />
        <Text variant="bodyMd" tx="label.auth.reset-password.subtitle" />
      </Box>

      <Form
        flex
        flexDirection="column"
        formMethods={resetPasswordFormMethods}
        width="100%"
        gap={32}
        onSubmit={resetPassword}
      >
        <ControlledInput
          disabled={isLoading}
          trailingElement={
            <TrailingInputBox>
              {email && (
                <IconButton icon="x-circle-outline" onClick={clearEmail} />
              )}
            </TrailingInputBox>
          }
          labelTx="label.auth.email"
          name="email"
        />

        <Button
          isLoading={isLoading}
          width="100%"
          tx="button.auth.reset-password"
          type="submit"
          variant="primary"
          large
        />
      </Form>
    </React.Fragment>
  );
}

export function ResetPassword() {
  return (
    <React.Fragment>
      <SignedOutToolbar />

      <SplitScreenContainer>
        <SplitScreenLeftSideAlt
          titleTx="label.auth.orbi-network.title"
          subtitleTx="label.auth.orbi-network.subtitle"
        />

        <SplitScreenContentContainer>
          <ResetPasswordContent />
        </SplitScreenContentContainer>
      </SplitScreenContainer>
    </React.Fragment>
  );
}
