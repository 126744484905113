import { Box } from '@orbiapp/components';
import styled, { css } from 'styled-components';

const CheckboxGrid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: fit-content;
  gap: 16px;
`;

interface SelectPostTypeCardProps {
  toggled: boolean;
  disabled?: boolean;
}

const SelectPostTypeCard = styled(Box)<SelectPostTypeCardProps>`
  ${(props) => {
    const { disabled, theme, toggled } = props;

    return css`
      border-radius: 16px;
      padding: 32px;
      cursor: pointer;
      background-color: ${theme.colors.selectionCardBackground};

      position: relative;

      ${disabled
        ? css`
            border: 2px solid ${theme.colors.selectionCardBorderDisabled};
            pointer-events: none;

            :before {
              content: '';
              position: absolute;
              inset: 0;
              z-index: 4;
              border-radius: inherit;
              opacity: 0.5;
              background-color: ${theme.colors.selectionCardBackgroundDisabled};
              cursor: auto;
            }
          `
        : css`
            border: 2px solid ${theme.colors.selectionCardBorder};
          `}

      ${toggled &&
      !disabled &&
      css`
        background-color: ${theme.colors.selectionCardBackgroundToggled};
        border: 2px solid ${theme.colors.selectionCardBorderToggled};
      `}
    `;
  }}
`;

export const Styled = { CheckboxGrid, SelectPostTypeCard };
