import React from 'react';
import { useController } from 'react-hook-form';

import { INPUT_X_PADDING } from '../../constants';
import {
  InputTrailingElementProvider,
  TrailingInputBox,
} from '../../context/input-trailing-element-context';
import { useCombinedRefs } from '../../helpers';
import { translate } from '../../i18n';
import { isTxString, parseTimestamp } from '../../utils';
import { Box } from '../box';
import { IconButton } from '../icon-button';
import { InputHelper } from '../input-helper';
import { InputLabel } from '../input-label';
import { Styled } from './input.styled';
import {
  ControlledDatePickerProps,
  ControlledInputProps,
  InputProps,
  datePickerTypes,
} from './input.types';

function _ControlledDatePicker(
  props: ControlledDatePickerProps,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  const {
    defaultValue,
    deps,
    disabled,
    maxLength,
    name,
    onBlur,
    onChange,
    type,
    ...rest
  } = props;

  const controller = useController({
    name,
    rules: { deps },
  });

  const combinedRefs = useCombinedRefs(ref, controller.field.ref);

  const onChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const date = new Date(e.target.value).getTime();

    if (isNaN(date)) {
      e.preventDefault();
      return;
    }

    controller.field.onChange({
      ...e,
      target: { ...e.target, value: date },
    });
  };

  const onBlurHandler: React.FocusEventHandler<HTMLInputElement> = (e) => {
    controller.field.onBlur();
    onBlur?.(e);
  };

  const errorTx = isTxString(controller.fieldState.error?.message)
    ? controller.fieldState.error?.message
    : undefined;

  const value =
    type === 'date'
      ? parseTimestamp(controller.field.value, 'YYYY-MM-DD')
      : parseTimestamp(controller.field.value, 'YYYY-MM-DDTHH:mm');

  return (
    <Input
      disabled={disabled}
      errorTx={errorTx}
      name={name}
      onChange={onChangeHandler}
      onBlur={onBlurHandler}
      ref={combinedRefs}
      type={type}
      value={value}
      {...rest}
    />
  );
}

function _ControlledInput(
  props: ControlledInputProps,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  const {
    defaultValue,
    deps,
    disabled,
    maxLength,
    name,
    onBlur,
    onChange,
    ...rest
  } = props;

  const controller = useController({
    defaultValue,
    name,
    rules: { deps, maxLength },
  });

  const combinedRefs = useCombinedRefs(ref, controller.field.ref);

  const onChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    controller.field.onChange(e);
    onChange?.(e);
  };

  const onBlurHandler: React.FocusEventHandler<HTMLInputElement> = (e) => {
    controller.field.onBlur();
    onBlur?.(e);
  };

  const errorTx = isTxString(controller.fieldState.error?.message)
    ? controller.fieldState.error?.message
    : undefined;

  return (
    <Input
      disabled={disabled}
      maxLength={maxLength}
      name={controller.field.name}
      onBlur={onBlurHandler}
      onChange={onChangeHandler}
      ref={combinedRefs}
      value={controller.field.value ?? ''}
      errorTx={errorTx}
      {...rest}
    />
  );
}

function useInputDynamicOffset(props: {
  combinedRefs: React.MutableRefObject<any>;
  labelRef: React.RefObject<HTMLLabelElement>;
  leadingBoxRef: React.RefObject<HTMLDivElement>;
  trailingBoxRef: React.RefObject<HTMLDivElement>;
}) {
  const { combinedRefs, labelRef, leadingBoxRef, trailingBoxRef } = props;

  React.useEffect(() => {
    if (!combinedRefs.current || !leadingBoxRef.current || !labelRef.current) {
      return;
    }

    const { width } = leadingBoxRef.current.getBoundingClientRect();

    combinedRefs.current.style.paddingLeft = `${width + INPUT_X_PADDING + 8}px`;
    labelRef.current.style.left = `${width + INPUT_X_PADDING + 8}px`;
  }, [combinedRefs, labelRef, leadingBoxRef]);

  React.useEffect(() => {
    if (!combinedRefs.current || !trailingBoxRef.current) {
      return;
    }

    const { width } = trailingBoxRef.current.getBoundingClientRect();

    combinedRefs.current.style.paddingRight = `${
      width + INPUT_X_PADDING + 8
    }px`;
  }, [combinedRefs, trailingBoxRef]);
}

function useInput(props: {
  defaultValue?: InputProps['defaultValue'];
  maxLength?: number;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  type?: InputProps['type'];
  value?: InputProps['value'];
}) {
  const { defaultValue, value, onFocus, onBlur, onChange, maxLength, type } =
    props;

  const handleFocus: React.FocusEventHandler<HTMLInputElement> = (e) => {
    onFocus?.(e);
  };

  const handleBlur: React.FocusEventHandler<HTMLInputElement> = (e) => {
    onBlur?.(e);
  };

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const length = e.target.value.length;

    if (maxLength === undefined) {
      onChange?.(e);
      return;
    }

    if (length > maxLength) {
      e.target.value = e.target.value.slice(0, maxLength);
      onChange?.(e);
      return;
    }

    onChange?.(e);
  };

  const hasValue = typeof value === 'string' ? !!value : value !== undefined;

  const hasDefaultValue =
    typeof defaultValue === 'string'
      ? !!defaultValue
      : defaultValue !== undefined;

  const floatLabel =
    hasValue || hasDefaultValue || datePickerTypes.includes(type as any);

  return { handleFocus, handleBlur, handleChange, floatLabel };
}

function _Input(props: InputProps, ref: React.ForwardedRef<HTMLInputElement>) {
  const {
    defaultValue,
    disabled = false,
    error,
    errorTx,
    errorTxArgs,
    helperText,
    helperTx,
    helperTxArgs,
    label,
    labelTx,
    labelTxArgs,
    leadingElement,
    maxLength,
    onBlur,
    onChange,
    onFocus,
    placeholder,
    placeholderTx,
    placeholderTxArgs,
    trailingElement,
    type,
    value,
    width = '100%',
    hideExtraTrailingElement,
    ...rest
  } = props;

  const inputRef = React.useRef<HTMLInputElement>(null);
  const labelRef = React.useRef<HTMLLabelElement>(null);
  const trailingBoxRef = React.useRef<HTMLDivElement>(null);
  const leadingBoxRef = React.useRef<HTMLDivElement>(null);

  const combinedRefs = useCombinedRefs(ref, inputRef);

  useInputDynamicOffset({
    combinedRefs,
    labelRef,
    leadingBoxRef,
    trailingBoxRef,
  });

  const { handleFocus, handleBlur, handleChange, floatLabel } = useInput({
    defaultValue,
    value,
    onFocus,
    onBlur,
    onChange,
    maxLength,
    type,
  });

  const hasError = !!error || !!errorTx;

  const openPicker = () => {
    combinedRefs.current?.showPicker();
    combinedRefs.current?.focus();
    combinedRefs.current?.click();
  };

  const isDatePicker = datePickerTypes.includes(type as any);

  const handleWheel: React.WheelEventHandler<HTMLInputElement> = (e) => {
    if (type !== 'number') return;
    e.currentTarget.blur();
  };

  return (
    <InputTrailingElementProvider
      trailingBoxRef={trailingBoxRef}
      leadingBoxRef={leadingBoxRef}
      extraTrailingElements={
        isDatePicker && !hideExtraTrailingElement ? (
          <IconButton
            disabled={disabled}
            icon="calendar-days-outline"
            onClick={openPicker}
          />
        ) : undefined
      }
    >
      <Styled.OuterInputBox floatLabel={floatLabel} relative width={width}>
        <Box relative>
          {leadingElement}

          <Styled.InputBox floatLabel={floatLabel} relative>
            <Styled.Input
              defaultValue={defaultValue}
              disabled={disabled}
              hasError={hasError}
              onBlur={handleBlur}
              onChange={handleChange}
              onFocus={handleFocus}
              ref={combinedRefs}
              type={type}
              value={value}
              onWheel={handleWheel}
              placeholder={
                placeholderTx
                  ? translate(placeholderTx, placeholderTxArgs ?? {}).toString()
                  : placeholder
              }
              {...rest}
            />

            <InputLabel
              disabled={disabled}
              hasError={hasError}
              left={INPUT_X_PADDING}
              ref={labelRef}
              text={label}
              tx={labelTx}
              txArgs={labelTxArgs}
            />
          </Styled.InputBox>

          {trailingElement ? (
            trailingElement
          ) : isDatePicker ? (
            <TrailingInputBox />
          ) : null}
        </Box>

        <InputHelper
          currentLength={value?.toString().length}
          disabled={disabled}
          hasError={hasError}
          helperText={error || helperText}
          helperTx={errorTx || helperTx}
          helperTxArgs={errorTxArgs || helperTxArgs}
          maxLength={maxLength}
        />
      </Styled.OuterInputBox>
    </InputTrailingElementProvider>
  );
}

export const ControlledInput = React.forwardRef(_ControlledInput);
export const ControlledDatePicker = React.forwardRef(_ControlledDatePicker);
export const Input = React.forwardRef(_Input);
