import { Banner } from '@orbiapp/components';
import React from 'react';

import { Analytics } from '../../services';
import {
  FeatureFlagsSelector,
  LocalActivityDraftsSelector,
  getLocalActivityDraftsThunk,
  useDispatch,
  useSelector,
} from '../../store';

function ShowConvertDraftsBanner() {
  const [isClosed, setIsClosed] = React.useState(false);

  React.useEffect(() => {
    Analytics.track('convert_draft_banner');
  }, []);

  if (isClosed) {
    return null;
  }

  const closeBanner = () => {
    setIsClosed(true);
  };

  return (
    <Banner
      variant="warning"
      onClose={closeBanner}
      linkTo="/activities/convert-drafts"
      linkTx="label.convert-drafts-banner.link"
      messageTx="label.convert-drafts-banner.message"
      titleTx="label.convert-drafts-banner.title"
    />
  );
}

export function ConvertDraftsBanner() {
  const dispatch = useDispatch();

  const useBackendStorage = useSelector(
    FeatureFlagsSelector.selectUseBackendStorage,
  );

  const localActivityDrafts = useSelector(
    LocalActivityDraftsSelector.selectData,
  );

  React.useEffect(() => {
    if (useBackendStorage) {
      dispatch(getLocalActivityDraftsThunk());
    }
  }, [dispatch, useBackendStorage]);

  if (localActivityDrafts?.length) {
    return <ShowConvertDraftsBanner />;
  }

  return null;
}
