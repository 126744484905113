import {
  Avatar,
  Box,
  Button,
  FormHeader,
  Icon,
  IconButton,
  SolidIconButton,
  Status,
  Text,
  Tooltip,
  flattenFieldErrorsObject,
  getAvatarVariantFromString,
  parseTimestamp,
} from '@orbiapp/components';
import { FieldErrors, useFormContext } from 'react-hook-form';

import { PublishButton } from '../../../../../components';
import { usePauseOffer } from '../../../../../helpers';
import { UpdateOfferForm } from '../../../../../models';
import { Logger } from '../../../../../services';
import {
  OfferSelector,
  UpdateOfferSelector,
  globalUiStateActions,
  setAlert,
  updateOfferThunk,
  useDispatch,
  useSelector,
} from '../../../../../store';

function OfferFormHeaderUpdateStatus() {
  const endDate = useSelector(OfferSelector.selectEndDate);
  const startDate = useSelector(OfferSelector.selectStartDate);
  const disabledAt = useSelector(OfferSelector.selectDisabledAt);

  if (!startDate) {
    return null;
  }

  const now = Date.now();

  if (endDate && now > endDate) {
    return <Status variant="error" tx="label.offers-status.closed" />;
  }

  if (startDate > now) {
    return (
      <Status
        variant="info"
        tx="label.offers-status.scheduled-at"
        txArgs={{
          date: parseTimestamp(startDate, 'DD MMM YYYY HH:mm'),
        }}
      />
    );
  }

  if (disabledAt) {
    return <Status variant="error" tx="label.offers-status.paused" />;
  }

  return <Status variant="success" tx="label.offers-status.live" />;
}

export function OfferFormHeader() {
  const editMeta = useSelector(OfferSelector.selectEditMeta);
  const endDate = useSelector(OfferSelector.selectEndDate);
  const startDate = useSelector(OfferSelector.selectStartDate);
  const disabledAt = useSelector(OfferSelector.selectDisabledAt);
  const offerKey = useSelector(OfferSelector.selectOfferKey);
  const hasBeenPublished = useSelector(OfferSelector.selectHasBeenPublished);
  const updateOfferStatus = useSelector(UpdateOfferSelector.selectStatus);

  const dispatch = useDispatch();

  const pauseOffer = usePauseOffer({
    endDate,
    offerKey,
    startDate,
  });

  const formContext = useFormContext<UpdateOfferForm>();

  const updateOffer = async (updateOfferForm: UpdateOfferForm) => {
    dispatch(globalUiStateActions.clearAlert());

    const res = await dispatch(updateOfferThunk(updateOfferForm));
    if (res.meta.requestStatus === 'fulfilled') {
      formContext.reset(updateOfferForm);
    }
  };

  const handleError = (err: FieldErrors<UpdateOfferForm>) => {
    Logger.warning('updateOffer Validation', {
      err: flattenFieldErrorsObject(err),
    });

    dispatch(setAlert('error-in-form'));
  };

  const submitOffer = formContext.handleSubmit(updateOffer, handleError);

  const isLoading = updateOfferStatus === 'pending';

  return (
    <FormHeader flex flexDirection="column" gap={12} width="100%">
      <Box
        flex
        flexAlign="center"
        flexJustify="between"
        gap={24}
        width="100%"
        flexWrap
      >
        <Box flexGrow={1} gap={8} flex flexDirection="column">
          <Box gap={8} flexAlign="center" flex>
            <Tooltip placement="bottom" titleTx="label.tooltip.go-back">
              <IconButton to="/offers" icon="chevron-left" />
            </Tooltip>

            <Text
              color="pageTitle"
              variant="titleMd"
              tx="title.offers.edit-offer"
              as="h1"
            />
          </Box>
        </Box>

        <Box flexAlign="center" flexWrap flex gap={16}>
          {!pauseOffer.hidden && (
            <Tooltip
              placement="bottom"
              titleTx={
                disabledAt ? 'label.tooltip.resume' : 'label.tooltip.pause'
              }
            >
              <SolidIconButton
                isLoading={pauseOffer.isLoading}
                onClick={pauseOffer.toggleIsDisabled}
                icon={disabledAt ? 'play-outline' : 'pause-outline'}
              />
            </Tooltip>
          )}

          <Tooltip placement="bottom" titleTx="label.tooltip.duplicate">
            <SolidIconButton
              icon="document-duplicate-outline"
              to={`/offers/duplicate-offer/${offerKey}`}
            />
          </Tooltip>

          {hasBeenPublished ? (
            <Button
              isLoading={isLoading}
              tx="button.save"
              variant="primary"
              onClick={submitOffer}
            />
          ) : (
            <PublishButton
              onError={handleError}
              isLoading={isLoading}
              onSubmit={updateOffer}
            />
          )}
        </Box>
      </Box>

      <Box gap={16} flexWrap flex flexJustify="between">
        {editMeta && (
          <Box flexWrap flex gap={16}>
            <Box flexWrap flexAlign="center" gap={16} flex>
              <Box flexWrap flex flexAlign="center" mr={48} gap={16}>
                <Icon
                  color="formHeaderEditedByMetaIcon"
                  name="user-circle-outline"
                />
                <Text
                  color="formHeaderEditedByMetaTitle"
                  tx="label.offer-form.updated-by"
                  variant="bodySm"
                  whiteSpace="nowrap"
                />

                <Box flexAlign="center" gap={8} flex>
                  <Avatar
                    variant={
                      editMeta.updatedBy?.userKey
                        ? getAvatarVariantFromString(
                            editMeta.updatedBy?.userKey,
                          )
                        : undefined
                    }
                    width={32}
                    height={32}
                    fallbackLetter={editMeta.updatedBy?.firstName[0]}
                    src={editMeta.updatedBy?.profilePicture?.thumbnail64.url}
                  />

                  <Text
                    color="formHeaderEditedByMeta"
                    text={`${editMeta.updatedBy?.firstName} ${editMeta.updatedBy?.lastName}`}
                    textTransform="capitalize"
                    variant="bodySm"
                    whiteSpace="nowrap"
                  />
                </Box>
              </Box>
            </Box>

            {editMeta.updatedAt && (
              <Box flex flexAlign="center" gap={16}>
                <Icon name="clock-outline" color="formHeaderEditedByMetaIcon" />
                <Text
                  variant="bodySm"
                  color="formHeaderEditedByMetaTitle"
                  tx="label.general.updated-at"
                />
                <Text
                  variant="bodySm"
                  text={parseTimestamp(
                    editMeta.updatedAt,
                    'DD MMM YYYY HH:mm:ss',
                  )}
                  whiteSpace="nowrap"
                />
              </Box>
            )}
          </Box>
        )}

        <OfferFormHeaderUpdateStatus />
      </Box>
    </FormHeader>
  );
}
