import Joi from 'joi';

import { CreateActivityForm, UpdateActivityForm } from './activity.model';
import { ActivitySchema, LinkSchema } from './activity.schema';
import { CoHostingSchema } from './co-hosting';
import { MinTicketCounts } from './ticket-types';

export const AddLinkValidation = Joi.object({
  url: LinkSchema.url,
  label: LinkSchema.label,
});

const UpdateTicketsFormValidation = (options: {
  minTicketCounts: MinTicketCounts;
}) =>
  Joi.object({
    showAttendance: ActivitySchema.showAttendance,
    ticketTypes: ActivitySchema.updateTicketType(options),
  });

const DescriptionFormValidation = (options: { enableTags: boolean }) =>
  Joi.object({
    categories: ActivitySchema.categories,
    tags: ActivitySchema.tags,
    title: ActivitySchema.title,
    description: ActivitySchema.description(options),
    location: ActivitySchema.location,
    startDate: ActivitySchema.startDate,
    endDate: ActivitySchema.endDate,
    contactDetails: ActivitySchema.contactDetails,
    links: ActivitySchema.links,
  });

const MediaFormValidation = Joi.object({
  coverImage: ActivitySchema.coverImage,
});

const ParticipantsFormValidation = Joi.object({
  segmentation: ActivitySchema.segmentation,
});

const CreateTicketsFormValidation = Joi.object({
  showAttendance: ActivitySchema.showAttendance,
  ticketTypes: ActivitySchema.ticketTypes,
});

const RequestMoreInfoFormValidation = Joi.object({
  requestedInfo: ActivitySchema.requestedInfo,
});

const CoHostingFormValidation = Joi.object({
  cohosts: CoHostingSchema.coHosts,
});

const AddonsFormValidation = Joi.object({
  questionsActivated: ActivitySchema.questionsActivated,
});

export const CreateActivityValidation = (options: { enableTags: boolean }) =>
  Joi.object<CreateActivityForm>({
    description: DescriptionFormValidation(options).required(),
    media: MediaFormValidation.required(),
    participants: ParticipantsFormValidation.required(),
    tickets: CreateTicketsFormValidation.required(),
    requestMoreInfo: RequestMoreInfoFormValidation.required(),
    coHosting: CoHostingFormValidation.required(),
    addons: AddonsFormValidation.required(),
  });

export const UpdateActivityValidation = (options: {
  enableTags: boolean;
  minTicketCounts: MinTicketCounts;
}) =>
  Joi.object<UpdateActivityForm>({
    activityKey: ActivitySchema.activityKey,
    description: DescriptionFormValidation(options).required(),
    media: MediaFormValidation.required(),
    participants: ParticipantsFormValidation.required(),
    tickets: UpdateTicketsFormValidation(options).required(),
    requestMoreInfo: RequestMoreInfoFormValidation.required(),
    coHosting: CoHostingFormValidation.required(),
    addons: AddonsFormValidation.required(),
  });
