import {
  Box,
  Button,
  Chip,
  Confirm,
  ContentContainer,
  ContentSidebar,
  ContentSidebarContentContainer,
  ContentSidebarFooterContainer,
  EmptyState,
  IconButton,
  InnerContentContainer,
  InnerPageContainer,
  Modal,
  ResponsiveBox,
  SolidIconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Text,
  Tooltip,
  getUID,
  parseTimestamp,
  useConfirm,
  useModalState,
} from '@orbiapp/components';
import React from 'react';
import { Navigate } from 'react-router-dom';

import {
  MembershipTypeQuestion,
  MembershipTypeQuestionForm,
  RequestedInfoType,
} from '../../../../../models';
import {
  CreateCheckboxSelector,
  CreateFreetextSelector,
  CreateMultichoiceSelector,
  DeleteCheckboxQuestionSelector,
  DeleteFreetextQuestionSelector,
  DeleteMultichoiceQuestionSelector,
  MembershipTypeSelector,
  UpdateCheckboxSelector,
  UpdateFreetextSelector,
  UpdateMultichoiceSelector,
  createCheckboxThunk,
  createFreetextThunk,
  createMultichoiceQuestionThunk,
  deleteCheckboxQuestionThunk,
  deleteFreetextQuestionThunk,
  deleteMultichoiceQuestionThunk,
  updateCheckboxThunk,
  updateFreetextThunk,
  updateMultichoiceThunk,
  useDispatch,
  useSelector,
} from '../../../../../store';
import { getRequestedInfoTypeTx, isAnyPending } from '../../../../../utils';
import { CheckboxForm, EditCheckboxForm } from '../../components/checkbox-form';
import { EditFreeTextForm, FreeTextForm } from '../../components/freetext-form';
import {
  EditMultiChoiceForm,
  MultiChoiceForm,
} from '../../components/multichoice-form';
import {
  QuestionsSidebarContextType,
  QuestionsSidebarState,
} from './questions.types';

const QuestionsSidebarContext =
  React.createContext<QuestionsSidebarContextType>({
    state: null,
    setState: () => {},
  });

function QuestionsSidebarProvider(props: React.PropsWithChildren) {
  const { children } = props;

  const [state, setState] = React.useState<QuestionsSidebarState>(null);

  return (
    <QuestionsSidebarContext.Provider value={{ state, setState }}>
      {children}
    </QuestionsSidebarContext.Provider>
  );
}

function QuestionEmptyState() {
  const { state, setState } = React.useContext(QuestionsSidebarContext);

  const openSidebar = () => {
    setState({
      mode: 'create',
      data: {
        isRequired: false,
        key: getUID(),
        question: '',
        type: RequestedInfoType.FreeText,
      },
    });
  };

  return (
    <EmptyState
      buttonOnClick={openSidebar}
      buttonTx="label.memberships.questions.empty-state.button"
      disabled={state?.mode === 'create'}
      titleTx="label.memberships.questions.empty-state.label"
    />
  );
}

function AddNewQuestionButton() {
  const { state, setState } = React.useContext(QuestionsSidebarContext);

  const addNewQuestion = () => {
    setState({
      mode: 'create',
      data: {
        isRequired: false,
        key: getUID(),
        question: '',
        type: RequestedInfoType.FreeText,
      },
    });
  };

  return (
    <ResponsiveBox
      xs={
        <Button
          disabled={state?.mode === 'create'}
          onClick={addNewQuestion}
          tx="label.memberships.questions.empty-state.button"
          variant="secondary"
        />
      }
    >
      <Tooltip
        placement="left"
        titleTx="label.memberships.questions.empty-state.button"
      >
        <SolidIconButton
          disabled={state?.mode === 'create'}
          icon="plus-circle-outline"
          onClick={addNewQuestion}
        />
      </Tooltip>
    </ResponsiveBox>
  );
}

function QuestionsTable() {
  const { confirmValue, closeConfirm, isOpen, openConfirm } =
    useConfirm<MembershipTypeQuestion>();
  const membershipQuestions = useSelector(
    MembershipTypeSelector.selectMembershipQuestions,
  );
  const deleteCheckboxStatus = useSelector(
    DeleteCheckboxQuestionSelector.selectStatus,
  );
  const deleteMultichoiceStatus = useSelector(
    DeleteMultichoiceQuestionSelector.selectStatus,
  );
  const deleteFreetextStatus = useSelector(
    DeleteFreetextQuestionSelector.selectStatus,
  );

  const { state, setState } = React.useContext(QuestionsSidebarContext);

  const dispatch = useDispatch();

  if (!membershipQuestions) {
    return null;
  }

  const removeQuestion = () => {
    if (!confirmValue) return;

    switch (confirmValue.type) {
      case RequestedInfoType.Checkbox:
        dispatch(deleteCheckboxQuestionThunk(confirmValue.key));
        break;

      case RequestedInfoType.FreeText:
        dispatch(deleteFreetextQuestionThunk(confirmValue.key));
        break;

      case RequestedInfoType.MultiChoice:
        dispatch(deleteMultichoiceQuestionThunk(confirmValue.key));
        break;
    }

    if (state?.data?.key === confirmValue.key) {
      setState(null);
    }

    closeConfirm();
  };

  const renderQuestionTableRowRow = (question: MembershipTypeQuestion) => {
    const stageQuestionForRemove: React.MouseEventHandler<HTMLButtonElement> = (
      e,
    ) => {
      e.stopPropagation();

      openConfirm(question);
    };

    const selectQuestion = () => {
      setState({
        mode: 'view',
        data: question,
      });
    };

    return (
      <TableRow
        highlight={question.key === state?.data?.key}
        key={question.key}
        onClick={selectQuestion}
      >
        <TableCell text={question.question} />
        <TableCell>
          <Chip variant="primary" tx={getRequestedInfoTypeTx(question.type)} />
        </TableCell>
        <TableCell>
          <Chip
            variant="secondary"
            tx={
              question.isRequired
                ? 'label.memberships.view-membership.questions.is-required'
                : 'label.memberships.view-membership.questions.is-optional'
            }
          />
        </TableCell>
        <TableCell width={40} hoverCell fixedRight>
          <IconButton icon="trash-outline" onClick={stageQuestionForRemove} />
        </TableCell>
      </TableRow>
    );
  };

  return (
    <React.Fragment>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead tx="label.memberships.create-membership.questions.table.question" />
            <TableHead tx="label.memberships.create-membership.questions.table.type" />
            <TableHead tx="label.memberships.create-membership.questions.table.required" />
            <TableHead fixedRight />
          </TableRow>
        </TableHeader>
        <TableBody>
          {membershipQuestions.map(renderQuestionTableRowRow)}
        </TableBody>
      </Table>

      <Confirm
        isLoading={isAnyPending(
          deleteCheckboxStatus,
          deleteMultichoiceStatus,
          deleteFreetextStatus,
        )}
        cancelTx="prompt.delete-question.cancel"
        confirmTx="prompt.delete-question.confirm"
        isOpen={isOpen}
        messageTx="prompt.delete-question.message"
        onCancel={closeConfirm}
        onConfirm={removeQuestion}
        titleTx="prompt.delete-question.title"
      />
    </React.Fragment>
  );
}

function QuestionsContent() {
  const membershipQuestions = useSelector(
    MembershipTypeSelector.selectMembershipQuestions,
  );

  if (!membershipQuestions || membershipQuestions.length === 0) {
    return <QuestionEmptyState />;
  }

  return (
    <React.Fragment>
      <Box flex flexJustify="between" flexWrap="wrap" gap={16}>
        <Text
          color="pageTitle"
          as="h1"
          tx="label.memberships.view-membership.tabs.questions"
          variant="titleMd"
        />

        <AddNewQuestionButton />
      </Box>

      <QuestionsTable />
    </React.Fragment>
  );
}

function CreateQuestionContent() {
  const { state, setState } = React.useContext(QuestionsSidebarContext);

  const dispatch = useDispatch();

  const createCheckboxStatus = useSelector(CreateCheckboxSelector.selectStatus);
  const createFreetextStatus = useSelector(CreateFreetextSelector.selectStatus);
  const createMultichoiceStatus = useSelector(
    CreateMultichoiceSelector.selectStatus,
  );

  if (!state?.data || state.mode !== 'create') {
    return null;
  }

  const closeSidebar = () => setState(null);

  const setDefaultValues = (data: MembershipTypeQuestionForm) => {
    setState({ mode: 'create', data });
  };

  const createQuestion = (question: MembershipTypeQuestionForm) => {
    (() => {
      switch (question.type) {
        case RequestedInfoType.Checkbox:
          return dispatch(createCheckboxThunk(question));
        case RequestedInfoType.MultiChoice:
          return dispatch(createMultichoiceQuestionThunk(question));
        case RequestedInfoType.FreeText:
          return dispatch(createFreetextThunk(question));
      }
    })().then((res) => {
      if (res.meta.requestStatus === 'fulfilled') {
        closeSidebar();
      }
    });
  };

  switch (state?.data.type) {
    case RequestedInfoType.Checkbox:
      return (
        <CheckboxForm
          defaultValues={{
            isRequired: state.data.isRequired,
            key: state.data.key,
            question: state.data.question,
            type: state.data.type,
          }}
          isLoading={createCheckboxStatus === 'pending'}
          onSubmitted={createQuestion}
          setDefaultValues={setDefaultValues}
          titleTx="label.memberships.create-membership.questions.new-question"
        />
      );

    case RequestedInfoType.MultiChoice:
      return (
        <MultiChoiceForm
          defaultValues={{
            isRequired: state.data.isRequired,
            key: state.data.key,
            question: state.data.question,
            type: state.data.type,
            options: state.data.options,
          }}
          isLoading={createMultichoiceStatus === 'pending'}
          onSubmitted={createQuestion}
          setDefaultValues={setDefaultValues}
          titleTx="label.memberships.create-membership.questions.new-question"
        />
      );

    case RequestedInfoType.FreeText:
      return (
        <FreeTextForm
          defaultValues={{
            isRequired: state.data.isRequired,
            key: state.data.key,
            question: state.data.question,
            type: state.data.type,
          }}
          isLoading={createFreetextStatus === 'pending'}
          onSubmitted={createQuestion}
          setDefaultValues={setDefaultValues}
          titleTx="label.memberships.create-membership.questions.new-question"
        />
      );

    default:
      return null;
  }
}

function CreateQuestionSidebar() {
  const { state, setState } = React.useContext(QuestionsSidebarContext);

  const closeSidebar = () => setState(null);

  return (
    <ContentSidebar
      isOpen={state?.mode === 'create'}
      onClose={closeSidebar}
      width={470}
    >
      <CreateQuestionContent />
    </ContentSidebar>
  );
}

function EditQuestionContent(props: { closeModal: () => void }) {
  const { closeModal } = props;

  const { state, setState } = React.useContext(QuestionsSidebarContext);

  const dispatch = useDispatch();

  const updateCheckboxStatus = useSelector(UpdateCheckboxSelector.selectStatus);
  const updateFreetextStatus = useSelector(UpdateFreetextSelector.selectStatus);
  const updateMultichoiceStatus = useSelector(
    UpdateMultichoiceSelector.selectStatus,
  );

  if (!state) {
    return null;
  }

  const updateQuestion = (question: MembershipTypeQuestionForm) => {
    (() => {
      switch (question.type) {
        case RequestedInfoType.Checkbox:
          return dispatch(updateCheckboxThunk(question));
        case RequestedInfoType.MultiChoice:
          return dispatch(updateMultichoiceThunk(question));
        case RequestedInfoType.FreeText:
          return dispatch(updateFreetextThunk(question));
      }
    })().then((res) => {
      if (res.meta.requestStatus === 'fulfilled') {
        setState({
          mode: 'view',
          data: { ...(state.data as MembershipTypeQuestion), ...question },
        });

        closeModal();
      }
    });
  };

  switch (state.data.type) {
    case RequestedInfoType.Checkbox:
      return (
        <EditCheckboxForm
          defaultValues={{
            isRequired: state.data.isRequired,
            key: state.data.key,
            question: state.data.question,
            type: state.data.type,
          }}
          isLoading={updateCheckboxStatus === 'pending'}
          onSubmitted={updateQuestion}
          closeModal={closeModal}
        />
      );

    case RequestedInfoType.MultiChoice:
      return (
        <EditMultiChoiceForm
          defaultValues={{
            isRequired: state.data.isRequired,
            key: state.data.key,
            question: state.data.question,
            type: state.data.type,
            options: state.data.options,
          }}
          isLoading={updateMultichoiceStatus === 'pending'}
          onSubmitted={updateQuestion}
          closeModal={closeModal}
        />
      );

    case RequestedInfoType.FreeText:
      return (
        <EditFreeTextForm
          defaultValues={{
            isRequired: state.data.isRequired,
            key: state.data.key,
            question: state.data.question,
            type: state.data.type,
          }}
          isLoading={updateFreetextStatus === 'pending'}
          onSubmitted={updateQuestion}
          closeModal={closeModal}
        />
      );

    default:
      return null;
  }
}

function ViewQuestionSidebarContent() {
  const { state } = React.useContext(QuestionsSidebarContext);

  if (!state?.data || !('createdAt' in state.data)) {
    return null;
  }

  return (
    <React.Fragment>
      <Text
        variant="bodyLgBold"
        tx="label.memberships.view-membership.questions.question"
      />

      <Box flex flexDirection="column" gap={16}>
        <Text variant="bodySm" text={state.data.question} />

        <Text
          variant="bodySm"
          tx="label.memberships.view-membership.questions.created-at"
          txArgs={{
            createdAt: parseTimestamp(state.data.createdAt, 'DD MMM YYYY'),
          }}
        />
      </Box>

      <Box flex flexWrap="wrap" gap={8}>
        <Chip variant="primary" tx={getRequestedInfoTypeTx(state.data.type)} />
        <Chip
          variant="secondary"
          tx={
            state.data.isRequired
              ? 'label.memberships.view-membership.questions.is-required'
              : 'label.memberships.view-membership.questions.is-optional'
          }
        />
      </Box>
    </React.Fragment>
  );
}

function ViewQuestionSidebar() {
  const { state, setState } = React.useContext(QuestionsSidebarContext);
  const { closeModal, isOpen, openModal } = useModalState();

  const closeSidebar = () => setState(null);

  return (
    <React.Fragment>
      <ContentSidebar
        isOpen={state?.mode === 'view'}
        onClose={closeSidebar}
        width={470}
      >
        <ContentSidebarContentContainer>
          <ViewQuestionSidebarContent />
        </ContentSidebarContentContainer>

        <ContentSidebarFooterContainer flexJustify="end">
          <Button
            onClick={openModal}
            tx="label.memberships.view-membership.questions.edit-question"
            variant="secondary"
          />
        </ContentSidebarFooterContainer>
      </ContentSidebar>

      <Modal width={500} isOpen={isOpen} onClose={closeModal}>
        <EditQuestionContent closeModal={closeModal} />
      </Modal>
    </React.Fragment>
  );
}

export function ViewMembershipQuestions() {
  const isIntegrated = useSelector(MembershipTypeSelector.selectIsIntegrated);

  if (isIntegrated) {
    return <Navigate to="overview" />;
  }

  return (
    <QuestionsSidebarProvider>
      <InnerPageContainer>
        <ContentContainer>
          <InnerContentContainer>
            <QuestionsContent />
          </InnerContentContainer>
        </ContentContainer>

        <CreateQuestionSidebar />
        <ViewQuestionSidebar />
      </InnerPageContainer>
    </QuestionsSidebarProvider>
  );
}
