import { joiResolver } from '@hookform/resolvers/joi';
import { Time, isBoolean } from '@orbiapp/components';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import {
  ActivityForm,
  CreateActivityValidation,
  UpdateActivityValidation,
  segmentationValues,
} from '../../../../models';
import {
  ActivityDataSelector,
  DepartmentSelector,
  FeatureFlagsSelector,
  useSelector,
} from '../../../../store';
import {
  ActivityFormContextType,
  CreateActivityProviderProps,
} from './create-activity.types';

function getActivityFormDefaultValues(
  defaultValues?: ActivityForm,
  options?: { enableTags: boolean; enableQuestions: boolean },
): ActivityForm {
  let questionsActivated = false;
  if (isBoolean(options?.enableQuestions)) {
    questionsActivated = defaultValues?.addons?.questionsActivated ?? false;
  }

  return {
    addons: {
      questionsActivated,
    },
    coHosting: {
      cohosts: null,
    },
    description: {
      categories: [],
      contactDetails: {
        email: '',
        name: '',
        phone: '',
      },
      description: '',
      endDate: new Date().setMinutes(0, 0, 0) + Time.Day + 2 * Time.Hour,
      links: null,
      location: {
        coordinates: null,
        description: '',
        label: '',
        link: '',
        city: null,
      },
      startDate: new Date().setMinutes(0, 0, 0) + Time.Day + Time.Hour,
      tags: options?.enableTags ? [] : null,
      title: '',
    },
    media: {
      coverImage: null,
    },
    participants: {
      segmentation: segmentationValues,
    },
    requestMoreInfo: {
      requestedInfo: null,
    },
    tickets: {
      ticketTypes: null,
      showAttendance: false,
    },
    ...defaultValues,
  };
}

export const ActivityFormContext = React.createContext<ActivityFormContextType>(
  {} as any,
);

export function ActivityFormProvider(
  props: React.PropsWithChildren<CreateActivityProviderProps>,
) {
  const { children, defaultValues, mode } = props;

  const enableTags = useSelector(DepartmentSelector.selectEnableTags);
  const enableQuestions = useSelector(FeatureFlagsSelector.selectShowQuestions);
  const minTicketCounts = useSelector(
    ActivityDataSelector.selectMinTicketCounts,
  );

  const [showErrorSnackbar, setShowErrorSnackbar] = React.useState(false);

  const formMethods = useForm<ActivityForm>({
    defaultValues: getActivityFormDefaultValues(defaultValues, {
      enableTags,
      enableQuestions,
    }),
    resolver: joiResolver(
      mode === 'update'
        ? UpdateActivityValidation({
            enableTags,
            minTicketCounts,
          })
        : CreateActivityValidation({
            enableTags,
          }),
    ),
  });

  return (
    <ActivityFormContext.Provider
      value={{ mode, showErrorSnackbar, setShowErrorSnackbar, ...formMethods }}
    >
      <FormProvider {...formMethods}>{children}</FormProvider>
    </ActivityFormContext.Provider>
  );
}
