export async function printQrCode(
  logoURL: string,
  footerLogoURL: string,
  headerLogoURL: string | undefined,
  id: string,
) {
  const qrCode = document.getElementById(id);
  if (!qrCode) return;

  qrCode.style.width = '540px';
  qrCode.style.height = '540px';

  const printable = `
    <body style="padding: 0; margin: 0">
      <div
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          flex-direction: column;
          border-width: 12px;
          border-style: solid;
          border-image: linear-gradient(
              180deg,
              #f36c0d 0%,
              #caa3e3 53.65%,
              #43c0dc 90.62%
            )
            1;
          box-sizing: border-box;
          margin: 0;
          padding: 0;
          gap: 32px;
          position: relative;
        "
      >
        <img src="${headerLogoURL}" />
      
        <div style="width: 540px; height: 540px; position: relative">
          <div
            style="
              background-color: #ffffff;
              width: 132px;
              height: 132px;
              top: 50%;
              left: 50%;
              position: absolute;
              transform: translate(-50%, -50%);
              z-index: 12;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            <img
              style="border-radius: 50%"
              width="120"
              height="120"
              src="${logoURL}"
            />
          </div>

          ${qrCode.outerHTML}
        </div>

        <img src="${footerLogoURL}" />
      </div>
    </body>
    `;

  const win = window.open();
  win?.document.write(printable);

  window.setTimeout(() => {
    win?.focus();
    win?.print();
    win?.close();
  }, 100);
}
