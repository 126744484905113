import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store.types';

export namespace FeatureFlagsSelector {
  const selectSelf = (state: RootState) => state.featureFlags.featureFlags;

  export const selectStatus = createSelector(
    selectSelf,
    (featureFlags) => featureFlags.status,
  );

  export const selectFeatureFlags = createSelector(
    selectSelf,
    (featureFlags) => featureFlags.data,
  );

  export const selectShowQuestions = createSelector(
    selectFeatureFlags,
    (featureFlags) => featureFlags?.STUDENT_DASHBOARD_SHOW_QUESTIONS ?? false,
  );

  export const selectUseBackendStorage = createSelector(
    selectFeatureFlags,
    (featureFlags) => featureFlags?.ACTIVITY_DRAFTS_ON_BACKEND ?? false,
  );
}
