import { GeneralApiResultWithData, Pagination } from '@orbiapp/components';

import { Post, PostOrderByKey } from '../../../../../models';
import { Logger } from '../../../../logger';
import { apisauce } from '../../../api';
import { getApiLogExtras, getGeneralApiProblem } from '../../../api.utils';
import { PostDto } from './posts.dto';
import { postMapper } from './posts.mappers';

export const getPosts = async (params: {
  pagination: Pagination<PostOrderByKey>;
}): Promise<GeneralApiResultWithData<Post[]>> => {
  const { pagination } = params;

  const res = await apisauce.get<PostDto[], any>('/v2/posts', pagination);

  if (!res.ok) {
    Logger.warning('getPosts', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data: Post[] = res.data.map(postMapper);

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('getPosts', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};
