import { Box, Icon } from '@orbiapp/components';
import styled, { css } from 'styled-components';

const SelectionCard = styled(Box)<{ isSelected: boolean }>`
  ${(props) => {
    const { isSelected, theme } = props;

    return css`
      border-width: 2px;
      border-style: solid;
      cursor: pointer;

      border-color: ${isSelected
        ? theme.colors.selectionCardBorderSelected
        : theme.colors.selectionCardBorder};

      :hover {
        border-color: ${isSelected
          ? theme.colors.selectionCardBorderSelectedHover
          : theme.colors.selectionCardBorderHover};
      }

      @media (max-width: 980px) {
        width: 100%;
      }
    `;
  }}
`;

const SelectionCardIcon = styled(Icon)`
  ${(props) => {
    const { theme } = props;

    return css`
      color: ${theme.colors.selectionCardIcon};
    `;
  }}
`;

const TooltipIcon = styled(Icon)`
  ${(props) => {
    const { theme } = props;

    return css`
      color: ${theme.colors.selectionCardTooltipIcon};
    `;
  }}
`;

export const Styled = { SelectionCard, SelectionCardIcon, TooltipIcon };
