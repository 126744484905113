import ReactCalendar from 'react-calendar';
import styled, { css } from 'styled-components';

import { getTextStyles } from '../text';

const Calendar = styled(ReactCalendar)`
  ${(props) => {
    const { theme } = props;

    return css`
      border: none;
      ${getTextStyles('bodySm', theme)}

      .react-calendar__tile--now {
        background: ${theme.colors.calendarNowBackground} !important;

        :hover {
          background: ${theme.colors.calendarNowBackgroundHover} !important;
        }
      }

      .react-calendar__tile--active {
        background: ${theme.colors.calendarActiveBackground} !important;

        :hover {
          background: ${theme.colors.calendarActiveBackgroundHover} !important;
        }
      }

      .react-calendar__tile--hasActive {
        background: ${theme.colors.calendarHasActiveBackground} !important;

        :hover {
          background: ${theme.colors
            .calendarHasActiveBackgroundHover} !important;
        }
      }
    `;
  }}
`;

export const Styled = { Calendar };
