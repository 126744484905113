import React from 'react';
import QRCode from 'react-qr-code';

import { FullscreenProvider, useFullscreen } from '../../helpers';
import { printQrCode } from '../../utils/qr-code';
import { Box } from '../box';
import { SolidIconButton } from '../icon-button';
import { Image } from '../image';
import { Link } from '../link';
import { Tooltip } from '../tooltip';
import { Styled } from './qr-code.styled';

interface QrCodeProps {
  size: number;
  logoSize: number;
}

interface PrintQrCodeProps {
  expandTx: TxString;
  printTx: TxString;
}

interface ExpandedQrCodeProps {
  value: string;

  logoURL: string;
  footerLogoURL: string;
  headerLogoURL?: string;

  closeTx: TxString;
}

interface ExpandedQrCodeContextType extends ExpandedQrCodeProps {
  id: string;
}

const ExpandedQrCodeContext = React.createContext<ExpandedQrCodeContextType>(
  {} as ExpandedQrCodeContextType,
);

function QrCode(props: QrCodeProps) {
  const { size, logoSize } = props;

  const { logoURL, value, id } = React.useContext(ExpandedQrCodeContext);

  const { isFullscreen } = useFullscreen();

  return (
    <Styled.QrCodeBox
      flex
      flexAlign="center"
      flexJustify="center"
      isFullscreen={isFullscreen}
      relative
      size={size}
      id="qr-code-box"
    >
      <QRCode
        level="M"
        value={value}
        id={id}
        style={{ width: '100%', height: '100%' }}
      />

      <Styled.LogoBox
        absolute
        isFullscreen={isFullscreen}
        size={logoSize}
        backgroundColor="qrCodeBackground"
        p={8}
        flex
        flexJustify="center"
        flexAlign="center"
      >
        <Image r="50%" src={logoURL} />
      </Styled.LogoBox>
    </Styled.QrCodeBox>
  );
}

export function PrintQrCode(props: PrintQrCodeProps) {
  const { expandTx, printTx } = props;

  const { logoURL, footerLogoURL, headerLogoURL, id } = React.useContext(
    ExpandedQrCodeContext,
  );

  const { enterFullscreen } = useFullscreen();

  const handlePrintQrCode = async () => {
    printQrCode(logoURL, footerLogoURL, headerLogoURL, id);
  };

  return (
    <Box gap={16} flexAlign="center" flex>
      <QrCode size={170} logoSize={48} />

      <Box flex gap={16}>
        <Link onClick={enterFullscreen} variant="secondary" tx={expandTx} />
        <Link onClick={handlePrintQrCode} tx={printTx} variant="secondary" />
      </Box>
    </Box>
  );
}

function ExpandedQrCode() {
  const { headerLogoURL, closeTx, footerLogoURL } = React.useContext(
    ExpandedQrCodeContext,
  );

  const { isFullscreen, exitFullscreen } = useFullscreen();

  const closeButtonBoxRef = React.useRef<HTMLDivElement>(null);
  const timeoutRef = React.useRef<number | null>(null);

  const handleMouseMove = () => {
    if (closeButtonBoxRef.current?.hasAttribute('hidden')) {
      closeButtonBoxRef.current?.removeAttribute('hidden');
    }

    if (timeoutRef.current) {
      window.clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = window.setTimeout(() => {
      if (!closeButtonBoxRef.current) return;

      closeButtonBoxRef.current.setAttribute('hidden', 'true');
      timeoutRef.current = null;
    }, 10000);
  };

  if (!isFullscreen) {
    return null;
  }

  return (
    <Styled.ExpandedQrCode
      fixed
      inset={0}
      zIndex={1000}
      onMouseMove={handleMouseMove}
    >
      <Box
        backgroundColor="expandedQrCodeBackground"
        flex
        flexAlign="center"
        flexJustify="center"
        height="100%"
        relative
        width="100%"
      >
        <Box
          onClick={exitFullscreen}
          ref={closeButtonBoxRef}
          absolute
          top={32}
          right={32}
        >
          <Tooltip placement="left" titleTx={closeTx}>
            <SolidIconButton icon="x-mark" />
          </Tooltip>
        </Box>

        <Box flex flexDirection="column" flexAlign="center" gap={32}>
          {headerLogoURL && <Image src={headerLogoURL} />}

          <QrCode size={540} logoSize={120} />

          <Image src={footerLogoURL} />
        </Box>
      </Box>
    </Styled.ExpandedQrCode>
  );
}

export function ExpandedQrCodeProvider(
  props: React.PropsWithChildren<ExpandedQrCodeProps>,
) {
  const { children } = props;

  const id = React.useId();

  return (
    <ExpandedQrCodeContext.Provider value={{ ...props, id }}>
      <FullscreenProvider>
        <ExpandedQrCode />

        {children}
      </FullscreenProvider>
    </ExpandedQrCodeContext.Provider>
  );
}
