import {
  GeneralApiResult,
  GeneralApiResultWithData,
} from '@orbiapp/components';

import { Activity } from '../../../../../models';
import { Logger } from '../../../../logger';
import { apisauce } from '../../../api';
import { CUSTOM_TIMEOUTS } from '../../../api.constants';
import { getApiLogExtras, getGeneralApiProblem } from '../../../api.utils';
import { ActivityDto } from './activities.dto';
import { getActivityMapper } from './activities.mappers';

export const unpublishActivity = async (
  activityKey: string,
): Promise<GeneralApiResult> => {
  const res = await apisauce.put<void, any>(
    `/v1/activities/${activityKey}/unpublish`,
  );

  if (!res.ok) {
    Logger.warning('unpublishActivity', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  return { kind: 'ok' };
};

export const getActivity = async (getActivityParams: {
  activityKey: string;
  enableTags: boolean;
}): Promise<GeneralApiResultWithData<Activity>> => {
  const { activityKey, enableTags } = getActivityParams;

  const res = await apisauce.get<ActivityDto, any>(
    `/v1/activities/${activityKey}`,
    undefined,
    { timeout: CUSTOM_TIMEOUTS.getActivity },
  );

  if (!res.ok) {
    Logger.warning('getActivity', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data: Activity = getActivityMapper(res.data, {
      enableTags,
    });

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('getActivity', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};
