import { joiResolver } from '@hookform/resolvers/joi';
import {
  Box,
  Button,
  Checkbox,
  ContentContainer,
  ContentSidebar,
  ContentSidebarContentContainer,
  ContentSidebarFooterContainer,
  ControlledTextarea,
  EmptyState,
  InnerContentContainer,
  InnerPageContainer,
  Modal,
  ModalBodyContainer,
  ModalContentContainer,
  ModalFooterContainer,
  ModalHeaderContainer,
  ModalTitle,
  Paginator,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TablePlaceholderRows,
  TableRow,
  Text,
  TinySelect,
  flattenFieldErrorsObject,
  paginatorOptions,
  parseTimestamp,
} from '@orbiapp/components';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Navigate } from 'react-router-dom';

import { useDataGridPagination } from '../../../../../helpers';
import {
  ACTIVITY_POST_MESSAGE_MAX_LENGTH,
  ActivityPost,
  CreateActivityPost,
  CreateActivityPostValidation,
  UpdateActivityPost,
  UpdateActivityPostValidation,
  semesters,
} from '../../../../../models';
import { Logger } from '../../../../../services';
import {
  ActivityDataSelector,
  ActivityPostsSelector,
  ActivitySelector,
  CreateActivityPostSelector,
  UpdateActivityPostSelector,
  createActivityPostThunk,
  getActivityPostsThunk,
  postsActions,
  updateActivityPostThunk,
  useDispatch,
  useSelector,
} from '../../../../../store';
import { EventActions } from '../components';
import { ActivityPostsContextType } from './posts.types';

export const ActivityPostsContext =
  React.createContext<ActivityPostsContextType>({
    setCreatePostModalIsOpen: () => {},
    createPostModalIsOpen: false,
    selectedActivityPost: null,
    setSelectedActivityPost: () => {},
  });

export function ActivityPostsProvider(props: React.PropsWithChildren) {
  const { children } = props;

  const [selectedActivityPost, setSelectedActivityPost] =
    React.useState<ActivityPost | null>(null);

  const [createPostModalIsOpen, setCreatePostModalIsOpen] =
    React.useState(false);

  return (
    <ActivityPostsContext.Provider
      value={{
        createPostModalIsOpen,
        selectedActivityPost,
        setCreatePostModalIsOpen,
        setSelectedActivityPost,
      }}
    >
      {children}
    </ActivityPostsContext.Provider>
  );
}

const TABLE_COLUMNS_WIDTHS = {
  message: '75ch',
  createdAt: 150,
};

function ActivityPostsTable() {
  const {
    setCreatePostModalIsOpen,
    selectedActivityPost,
    setSelectedActivityPost,
  } = React.useContext(ActivityPostsContext);

  const activityPosts = useSelector(ActivityPostsSelector.selectAll);
  const activityPostsPagination = useSelector(
    ActivityPostsSelector.selectPagination,
  );
  const activityPostsStatus = useSelector(ActivityPostsSelector.selectStatus);
  const isLoading = activityPostsStatus === 'pending';

  const { rows, paginatorProps, onPageSizeChange, onPaginate } =
    useDataGridPagination<ActivityPost, undefined>({
      data: activityPosts,
      pagination: activityPostsPagination,
      reset: postsActions.clearActivityPosts,
      thunk: getActivityPostsThunk,
    });

  const renderPostTableRow = (activityPost: ActivityPost) => {
    const openActivityPostsSidebar = () =>
      setSelectedActivityPost(activityPost);

    return (
      <TableRow
        highlight={
          activityPost.activityPostKey === selectedActivityPost?.activityPostKey
        }
        onClick={openActivityPostsSidebar}
        key={activityPost.activityPostKey}
      >
        <TableCell
          width={TABLE_COLUMNS_WIDTHS.message}
          text={activityPost.message}
        />
        <TableCell
          width={TABLE_COLUMNS_WIDTHS.createdAt}
          text={parseTimestamp(activityPost.createdAt, 'DD MMM YYYY HH:mm')}
        />

        <TableCell width={50} fixedRight />
      </TableRow>
    );
  };

  if (isLoading && rows.length === 0) {
    return (
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead tx="label.posts.table.message" />
            <TableHead tx="label.posts.table.created-at" />
          </TableRow>
        </TableHeader>

        <TableBody>
          <TablePlaceholderRows
            rowCount={10}
            layout={Object.values(TABLE_COLUMNS_WIDTHS)}
          />
        </TableBody>
      </Table>
    );
  }

  if (!isLoading && rows.length === 0) {
    const openCreatePostModal = () => setCreatePostModalIsOpen(true);

    return (
      <EmptyState
        titleTx="placeholder.table.posts"
        buttonTx="button.posts.create"
        buttonOnClick={openCreatePostModal}
      />
    );
  }

  return (
    <React.Fragment>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead tx="label.posts.table.message" />
            <TableHead tx="label.posts.table.created-at" />
            <TableHead fixedRight />
          </TableRow>
        </TableHeader>

        <TableBody>{rows.map(renderPostTableRow)}</TableBody>
      </Table>

      <Box flexJustify="end" gap={24} flexAlign="center" flex>
        <Box flex gap={16} flexAlign="center">
          <Text
            tx="label.general.rows-per-page"
            variant="bodySm"
            whiteSpace="nowrap"
          />

          <TinySelect
            invertMenu
            onChange={onPageSizeChange}
            options={paginatorOptions}
            value={paginatorProps.pageSize}
          />
        </Box>

        <Paginator
          currentPage={paginatorProps.currentPage}
          hasNextPage={paginatorProps.hasNextPage}
          hasPrevPage={paginatorProps.hasPrevPage}
          onPaginate={onPaginate}
        />
      </Box>
    </React.Fragment>
  );
}

export function CreateEventPostModal() {
  const { createPostModalIsOpen, setCreatePostModalIsOpen } =
    React.useContext(ActivityPostsContext);

  const activityKey = useSelector(ActivityDataSelector.selectActivityKey);

  const dispatch = useDispatch();

  const createActivityPost = useSelector(
    CreateActivityPostSelector.selectStatus,
  );

  const isLoading = createActivityPost === 'pending';

  const formMethods = useForm<CreateActivityPost>({
    resolver: joiResolver(CreateActivityPostValidation),
    defaultValues: {
      activityKey: activityKey ?? '',
      message: '',
      audiences: [],
    },
  });

  const closeCreatePostModal = () => setCreatePostModalIsOpen(false);

  const createPost = formMethods.handleSubmit(
    async (data) => {
      await dispatch(createActivityPostThunk(data));

      formMethods.reset();

      closeCreatePostModal();
    },
    (err) => {
      Logger.warning('createEventPost Validation', {
        err: flattenFieldErrorsObject(err),
      });
    },
  );

  const audiences = formMethods.watch('audiences');

  const attendeesIsSelected = audiences?.includes('attendees') ?? false;
  const savesIsSelected = audiences?.includes('saves') ?? false;
  const allIsSelected =
    attendeesIsSelected &&
    savesIsSelected &&
    semesters.every((semester) => audiences?.includes(semester) ?? false);

  const selectAll = () => {
    if (allIsSelected) {
      formMethods.setValue('audiences', []);
    } else {
      formMethods.setValue('audiences', ['attendees', 'saves', ...semesters]);
    }
  };

  const selectAttendees = () => {
    if (attendeesIsSelected) {
      formMethods.setValue(
        'audiences',
        audiences?.filter((audience) => audience !== 'attendees') ?? [],
      );
    } else {
      formMethods.setValue('audiences', [...(audiences ?? []), 'attendees']);
    }
  };

  const selectSaved = () => {
    if (savesIsSelected) {
      formMethods.setValue(
        'audiences',
        audiences?.filter((audience) => audience !== 'saves') ?? [],
      );
    } else {
      formMethods.setValue('audiences', [...(audiences ?? []), 'saves']);
    }
  };

  return (
    <Modal isOpen={createPostModalIsOpen} onClose={closeCreatePostModal}>
      <FormProvider {...formMethods}>
        <ModalContentContainer>
          <ModalHeaderContainer>
            <ModalTitle tx="label.posts.create-post.label" />
          </ModalHeaderContainer>

          <ModalBodyContainer>
            <Text
              variant="bodyMdBold"
              tx="label.view-activity.posts.create-post.steps.message.label"
            />

            <ControlledTextarea
              disabled={isLoading}
              labelTx="label.posts.create-post.message"
              maxLength={ACTIVITY_POST_MESSAGE_MAX_LENGTH}
              name="message"
            />

            <Text
              variant="bodyMdBold"
              tx="label.view-activity.posts.create-post.steps.audiences.label"
            />

            <Checkbox
              checked={allIsSelected}
              onChange={selectAll}
              tx="label.view-activity.posts.create-post.all"
            />

            <Checkbox
              checked={attendeesIsSelected}
              disabled={allIsSelected}
              onChange={selectAttendees}
              tx="label.view-activity.posts.create-post.attendees"
            />

            <Checkbox
              checked={savesIsSelected}
              disabled={allIsSelected}
              onChange={selectSaved}
              tx="label.view-activity.posts.create-post.saves"
            />
          </ModalBodyContainer>

          <ModalFooterContainer>
            <Button
              onClick={closeCreatePostModal}
              tx="button.cancel"
              variant="tertiary"
            />
            <Button
              isLoading={isLoading}
              onClick={createPost}
              tx="button.posts.create"
              variant="primary"
            />
          </ModalFooterContainer>
        </ModalContentContainer>
      </FormProvider>
    </Modal>
  );
}

function ActivityPostsContent() {
  return (
    <React.Fragment>
      <Box flex flexWrap="wrap" gap={16} flexJustify="between">
        <Text
          as="h1"
          color="pageTitle"
          tx="label.view-activity.tabs.posts"
          variant="titleMd"
        />

        <EventActions />
      </Box>

      <ActivityPostsTable />
    </React.Fragment>
  );
}

function UpdatePostFormContent() {
  const { selectedActivityPost } = React.useContext(ActivityPostsContext);

  const activityKey = useSelector(ActivityDataSelector.selectActivityKey);

  const dispatch = useDispatch();

  const updateActivityPostStatus = useSelector(
    UpdateActivityPostSelector.selectStatus,
  );

  const isLoading = updateActivityPostStatus === 'pending';

  const formMethods = useForm<UpdateActivityPost>({
    resolver: joiResolver(UpdateActivityPostValidation),
    defaultValues: {
      activityKey: activityKey ?? '',
      activityPostKey: selectedActivityPost?.activityPostKey ?? '',
      message: selectedActivityPost?.message ?? '',
    },
  });

  const updateActivityPost = formMethods.handleSubmit(
    (data) => {
      dispatch(updateActivityPostThunk(data));
    },
    (err) => {
      Logger.warning('updateEventPost Validation', {
        err: flattenFieldErrorsObject(err),
      });
    },
  );

  React.useEffect(() => {
    formMethods.reset({
      activityKey: activityKey ?? '',
      activityPostKey: selectedActivityPost?.activityPostKey ?? '',
      message: selectedActivityPost?.message ?? '',
    });
  }, [activityKey, formMethods, selectedActivityPost]);

  return (
    <FormProvider {...formMethods}>
      <ContentSidebarContentContainer>
        <Text
          color="pageTitle"
          as="h1"
          tx="label.posts.edit-post.title"
          variant="titleMd"
        />

        <ControlledTextarea
          disabled={isLoading}
          labelTx="label.posts.create-post.message"
          maxLength={ACTIVITY_POST_MESSAGE_MAX_LENGTH}
          name="message"
        />
      </ContentSidebarContentContainer>

      <ContentSidebarFooterContainer flexJustify="end">
        <Button
          isLoading={isLoading}
          onClick={updateActivityPost}
          tx="button.save"
          variant="secondary"
        />
      </ContentSidebarFooterContainer>
    </FormProvider>
  );
}

function ActivityPostsSidebar() {
  const { selectedActivityPost, setSelectedActivityPost } =
    React.useContext(ActivityPostsContext);

  const isOpen = selectedActivityPost !== null;
  const closeActivityPostsSidebar = () => setSelectedActivityPost(null);

  return (
    <ContentSidebar
      isOpen={isOpen}
      onClose={closeActivityPostsSidebar}
      width={400}
    >
      <UpdatePostFormContent />
    </ContentSidebar>
  );
}

export function ViewActivityPosts() {
  const isActivityOwner = useSelector(ActivitySelector.selectIsActivityOwner);
  const activityKey = useSelector(ActivityDataSelector.selectActivityKey);

  if (!isActivityOwner) {
    return <Navigate to={`/activities/${activityKey}/description`} />;
  }

  return (
    <React.Fragment>
      <InnerPageContainer>
        <ContentContainer>
          <InnerContentContainer>
            <ActivityPostsContent />
          </InnerContentContainer>
        </ContentContainer>

        <ActivityPostsSidebar />
      </InnerPageContainer>
    </React.Fragment>
  );
}
