import { Box, Chip, Text } from '@orbiapp/components';

import {
  SelectionCard,
  SelectionCardList,
  SelectionCardListItem,
} from '../selection-card';

interface SelectionCardProps {
  isSelected?: boolean;
  onSelect: () => void;
}

export const StandardSelectionCard = (props: SelectionCardProps) => {
  return (
    <SelectionCard {...props}>
      <Text
        tx="label.memberships.create-membership.settings.membership-type.standard"
        variant="titleSm"
      />

      <SelectionCardList>
        <SelectionCardListItem
          titleTx="label.memberships.create-membership.settings.membership-type.ask-questions-tooltip"
          tx="label.memberships.create-membership.settings.membership-type.ask-questions"
        />
        <SelectionCardListItem
          titleTx="label.memberships.create-membership.settings.membership-type.take-payments-tooltip"
          tx="label.memberships.create-membership.settings.membership-type.take-payments"
        />
        <SelectionCardListItem
          titleTx="label.memberships.create-membership.settings.membership-type.invite-members-tooltip"
          tx="label.memberships.create-membership.settings.membership-type.invite-members"
        />
        <SelectionCardListItem
          titleTx="label.memberships.create-membership.settings.membership-type.require-tooltip"
          tx="label.memberships.create-membership.settings.membership-type.require"
        />
      </SelectionCardList>
    </SelectionCard>
  );
};

export const NeedsApprovalSelectionCard = (props: SelectionCardProps) => {
  return (
    <SelectionCard {...props}>
      <Text
        tx="label.memberships.create-membership.settings.membership-type.needs-approval"
        variant="titleSm"
      />

      <SelectionCardList>
        <SelectionCardListItem
          titleTx="label.memberships.create-membership.settings.membership-type.standard-features-tooltip"
          tx="label.memberships.create-membership.settings.membership-type.standard-features"
        />
        <SelectionCardListItem
          titleTx="label.memberships.create-membership.settings.membership-type.review-tooltip"
          tx="label.memberships.create-membership.settings.membership-type.review"
        />
      </SelectionCardList>
    </SelectionCard>
  );
};

export function InviteOnlySelectionCard(props: SelectionCardProps) {
  return (
    <SelectionCard {...props}>
      <Box flexJustify="between" flexAlign="center" flex>
        <Text
          tx="label.memberships.create-membership.settings.membership-type.invite-only"
          variant="titleSm"
        />

        <Chip
          tx="label.memberships.create-membership.settings.membership-type.hidden-pill"
          variant="secondary"
        />
      </Box>

      <SelectionCardList>
        <SelectionCardListItem
          titleTx="label.memberships.create-membership.settings.membership-type.standard-features-tooltip"
          tx="label.memberships.create-membership.settings.membership-type.standard-features"
        />
        <SelectionCardListItem
          titleTx="label.memberships.create-membership.settings.membership-type.hidden-tooltip"
          tx="label.memberships.create-membership.settings.membership-type.hidden"
        />
      </SelectionCardList>
    </SelectionCard>
  );
}
