import React from 'react';

import { translate } from '../../i18n';
import { getPlacementMargin } from '../../utils';
import { Avatar, AvatarProps } from '../avatar';
import { Box } from '../box';
import { Divider } from '../divider';
import { Link, LinkProps } from '../link';
import { Menu, useAnchoredMenu } from '../menu';
import { ResponsiveBox } from '../responsive-box';
import { Sheet } from '../sheet';
import { Text } from '../text';
import { Styled } from './select-workspace.styled';

export interface SelectWorkspaceMenuItem {
  label: string;

  to: string;

  avatarVariant: AvatarProps['variant'];
  avatarSrc: string;

  switchTx?: TxString;
}

interface SelectWorkspaceMenuProps {
  label: string;

  avatarSrc?: string;
  avatarVariant?: AvatarProps['variant'];

  workspaces: SelectWorkspaceMenuItem[];

  emptyStateTx: TxString;

  emptyStateLinkTo?: string;
  emptyStateLinkTx?: TxString;
  emptyStateLinkHref?: string;
  emptyStateLinkTarget?: LinkProps['target'];

  filterPlaceholderTx: TxString;
}

function renderWorkspaceMenuItem(
  menuItem: SelectWorkspaceMenuItem,
  index: number,
) {
  return (
    <Styled.WorkspaceMenuItem
      key={`workspace-menu-item-${index}`}
      to={menuItem.to}
    >
      <Box flex flexJustify="between" gap={8}>
        <Box flexAlign="center" overflow="hidden" gap={8} flex>
          <Avatar
            fallbackLetter={menuItem.label.charAt(0)}
            src={menuItem.avatarSrc}
            variant={menuItem.avatarVariant}
            height={24}
            minHeight={24}
            width={24}
            minWidth={24}
          />

          <Text
            textOverflow="ellipsis"
            overflow="hidden"
            variant="bodyMd"
            text={menuItem.label}
            whiteSpace="nowrap"
          />
        </Box>

        {menuItem.switchTx && <Styled.SwitchLink tx={menuItem.switchTx} />}
      </Box>
    </Styled.WorkspaceMenuItem>
  );
}

function renderWorkspaceSheetMenuItem(
  menuItem: SelectWorkspaceMenuItem,
  index: number,
) {
  return (
    <Link plain key={`workspace-sheet-menu-item-${index}`} to={menuItem.to}>
      <Box p={16} flex flexJustify="between" gap={8}>
        <Box flexAlign="center" overflow="hidden" gap={8} flex>
          <Avatar
            fallbackLetter={menuItem.label.charAt(0)}
            src={menuItem.avatarSrc}
            variant={menuItem.avatarVariant}
            height={24}
            minHeight={24}
            width={24}
            minWidth={24}
          />

          <Text
            textOverflow="ellipsis"
            overflow="hidden"
            variant="bodyMd"
            text={menuItem.label}
            whiteSpace="nowrap"
          />
        </Box>
      </Box>

      <Divider />
    </Link>
  );
}

export function SelectWorkspaceMenu(props: SelectWorkspaceMenuProps) {
  const {
    label,
    workspaces,

    avatarSrc,
    avatarVariant,

    emptyStateTx,

    emptyStateLinkTo,
    emptyStateLinkTx,
    emptyStateLinkHref,
    emptyStateLinkTarget,

    filterPlaceholderTx,
  } = props;

  const anchorRef = React.useRef<HTMLDivElement>(null);
  const clickOutsideRef = React.useRef<HTMLDivElement>(null);

  const [searchString, setSearchString] = React.useState('');

  const { closeMenu, isOpen, openMenu, menuRef } = useAnchoredMenu({
    anchorRef,
    placement: 'bottom-start',
    clickOutsideRef,
  });

  const workspacesIsEmpty = workspaces.length === 0;

  const emptyStateText = (
    <Text
      color="selectWorkspaceEmptyStateLabel"
      overflow="hidden"
      textOverflow="ellipsis"
      tx={emptyStateTx}
      variant="bodySmItalic"
      whiteSpace="nowrap"
    />
  );

  const emptyStateLink =
    emptyStateLinkTo || emptyStateLinkHref ? (
      <Link
        href={emptyStateLinkHref}
        target={emptyStateLinkTarget}
        to={emptyStateLinkTo}
        tx={emptyStateLinkTx}
        variant="secondary"
        small
      />
    ) : null;

  const handleSearchChange: React.ChangeEventHandler<HTMLInputElement> = (
    event,
  ) => {
    setSearchString(event.target.value);
  };

  const filterWorkspacesInput = (
    <Styled.FilterInput
      placeholder={translate(filterPlaceholderTx)}
      value={searchString}
      onChange={handleSearchChange}
      max={255}
    />
  );

  const showSearchInput = workspaces.length > 8;

  const filteredWorkspaces = workspaces.filter((workspace) =>
    workspace.label.toLowerCase().includes(searchString.toLowerCase()),
  );

  return (
    <Box overflow="hidden" ref={clickOutsideRef}>
      <Styled.SelectWorkspaceBox
        cursor="pointer"
        flex
        flexAlign="center"
        gap={8}
        isOpen={isOpen}
        onClick={isOpen ? closeMenu : openMenu}
        overflow="hidden"
        ref={anchorRef}
        p={4}
        r={4}
      >
        <ResponsiveBox
          xs={
            <Avatar
              width={40}
              height={40}
              alt={label}
              src={avatarSrc}
              fallbackLetter={label.charAt(0)}
              variant={avatarVariant}
            />
          }
        >
          <Avatar
            width={32}
            height={32}
            alt={label}
            src={avatarSrc}
            fallbackLetter={label.charAt(0)}
            variant={avatarVariant}
          />
        </ResponsiveBox>

        <Styled.SelectWorkspaceBoxText
          userSelect="none"
          overflow="hidden"
          maxWidth="24ch"
          textOverflow="ellipsis"
          variant="bodyMd"
          whiteSpace="nowrap"
          text={label}
        />

        <Styled.SelectWorkspaceBoxIcon
          color="iconButtonIcon"
          name={isOpen ? 'chevron-up' : 'chevron-down'}
        />
      </Styled.SelectWorkspaceBox>

      <ResponsiveBox
        xs={
          <Menu
            width={workspacesIsEmpty ? 'auto' : 300}
            isOpen={isOpen}
            ref={menuRef}
            fixed
            maxHeight={320}
            {...getPlacementMargin('bottom-start')}
          >
            {showSearchInput && (
              <Box sticky top={0} backgroundColor="menuItemBackground" p={8}>
                {filterWorkspacesInput}
              </Box>
            )}

            {workspacesIsEmpty ? (
              <Styled.WorkspaceMenuItem>
                <Box flex gap={16}>
                  {emptyStateText}
                  {emptyStateLink}
                </Box>
              </Styled.WorkspaceMenuItem>
            ) : (
              filteredWorkspaces.map(renderWorkspaceMenuItem)
            )}
          </Menu>
        }
      >
        <Sheet isOpen={isOpen} onClose={closeMenu} pt={16}>
          <Box overflow="auto" height="100%">
            {showSearchInput && (
              <Box sticky top={0} backgroundColor="menuItemBackground" p={12}>
                {filterWorkspacesInput}
              </Box>
            )}

            {workspacesIsEmpty ? (
              <Box
                mt={64}
                flexAlign="center"
                flex
                flexDirection="column"
                flexJustify="center"
                gap={16}
              >
                {emptyStateText}
                {emptyStateLink}
              </Box>
            ) : (
              filteredWorkspaces.map(renderWorkspaceSheetMenuItem)
            )}
          </Box>
        </Sheet>
      </ResponsiveBox>
    </Box>
  );
}
