import {
  GeneralApiResult,
  GeneralApiResultWithData,
  Pagination,
} from '@orbiapp/components';

import {
  MembershipInviteListItem,
  MembershipInviteOrderByKey,
  MembershipInviteStatus,
  MembershipInvitesForm,
  MembershipInvitesResult,
} from '../../../../../../models';
import { Logger } from '../../../../../logger';
import { apisauce } from '../../../../api';
import { getApiLogExtras, getGeneralApiProblem } from '../../../../api.utils';
import {
  CreateMembershipInvitesResultDto,
  MembershipInviteListItemDto,
} from './invites.dto';
import {
  createMembershipInvitationsMapper,
  createMembershipInvitationsResultMapper,
  membershipInviteListItemMapper,
} from './invites.mappers';

export const withdrawMembershipInvite = async (params: {
  membershipTypeKey: string;
  membershipApplicationKey: string;
}): Promise<GeneralApiResult> => {
  const { membershipTypeKey, membershipApplicationKey } = params;

  const res = await apisauce.put<void, any>(
    `/v2/membership-types/${membershipTypeKey}/invites/${membershipApplicationKey}/withdraw`,
  );

  if (!res.ok) {
    Logger.warning('withdrawMembershipInvite', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  return { kind: 'ok' };
};

export const createMembershipInvitations = async (params: {
  membershipTypeKey: string;
  membershipInvitesForm: MembershipInvitesForm;
}): Promise<GeneralApiResultWithData<MembershipInvitesResult>> => {
  const { membershipTypeKey, membershipInvitesForm } = params;

  const res = await apisauce.post<CreateMembershipInvitesResultDto, any>(
    `/v2/membership-types/${membershipTypeKey}/invites`,
    createMembershipInvitationsMapper(membershipInvitesForm),
  );

  if (!res.ok) {
    Logger.warning('createMembershipInvitations', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data: MembershipInvitesResult =
      createMembershipInvitationsResultMapper(res.data);

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('createMembershipInvitations', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export const getMembershipInvites = async (params: {
  membershipTypeKey: string;
  pagination: Pagination<MembershipInviteOrderByKey>;
  includeStatus: MembershipInviteStatus | null;
}): Promise<GeneralApiResultWithData<MembershipInviteListItem[]>> => {
  const { membershipTypeKey, pagination, includeStatus } = params;

  const res = await apisauce.get<MembershipInviteListItemDto[], any>(
    `/v2/membership-types/${membershipTypeKey}/invites`,
    { ...pagination, status: includeStatus ?? undefined },
  );

  if (!res.ok) {
    Logger.warning('getMembershipInvites', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data: MembershipInviteListItem[] = res.data.map(
      membershipInviteListItemMapper,
    );

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('getMembershipInvites', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};
