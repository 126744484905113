import { GeneralApiResultWithData } from '@orbiapp/components';

import { MembershipApplicationRequirement } from '../../../../../../models';
import { Logger } from '../../../../../logger';
import { apisauce } from '../../../../api';
import { getGeneralApiProblem } from '../../../../api.utils';
import { MembershipApplicationRequirementDto } from './suggested-membership-application-requirements.dto';
import { membershipApplicationRequirementsMapper } from './suggested-membership-application-requirements.mappers';

export const getSuggestedMembershipApplicationRequirements = async (params: {
  includeHidden: boolean;
}): Promise<GeneralApiResultWithData<MembershipApplicationRequirement[]>> => {
  const { includeHidden } = params;

  const res = await apisauce.get<MembershipApplicationRequirementDto[], any>(
    '/v2/membership-types/suggested-membership-application-requirements',
    { includeHidden },
  );

  if (!res.ok) {
    Logger.warning('getSuggestedMembershipApplicationRequirements', {
      res: JSON.stringify(res),
    });
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data: MembershipApplicationRequirement[] = res.data.map(
      membershipApplicationRequirementsMapper,
    );

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('getSuggestedMembershipApplicationRequirements', {
      res: JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};
