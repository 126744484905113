import { FeatureFlags } from './feature-flags.model';

/**
 * NB: The flags are lower-cased in the backend and on statsig anyway.
 * Consider replacing with lower-case.
 */
export const FEATURE_FLAGS: (keyof FeatureFlags)[] = [
  'STUDENT_DASHBOARD_MEMLIST_MEMBERSHIP',
  'STUDENT_DASHBOARD_SHOW_QUESTIONS',
  'ACTIVITY_DRAFTS_ON_BACKEND',
];
