import { joiResolver } from '@hookform/resolvers/joi';
import {
  Box,
  ContentContainer,
  ControlledInput,
  ControlledSelect,
  IconButton,
  InnerContentContainer,
  Text,
  Tooltip,
  flattenFieldErrorsObject,
  isAnyTrue,
} from '@orbiapp/components';
import React from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import {
  CreateIntegratedMembershipTypeForm,
  INTEGRATION_API_KEY_MAX_LENGTH,
  INTEGRATION_URL_MAX_LENGTH,
  MembershipTypeIntegrationSource,
  REFRESH_TOKEN_MAX_LENGTH,
  SelectServiceFormValidation,
  integrationSource,
} from '../../../../../models';
import { Logger } from '../../../../../services';
import {
  CheckIntegrationSelector,
  FeatureFlagsSelector,
  checkIntegrationThunk,
  useDispatch,
  useSelector,
} from '../../../../../store';
import { CreateIntegratedMembershipFormNavBlocker } from '../components';
import { Styled } from './select-service.styled';

const OPTIONS_WITH_MEMLIST = integrationSource.map((source) => ({
  text: source,
  value: source,
}));

const OPTIONS_WITHOUT_MEMLIST = integrationSource
  .filter((source) => source !== 'memlist')
  .map((source) => ({
    text: source,
    value: source,
  }));

function EnterMetadataBox() {
  const formContext =
    useFormContext<CreateIntegratedMembershipTypeForm['metadata']>();

  const source = formContext.watch('source');

  switch (source) {
    case 'montania':
      return (
        <Styled.EnterMetaDataBox
          flex
          flexDirection="column"
          gap={16}
          p={32}
          r={8}
        >
          <Text
            variant="bodyMdBold"
            tx="label.memberships.create-membership.select-service.enter-details"
          />

          <ControlledInput
            labelTx="label.memberships.create-membership.integration-url.label"
            maxLength={INTEGRATION_URL_MAX_LENGTH}
            name="integrationUrl"
            required
          />

          <ControlledInput
            labelTx="label.memberships.create-membership.api-key.label"
            maxLength={INTEGRATION_API_KEY_MAX_LENGTH}
            name="apiKey"
            required
          />
        </Styled.EnterMetaDataBox>
      );

    case 'memlist':
      return (
        <Styled.EnterMetaDataBox
          flex
          flexDirection="column"
          gap={16}
          p={32}
          r={8}
        >
          <Text
            variant="bodyMdBold"
            tx="label.memberships.create-membership.select-service.enter-details"
          />

          <ControlledInput
            labelTx="label.memberships.create-membership.integration-url.label"
            maxLength={INTEGRATION_URL_MAX_LENGTH}
            name="integrationUrl"
            required
          />

          <ControlledInput
            labelTx="label.memberships.create-membership.refresh-token.label"
            maxLength={REFRESH_TOKEN_MAX_LENGTH}
            name="refreshToken"
            required
          />
        </Styled.EnterMetaDataBox>
      );
  }
}

export function CreateIntegratedMembershipSelectService() {
  const checkIntegrationStatus = useSelector(
    CheckIntegrationSelector.selectStatus,
  );
  const featureFlags = useSelector(FeatureFlagsSelector.selectFeatureFlags);

  const createIntegratedMembershipTypeFormContext =
    useFormContext<CreateIntegratedMembershipTypeForm>();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const metadata =
    createIntegratedMembershipTypeFormContext.getValues('metadata');

  const formMethods = useForm<CreateIntegratedMembershipTypeForm['metadata']>({
    resolver: joiResolver(SelectServiceFormValidation),
    defaultValues: metadata,
  });

  const handleNext = formMethods.handleSubmit(
    async (data) => {
      const res = await dispatch(checkIntegrationThunk(data));

      if (res.meta.requestStatus === 'fulfilled') {
        createIntegratedMembershipTypeFormContext.setValue('metadata', data, {
          shouldDirty: true,
        });

        navigate('/memberships/create-integration/general-info');
      }
    },
    (err) => {
      Logger.warning('createMembershipIntegrationSelectService Validation', {
        err: flattenFieldErrorsObject(err),
      });
    },
  );

  const handleSourceChange: React.ChangeEventHandler<HTMLSelectElement> = (
    e,
  ) => {
    const source = e.target.value as MembershipTypeIntegrationSource;

    switch (source) {
      case 'memlist':
        formMethods.reset({ source, refreshToken: '' });
        break;
      case 'montania':
        formMethods.reset({ source, integrationUrl: '', apiKey: '' });
        break;
    }
  };

  return (
    <React.Fragment>
      <ContentContainer>
        <InnerContentContainer>
          <Text
            color="pageTitle"
            as="h1"
            tx="label.memberships.create-membership.tabs.select-type"
            variant="titleMd"
          />

          <FormProvider {...formMethods}>
            <CreateIntegratedMembershipFormNavBlocker
              shouldBlock={isAnyTrue(
                createIntegratedMembershipTypeFormContext.formState.isDirty,
                formMethods.formState.isDirty,
              )}
            />

            <ControlledSelect
              width="fit-content"
              labelTx="label.memberships.create-membership.source.label"
              name="source"
              onChange={handleSourceChange}
              options={
                featureFlags?.STUDENT_DASHBOARD_MEMLIST_MEMBERSHIP
                  ? OPTIONS_WITH_MEMLIST
                  : OPTIONS_WITHOUT_MEMLIST
              }
            />

            <EnterMetadataBox />
          </FormProvider>
        </InnerContentContainer>

        <Box p={32} flex flexJustify="between" gap={16}>
          <Tooltip titleTx="button.previous" placement="right">
            <IconButton icon="arrow-left-circle-outline" disabled />
          </Tooltip>

          <Tooltip placement="left" titleTx="button.continue">
            <IconButton
              icon="arrow-right-circle-outline"
              onClick={handleNext}
              disabled={checkIntegrationStatus === 'pending'}
            />
          </Tooltip>
        </Box>
      </ContentContainer>
    </React.Fragment>
  );
}
