import {
  GeneralApiResult,
  GeneralApiResultWithData,
  Pagination,
} from '@orbiapp/components';

import {
  CreateDepartmentPost,
  DepartmentPost,
  UpdateDepartmentPost,
} from '../../../../../../models';
import { Logger } from '../../../../../logger';
import { apisauce } from '../../../../api';
import { getApiLogExtras, getGeneralApiProblem } from '../../../../api.utils';
import { DepartmentPostDto } from './posts.dto';
import {
  createDepartmentPostMapper,
  departmentPostMapper,
} from './posts.mappers';

export const createDepartmentPost = async (
  createDepartmentPost: CreateDepartmentPost,
): Promise<GeneralApiResultWithData<string>> => {
  const res = await apisauce.post<{ postKey: string }, any>(
    `/v1/department/posts`,
    createDepartmentPostMapper(createDepartmentPost),
  );

  if (!res.ok) {
    Logger.warning('createDepartmentPost', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data: string = res.data.postKey;

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('createDepartmentPost', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export const updateDepartmentPost = async (
  updateDepartmentPost: UpdateDepartmentPost,
): Promise<GeneralApiResult> => {
  const { departmentPostKey, ...rest } = updateDepartmentPost;

  const res = await apisauce.put<void, any>(
    `/v1/department/posts/${departmentPostKey}`,
    rest,
  );

  if (!res.ok) {
    Logger.warning('updateDepartmentPost', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  return { kind: 'ok' };
};

export const getDepartmentPosts = async (params: {
  pagination: Pagination;
}): Promise<GeneralApiResultWithData<DepartmentPost[]>> => {
  const { pagination } = params;

  const res = await apisauce.get<DepartmentPostDto[], any>(
    '/v1/department/posts',
    pagination,
  );

  if (!res.ok) {
    Logger.warning('getDepartmentPosts', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data: DepartmentPost[] = res.data.map(departmentPostMapper);

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('getDepartmentPosts', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};
