import {
  Alert,
  Box,
  Button,
  Snackbar,
  Text,
  flattenFieldErrorsObject,
  isObject,
} from '@orbiapp/components';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Logger } from '../../../../../../services';
import {
  ActivityDataSelector,
  CreateActivitySelector,
  UpdateActivitySelector,
  createActivityThunk,
  updateActivityThunk,
  useDispatch,
  useSelector,
} from '../../../../../../store';
import { isAnyPending } from '../../../../../../utils';
import { ActivityFormContext } from '../../create-activity.context';
import { Styled } from './publish-event-button.styled';

function getAlertErrorKeys(keys: string[]): TxString[] {
  return keys.map((key): TxString => {
    switch (key) {
      case 'description':
        return 'errors.activity-form.description';

      case 'media':
        return 'errors.activity-form.media';

      case 'participants':
        return 'errors.activity-form.participants';

      case 'tickets':
        return 'errors.activity-form.tickets';

      case 'requestMoreInfo':
        return 'errors.activity-form.requested-info';

      case 'coHosting':
        return 'errors.activity-form.co-hosting';

      default:
        return 'errors.activity-form.addons';
    }
  });
}

function renderErrorKey(txString: TxString, index: number) {
  return (
    <Styled.ErrorSnackbarListItem key={`event-snackbar-error-key-${index}`}>
      <Text variant="bodyMd" tx={txString} />
    </Styled.ErrorSnackbarListItem>
  );
}

export function PublishEventButton() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formContext = React.useContext(ActivityFormContext);

  const activityKey = useSelector(ActivityDataSelector.selectActivityKey);
  const createActivityStatus = useSelector(CreateActivitySelector.selectStatus);
  const updateActivityStatus = useSelector(UpdateActivitySelector.selectStatus);
  const ticketTypes = useSelector(ActivityDataSelector.selectTicketTypes);

  const isLoading = isAnyPending(createActivityStatus, updateActivityStatus);

  const handleInvalid = () => {
    formContext.setShowErrorSnackbar(true);
  };

  const closeErrorSnackbar = () => {
    formContext.setShowErrorSnackbar(false);
  };

  const putBackRemovedTicketType = (ticketTypeKey: string) => {
    const ticketType = ticketTypes?.find(
      (type) => type.ticketTypeKey === ticketTypeKey,
    );
    if (!ticketType) return;

    formContext.setValue('tickets.ticketTypes', [
      ...(formContext.getValues('tickets.ticketTypes') ?? []),
      ticketType,
    ]);
  };

  const createActivity = formContext.handleSubmit(
    async (data) => {
      const res = await dispatch(createActivityThunk(data));

      if (res.payload && 'activityKey' in res.payload) {
        navigate(`/activities/${res.payload.activityKey}/description`);
      }
    },
    (err) => {
      Logger.warning('createEvent Validation', {
        err: flattenFieldErrorsObject(err),
      });
      handleInvalid();
    },
  );

  const updateActivity = formContext.handleSubmit(
    async (data) => {
      if (!activityKey) return;

      const res = await dispatch(updateActivityThunk({ activityKey, ...data }));

      if (res.meta.requestStatus === 'fulfilled') {
        formContext.reset({
          ...data,
          coHosting: {
            cohosts:
              formContext.getValues('coHosting.cohosts')?.map((cohost) => {
                return {
                  ...cohost,
                  cohostRequestStatus:
                    cohost.cohostRequestStatus === 'not-sent'
                      ? 'pending'
                      : cohost.cohostRequestStatus,
                };
              }) ?? [],
          },
        });
        return;
      }

      if (!res.payload) return;
      if (!('message' in res.payload)) return;
      if (!isObject(res.payload.message)) return;

      if (res.payload.message.code === 0) {
        putBackRemovedTicketType(res.payload.message.metadata.ticketTypeKey);
      }
    },
    (err) => {
      Logger.warning('updateEvent Validation', {
        err: flattenFieldErrorsObject(err),
      });
      handleInvalid();
    },
  );

  const errorTxStrings = getAlertErrorKeys(
    Object.keys(formContext.formState.errors),
  );

  return (
    <React.Fragment>
      <Button
        isLoading={isLoading}
        onClick={
          formContext.mode === 'update' ? updateActivity : createActivity
        }
        variant="primary"
        tx={
          formContext.mode === 'update'
            ? 'button.activity.update'
            : 'button.activity.publish-event'
        }
      />

      {errorTxStrings.length > 0 &&
        formContext.formState.isSubmitted &&
        formContext.showErrorSnackbar && (
          <Snackbar
            onClose={closeErrorSnackbar}
            placement="top-end"
            zIndex={1000}
          >
            <Alert variant="error">
              <Box>
                <Text
                  color="alertErrorTitle"
                  variant="bodyMdBold"
                  tx="errors.activity-form.title"
                />

                <Text tx="errors.activity-form.subtitle" variant="bodyMd" />

                <Styled.ErrorSnackbarList>
                  {errorTxStrings.map(renderErrorKey)}
                </Styled.ErrorSnackbarList>
              </Box>
            </Alert>
          </Snackbar>
        )}
    </React.Fragment>
  );
}
