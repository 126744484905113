import { Box } from '@orbiapp/components';
import styled, { css } from 'styled-components';

const SelectMembershipTypeCard = styled(Box)<{ toggled: boolean }>`
  ${(props) => {
    const { theme, toggled } = props;

    return css`
      border-radius: 16px;
      padding: 32px;
      cursor: pointer;
      background-color: ${theme.colors.selectionCardBackground};
      border: 2px solid ${theme.colors.selectionCardBorder};

      ${toggled &&
      css`
        background-color: ${theme.colors.selectionCardBackgroundToggled};
        border: 2px solid ${theme.colors.selectionCardBorderToggled};
      `}
    `;
  }}
`;

export const Styled = { SelectMembershipTypeCard };
