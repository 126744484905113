import { Box, Text, Tooltip } from '@orbiapp/components';

import { Styled } from './selection-card.styled';
import {
  SelectionCardListItemProps,
  SelectionCardProps,
} from './selection-card.types';

export const SelectionCardListItem = (props: SelectionCardListItemProps) => {
  const { titleTx, ...rest } = props;

  const selectionCardContent = (
    <Box flex gap={8}>
      <Styled.SelectionCardIcon name="check-circle-solid" />

      <Text variant="bodyMd" {...rest} />
    </Box>
  );

  if (!titleTx) {
    return selectionCardContent;
  }

  return (
    <Box flexAlign="center" flex flexJustify="between" gap={16}>
      {selectionCardContent}

      <Tooltip placement="bottom" titleTx={titleTx}>
        <Styled.TooltipIcon name="question-mark-circle-outline" />
      </Tooltip>
    </Box>
  );
};

export const SelectionCardList = (props: React.PropsWithChildren) => {
  const { children } = props;

  return (
    <Box flex flexDirection="column" gap={16}>
      {children}
    </Box>
  );
};

export const SelectionCard = (props: SelectionCardProps) => {
  const { children, isSelected = false, onSelect } = props;

  return (
    <Styled.SelectionCard
      flex
      flexDirection="column"
      gap={24}
      isSelected={isSelected}
      onClick={onSelect}
      px={24}
      py={32}
      r={16}
      relative
    >
      <Box overflow="hidden" flexGrow={1} flex flexDirection="column" gap={16}>
        {children}
      </Box>
    </Styled.SelectionCard>
  );
};
