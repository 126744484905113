import {
  Avatar,
  Box,
  Chip,
  Divider,
  IconButton,
  LAYOUT_Z_INDEX,
  Menu,
  MenuButton,
  MenuItem,
  ProfileMenu,
  ProfileMenuHeader,
  ProfileMenuList,
  ProfileMenuListItem,
  ResponsiveBox,
  SelectWorkspaceMenu,
  SelectWorkspaceMenuItem,
  SidebarContext,
  Text,
  Time,
  Toolbar,
  ToolbarContentContainer,
  getAvatarVariantFromString,
  getPlacementMargin,
  numberFormatter,
  useAnchoredMenu,
  useCombinedRefs,
} from '@orbiapp/components';
import React from 'react';

import { LEARN_MORE_ABOUT_WORKSPACES_URL } from '../../../constants';
import { Analytics } from '../../../services';
import {
  AccountSelector,
  DepartmentSelector,
  useSelector,
} from '../../../store';
import { CreateMembershipModal } from './create-membership-modal';
import { CreatePostModal } from './create-post-modal';
import {
  ProfileMenuContext,
  ProfileMenuProvider,
  QuickActionsContext,
} from './signed-in.context';

function ToggleSidebarButton() {
  const { collapsed, toggleSidebar } = React.useContext(SidebarContext);

  return (
    <ResponsiveBox xs={null}>
      <IconButton
        icon={collapsed ? 'bars-3' : 'x-circle-outline'}
        onClick={toggleSidebar}
      />
    </ResponsiveBox>
  );
}

function QuickActionsMenu() {
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const clickOutsideRef = React.useRef<HTMLDivElement>(null);

  const { createMembershipModalState, createPostModalState } =
    React.useContext(QuickActionsContext);

  const { isOpen, toggleMenu, menuRef, closeMenu } = useAnchoredMenu({
    anchorRef,
    placement: 'bottom',
    autoCloseDuration: 5 * Time.Second,
    clickOutsideRef,
  });

  const trackCreateEventFromQuickActionsMenu = () => {
    Analytics.track('click_create_event__quick_menu');

    closeMenu();
  };

  const handleCreateOfferClick = () => {
    Analytics.track('click_create_offer__quick_menu');

    closeMenu();
  };

  const handleCreatePostClick = () => {
    Analytics.track('click_create_post__quick_menu');

    createPostModalState.openModal();

    closeMenu();
  };

  const handleCreateMembershipClick = () => {
    createMembershipModalState.openModal();

    closeMenu();
  };

  return (
    <ResponsiveBox
      xs={
        <Box ref={clickOutsideRef} minWidth={165}>
          <MenuButton
            isOpen={isOpen}
            onClick={closeMenu}
            onToggle={toggleMenu}
            ref={anchorRef}
            to="/activities/create-activity"
            tx="label.quick-actions.create-event"
          />

          <Menu
            isOpen={isOpen}
            ref={menuRef}
            minWidth="inherit"
            {...getPlacementMargin('bottom')}
          >
            <MenuItem
              to="/activities/create-activity"
              onClick={trackCreateEventFromQuickActionsMenu}
            >
              <Text
                as="span"
                tx="label.quick-actions.create-event"
                variant="bodyMd"
              />
            </MenuItem>

            <MenuItem onClick={handleCreateMembershipClick}>
              <Text
                as="span"
                tx="label.quick-actions.create-membership"
                variant="bodyMd"
              />
            </MenuItem>

            <MenuItem
              onClick={handleCreateOfferClick}
              to="/offers/create-offer"
            >
              <Text
                as="span"
                tx="label.quick-actions.create-offer"
                variant="bodyMd"
              />
            </MenuItem>

            <MenuItem onClick={handleCreatePostClick}>
              <Text
                as="span"
                tx="label.quick-actions.create-post"
                variant="bodyMd"
              />
            </MenuItem>
          </Menu>
        </Box>
      }
    />
  );
}

function ToggleUserProfileMenu() {
  const fullName = useSelector(AccountSelector.selectFullName);
  const userKey = useSelector(AccountSelector.selectUserKey);
  const profilePicture = useSelector(AccountSelector.selectProfilePicture);

  const { anchorRef, isOpen, closeMenu, openMenu } =
    React.useContext(ProfileMenuContext);

  if (!fullName || !userKey) {
    return null;
  }

  return (
    <Avatar
      variant={getAvatarVariantFromString(userKey)}
      cursor="pointer"
      fallbackLetter={fullName[0]}
      highlight={isOpen}
      onClick={isOpen ? closeMenu : openMenu}
      ref={anchorRef}
      src={profilePicture?.thumbnail64.url ?? undefined}
    />
  );
}

function UserProfileMenu() {
  const email = useSelector(AccountSelector.selectEmail);
  const fullName = useSelector(AccountSelector.selectFullName);
  const userKey = useSelector(AccountSelector.selectUserKey);
  const profilePicture = useSelector(AccountSelector.selectProfilePicture);

  const departmentLogo = useSelector(DepartmentSelector.selectLogo);
  const departmentName = useSelector(DepartmentSelector.selectName);

  const { menuRef, menuClickOutsideRef, isOpen, closeMenu } =
    React.useContext(ProfileMenuContext);

  const combinedRefs = useCombinedRefs(menuRef, menuClickOutsideRef);

  if (!fullName || !email || !isOpen || !userKey) {
    return null;
  }

  const avatarVariant = getAvatarVariantFromString(userKey);

  return (
    <ProfileMenu zIndex={LAYOUT_Z_INDEX.profileMenu} ref={combinedRefs}>
      <ProfileMenuHeader
        profilePictureSrc={profilePicture?.thumbnail64.url ?? ''}
        fullName={fullName}
        email={email}
        avatarVariant={avatarVariant}
      />

      <ProfileMenuList>
        <ProfileMenuListItem
          icon="user-circle-outline"
          onClick={closeMenu}
          to="/settings/account-settings"
          tx="label.toolbar-profile-menu.your-profile"
        />

        <ProfileMenuListItem
          to="/settings/department-settings"
          onClick={closeMenu}
        >
          <Avatar
            variant={getAvatarVariantFromString(userKey)}
            src={departmentLogo?.thumbnail64.url}
            fallbackLetter={departmentName?.charAt(0)}
            height={24}
            width={24}
          />
          <Text
            color="profileMenuListItemLabel"
            tx="label.toolbar-profile-menu.department-settings"
            variant="bodySm"
          />
        </ProfileMenuListItem>

        <ProfileMenuListItem
          icon="users-outline"
          onClick={closeMenu}
          to="/settings/team-settings"
          tx="label.toolbar-profile-menu.team-settings"
        />

        <ProfileMenuListItem
          href="mailto:support@orbiapp.io"
          icon="question-mark-circle-outline"
          onClick={closeMenu}
          tx="label.toolbar-profile-menu.support"
        />

        <Divider />

        <ProfileMenuListItem
          icon="power"
          onClick={closeMenu}
          to="/sign-out"
          tx="label.toolbar-profile-menu.sign-out"
        />
      </ProfileMenuList>
    </ProfileMenu>
  );
}

export function SignedInToolbar() {
  const followerCount = useSelector(DepartmentSelector.selectFollowerCount);
  const departmentName = useSelector(DepartmentSelector.selectName);
  const departmentLogo = useSelector(DepartmentSelector.selectLogo);
  const departmentKey = useSelector(DepartmentSelector.selectDepartmentKey);
  const workspaces = useSelector(AccountSelector.selectSwitchableWorkspaces);

  const workspacesMenuItems = workspaces
    .filter((workspace) =>
      workspace.type === 'department'
        ? workspace.departmentKey !== departmentKey
        : true,
    )
    .map((workspace): SelectWorkspaceMenuItem => {
      switch (workspace.type) {
        case 'company':
          return {
            avatarSrc: workspace.logo.thumbnail64.url,
            avatarVariant: getAvatarVariantFromString(workspace.companyKey),
            label: workspace.name,
            switchTx: 'label.general.switch',
            to: `/workspace/${workspace.type}/${workspace.companyKey}`,
          };

        default:
          return {
            avatarSrc: workspace.logo?.thumbnail64.url ?? '',
            avatarVariant: getAvatarVariantFromString(workspace.departmentKey),
            label: workspace.name,
            switchTx: 'label.general.switch',
            to: `/workspace/${workspace.type}/${workspace.departmentKey}`,
          };
      }
    })
    .sort((a, b) => a.label.localeCompare(b.label));

  return (
    <ProfileMenuProvider>
      <Toolbar
        zIndex={LAYOUT_Z_INDEX.pageToolbar}
        backgroundColor="toolbarBackground"
      >
        <ToolbarContentContainer py={8}>
          <Box flex flexAlign="center" width="100%" gap={56}>
            <Box overflow="hidden" flexAlign="center" gap={16} flex>
              <ToggleSidebarButton />

              <SelectWorkspaceMenu
                avatarSrc={departmentLogo?.thumbnail64.url}
                avatarVariant={getAvatarVariantFromString(departmentKey ?? '')}
                emptyStateLinkHref={LEARN_MORE_ABOUT_WORKSPACES_URL}
                emptyStateLinkTarget="_blank"
                emptyStateLinkTx="link.general.learn-more"
                emptyStateTx="label.general.workspaces-menu-empty-state"
                filterPlaceholderTx="placeholder.filter-workspaces"
                label={departmentName ?? ''}
                workspaces={workspacesMenuItems}
              />
            </Box>

            <QuickActionsMenu />

            <Box ml="auto" flex gap={32} flexAlign="center">
              <ResponsiveBox
                xs={
                  <Chip
                    variant="primary"
                    tx="label.general.followers"
                    whiteSpace="nowrap"
                    txArgs={{
                      followerCount: numberFormatter.format(followerCount ?? 0),
                    }}
                  />
                }
              />

              <IconButton
                icon="cog-6-tooth-outline"
                to="/settings/department-settings"
              />

              <ToggleUserProfileMenu />
            </Box>
          </Box>
        </ToolbarContentContainer>
      </Toolbar>

      <CreateMembershipModal />

      <CreatePostModal />

      <UserProfileMenu />
    </ProfileMenuProvider>
  );
}
