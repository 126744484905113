import { translate } from '../../i18n';
import { EditMeta } from '../../models';
import { parseTimestamp } from '../../utils';
import { Avatar, getAvatarVariantFromString } from '../avatar';
import { Box } from '../box';
import { TableCell, TableCellProps } from '../table/table-cell';
import { Text } from '../text';

interface UpdatedTableCellProps extends TableCellProps {
  editMeta: EditMeta;
}

interface UpdatedByTableCellProps extends UpdatedTableCellProps {
  fallbackTx: TxString;
}

export function UpdatedByTableCell(props: UpdatedByTableCellProps) {
  const { editMeta, fallbackTx, ...rest } = props;

  const fullName = editMeta.updatedBy
    ? `${editMeta.updatedBy.firstName} ${editMeta.updatedBy.lastName}`
    : translate(fallbackTx);

  return (
    <TableCell {...rest}>
      <Box flexAlign="center" gap={8} flex>
        <Avatar
          variant={
            editMeta.updatedBy?.userKey
              ? getAvatarVariantFromString(editMeta.updatedBy.userKey)
              : undefined
          }
          src={editMeta.updatedBy?.profilePicture?.thumbnail64.url}
          fallbackLetter={editMeta.updatedBy?.firstName[0]}
        />

        <Text textTransform="capitalize" variant="bodySm" text={fullName} />
      </Box>
    </TableCell>
  );
}

export function UpdatedAtTableCell(props: UpdatedTableCellProps) {
  const { editMeta, ...rest } = props;

  return (
    <TableCell
      {...rest}
      text={parseTimestamp(
        editMeta.updatedAt ?? editMeta.createdAt,
        'DD MMM YYYY HH:mm',
      )}
    />
  );
}
