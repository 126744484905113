import {
  COVER_IMAGE_MAX_SIZE_IN_BYTES,
  FileType,
  LOGO_IMAGE_MAX_SIZE_IN_BYTES,
} from '@orbiapp/components';
import Joi from 'joi';

import { FileErrors } from './file.errors';

export namespace CoverImageSchema {
  export const base64 = Joi.string()
    .max(COVER_IMAGE_MAX_SIZE_IN_BYTES)
    .required();
  export const fileName = Joi.string().required();
  export const fileType = Joi.valid(...Object.values(FileType))
    .required()
    .messages(FileErrors.fileType);
}

export namespace LogoImageSchema {
  export const base64 = Joi.string()
    .max(LOGO_IMAGE_MAX_SIZE_IN_BYTES)
    .required();
  export const fileName = Joi.string().required();
  export const fileType = Joi.valid(...Object.values(FileType)).required();
}
