import {
  GeneralApiResult,
  GeneralApiResultWithData,
  Pagination,
} from '@orbiapp/components';

import {
  MembershipPurchaseListItem,
  MembershipPurchasesOrderByKey,
  RefundMembershipPeriodErrorResponse,
} from '../../../../../../models';
import { Logger } from '../../../../../logger';
import { apisauce } from '../../../../api';
import { getApiLogExtras, getGeneralApiProblem } from '../../../../api.utils';
import { MembershipPurchaseDto } from './membership-purchases.dto';
import { membershipPurchaseMapper } from './membership-purchases.mappers';

export const getMembershipPurchases = async (params: {
  membershipTypeKey: string;
  pagination: Pagination<MembershipPurchasesOrderByKey>;
}): Promise<GeneralApiResultWithData<MembershipPurchaseListItem[]>> => {
  const { membershipTypeKey, pagination } = params;

  const res = await apisauce.get<MembershipPurchaseDto[], any>(
    `/v2/membership-types/${membershipTypeKey}/membership-purchases/history`,
    pagination,
  );

  if (!res.ok) {
    Logger.warning('getMembershipPurchases', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data: MembershipPurchaseListItem[] = res.data.map(
      membershipPurchaseMapper,
    );

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('getMembershipPurchases', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export const refundMembershipPurchase = async (params: {
  membershipTypeKey: string;
  membershipPurchaseKey: string;
}): Promise<GeneralApiResult<RefundMembershipPeriodErrorResponse>> => {
  const { membershipTypeKey, membershipPurchaseKey } = params;

  const res = await apisauce.put<void, RefundMembershipPeriodErrorResponse>(
    `/v2/membership-types/${membershipTypeKey}/membership-purchases/${membershipPurchaseKey}/refund`,
  );

  if (!res.ok) {
    Logger.warning('refundMembershipPurchase', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  return { kind: 'ok' };
};
