import {
  GeneralApiResult,
  GeneralApiResultWithData,
  Pagination,
} from '@orbiapp/components';

import {
  Question,
  QuestionForm,
  QuestionOrderByKey,
} from '../../../../../models';
import { Logger } from '../../../../logger';
import { apisauce } from '../../../api';
import { getApiLogExtras, getGeneralApiProblem } from '../../../api.utils';
import { QuestionDto } from './questions.dto';
import { questionMapper } from './questions.mappers';

export const getUnhandledQuestionsCount = async (): Promise<
  GeneralApiResultWithData<number>
> => {
  const res = await apisauce.get<{ count: number }, any>(
    '/v1/questions/unhandled-count',
  );

  if (!res.ok) {
    Logger.warning('getUnhandledQuestionsCount', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  if (typeof res.data?.count !== 'number') {
    throw new Error('unhandled question count is not a number');
  }

  try {
    const data: number = res.data.count;

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('getUnhandledQuestionsCount', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export const getQuestions = async (params: {
  hideHandled: boolean;
  pagination: Pagination<QuestionOrderByKey>;
}): Promise<GeneralApiResultWithData<Question[]>> => {
  const { hideHandled, pagination } = params;

  const res = await apisauce.get<QuestionDto[], any>('/v1/questions', {
    ...pagination,
    hideHandled,
  });

  if (!res.ok) {
    Logger.warning('getQuestions', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data: Question[] = res.data.map(questionMapper);

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('getQuestions', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export const getQuestionsByActivity = async (
  activityKey: string,
): Promise<GeneralApiResultWithData<Question[]>> => {
  const res = await apisauce.get<QuestionDto[], any>(
    `/v1/activities/${activityKey}/questions`,
  );

  if (!res.ok) {
    Logger.warning('getQuestionsByActivity', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data: Question[] = res.data.map(questionMapper);

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('getQuestionsByActivity', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export const updateQuestion = async (params: {
  activityKey: string;
  questionForm: QuestionForm;
  questionKey: string;
}): Promise<GeneralApiResult> => {
  const { activityKey, questionForm, questionKey } = params;

  const res = await apisauce.put<void, any>(
    `/v1/activities/${activityKey}/questions/${questionKey}`,
    questionForm,
  );

  if (!res.ok) {
    Logger.warning('updateQuestion', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  return { kind: 'ok' };
};

export const getQuestion = async (params: {
  activityKey: string;
  questionKey: string;
}): Promise<GeneralApiResultWithData<Question>> => {
  const { activityKey, questionKey } = params;

  const res = await apisauce.get<QuestionDto, any>(
    `/v1/activities/${activityKey}/questions/${questionKey}`,
  );

  if (!res.ok) {
    Logger.warning('getQuestion', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data: Question = questionMapper(res.data);

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('getQuestion', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export const setQuestionToggleIsHidden = async (params: {
  activityKey: string;
  isHidden: boolean;
  questionKey: string;
}): Promise<GeneralApiResult> => {
  const { activityKey, isHidden, questionKey } = params;

  const res = await apisauce.put<void, any>(
    `/v1/activities/${activityKey}/questions/${questionKey}/toggle-is-hidden`,
    { isHidden },
  );

  if (!res.ok) {
    Logger.warning('setQuestionToggleIsHidden', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  return { kind: 'ok' };
};
