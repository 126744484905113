import {
  GeneralApiResult,
  GeneralApiResultWithData,
} from '@orbiapp/components';

import { CreateQrScanToken, QrScanToken } from '../../../../../../models';
import { Logger } from '../../../../../logger';
import { apisauce } from '../../../../api';
import { getApiLogExtras, getGeneralApiProblem } from '../../../../api.utils';
import { QrScanTokenDto } from './qr-scan-tokens.dto';
import { qrScanTokenMapper } from './qr-scan-tokens.mappers';

export const createQrScanToken = async (params: {
  activityKey: string;
  createQrScanToken: CreateQrScanToken;
}): Promise<GeneralApiResultWithData<string>> => {
  const { activityKey, createQrScanToken } = params;

  const res = await apisauce.post<{ qrScanTokenKey: string }, any>(
    `/v1/activities/${activityKey}/qr-scan-tokens`,
    createQrScanToken,
  );

  if (!res.ok) {
    Logger.warning('createQrScanToken', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data: string = res.data.qrScanTokenKey;
    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('createQrScanToken', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export const getQrScanTokens = async (params: {
  activityKey: string;
}): Promise<GeneralApiResultWithData<QrScanToken[]>> => {
  const { activityKey } = params;

  const res = await apisauce.get<QrScanTokenDto[], any>(
    `/v1/activities/${activityKey}/qr-scan-tokens`,
  );

  if (!res.ok) {
    Logger.warning('getQrScanTokens', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data: QrScanToken[] = res.data.map(qrScanTokenMapper);

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('getQrScanTokens', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};

export const deleteQrScanToken = async (params: {
  activityKey: string;
  qrScanTokenKey: string;
}): Promise<GeneralApiResult> => {
  const { activityKey, qrScanTokenKey } = params;

  const res = await apisauce.delete<void, any>(
    `/v1/activities/${activityKey}/qr-scan-tokens/${qrScanTokenKey}`,
  );

  if (!res.ok) {
    Logger.warning('deleteQrScanToken', getApiLogExtras(res));
    return getGeneralApiProblem(res);
  }

  return { kind: 'ok' };
};
