import { joiResolver } from '@hookform/resolvers/joi';
import {
  Box,
  BreadCrumb,
  BreadCrumbs,
  Button,
  Chip,
  ContentContainer,
  ContentSidebar,
  ContentSidebarContentContainer,
  ContentSidebarFooterContainer,
  ControlledTextarea,
  EmptyState,
  InnerContentContainer,
  InnerPageContainer,
  Link,
  PageContainer,
  Paginator,
  ResponsiveBox,
  SolidIconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TablePlaceholderRows,
  TableRow,
  Text,
  TinySelect,
  Toolbar,
  ToolbarContentContainer,
  Tooltip,
  flattenFieldErrorsObject,
  paginatorOptions,
  parseTimestamp,
} from '@orbiapp/components';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { QuickActionsContext } from '../../../components';
import { useDataGridPagination } from '../../../helpers';
import {
  ACTIVITY_POST_MESSAGE_MAX_LENGTH,
  DEPARTMENT_POST_MESSAGE_MAX_LENGTH,
  Post,
  PostOrderByKey,
  UpdateActivityPost,
  UpdateActivityPostValidation,
  UpdateDepartmentPost,
  UpdateDepartmentPostValidation,
} from '../../../models';
import { Analytics, Logger } from '../../../services';
import {
  PostsSelector,
  UpdateActivityPostSelector,
  UpdateDepartmentPostSelector,
  getPostsThunk,
  postsActions,
  updateActivityPostThunk,
  updateDepartmentPostThunk,
  useDispatch,
  useSelector,
} from '../../../store';

interface PostsContextType {
  selectedPost: Post | null;
  setSelectedPost: React.Dispatch<React.SetStateAction<Post | null>>;
}

const PostsContext = React.createContext<PostsContextType>({
  selectedPost: null,
  setSelectedPost: () => {},
});

const TABLE_COLUMNS_WIDTHS = {
  type: 150,
  event: '50ch',
  message: '50ch',
  createdAt: 150,
};

function PostsProvider(props: React.PropsWithChildren) {
  const { children } = props;

  const [selectedPost, setSelectedPost] = React.useState<Post | null>(null);

  return (
    <PostsContext.Provider value={{ selectedPost, setSelectedPost }}>
      {children}
    </PostsContext.Provider>
  );
}

function PostsTable() {
  const posts = useSelector(PostsSelector.selectAll);
  const postsPagination = useSelector(PostsSelector.selectPagination);
  const postsStatus = useSelector(PostsSelector.selectStatus);

  const isLoading = postsStatus === 'pending';
  const postsContext = React.useContext(PostsContext);
  const quickActionsContext = React.useContext(QuickActionsContext);

  const { rows, paginatorProps, onPageSizeChange, onPaginate } =
    useDataGridPagination<Post, PostOrderByKey>({
      data: posts,
      pagination: postsPagination,
      reset: postsActions.clearPosts,
      thunk: getPostsThunk,
    });

  const renderPostTableRow = (post: Post) => {
    const openPostsSidebar = () => {
      postsContext.setSelectedPost(post);
    };

    return (
      <TableRow
        highlight={post.postKey === postsContext.selectedPost?.postKey}
        onClick={openPostsSidebar}
        key={post.postKey}
      >
        <TableCell width={TABLE_COLUMNS_WIDTHS.type} text={post.message}>
          {post.type === 'department' ? (
            <Chip variant="primary" tx="label.posts.table.department" />
          ) : (
            <Chip variant="secondary" tx="label.posts.table.event" />
          )}
        </TableCell>

        <TableCell
          width={TABLE_COLUMNS_WIDTHS.event}
          text={post.activityTitle ?? ''}
        />

        <TableCell width={TABLE_COLUMNS_WIDTHS.message} text={post.message} />

        <TableCell
          width={TABLE_COLUMNS_WIDTHS.createdAt}
          text={parseTimestamp(post.createdAt, 'DD MMM YYYY HH:mm')}
        />

        <TableCell width={50} fixedRight />
      </TableRow>
    );
  };

  if (!isLoading && rows.length === 0) {
    return (
      <EmptyState
        titleTx="label.posts.empty-state.title"
        buttonTx="label.posts.empty-state.button"
        buttonOnClick={quickActionsContext.createPostModalState.openModal}
      />
    );
  }

  if (isLoading && rows.length === 0) {
    return (
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead tx="label.posts.table.type" />
            <TableHead tx="label.posts.table.event" />
            <TableHead tx="label.posts.table.message" />
            <TableHead tx="label.posts.table.created-at" />
          </TableRow>
        </TableHeader>

        <TableBody>
          <TablePlaceholderRows
            rowCount={10}
            layout={Object.values(TABLE_COLUMNS_WIDTHS)}
          />
        </TableBody>
      </Table>
    );
  }

  return (
    <React.Fragment>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead tx="label.posts.table.type" />
            <TableHead tx="label.posts.table.event" />
            <TableHead tx="label.posts.table.message" />
            <TableHead tx="label.posts.table.created-at" />

            <TableHead fixedRight />
          </TableRow>
        </TableHeader>

        <TableBody>{rows.map(renderPostTableRow)}</TableBody>
      </Table>

      <Box flexJustify="end" gap={24} flexAlign="center" flex>
        <Box flex gap={16} flexAlign="center">
          <Text
            tx="label.general.rows-per-page"
            variant="bodySm"
            whiteSpace="nowrap"
          />

          <TinySelect
            invertMenu
            onChange={onPageSizeChange}
            options={paginatorOptions}
            value={paginatorProps.pageSize}
          />
        </Box>

        <Paginator
          currentPage={paginatorProps.currentPage}
          hasNextPage={paginatorProps.hasNextPage}
          hasPrevPage={paginatorProps.hasPrevPage}
          onPaginate={onPaginate}
        />
      </Box>
    </React.Fragment>
  );
}

function OpenCreatePostModalButton() {
  const postsCount = useSelector(PostsSelector.selectCount);

  const { createPostModalState } = React.useContext(QuickActionsContext);

  const handleCreatePostClick = () => {
    Analytics.track('click_create_post__dashboard');

    createPostModalState.openModal();
  };

  return (
    <React.Fragment>
      {postsCount > 0 && (
        <ResponsiveBox
          xs={
            <Button
              onClick={handleCreatePostClick}
              tx="button.posts.create"
              variant="primary"
            />
          }
        >
          <Tooltip placement="left" titleTx="button.posts.create">
            <SolidIconButton
              icon="plus-circle-outline"
              onClick={handleCreatePostClick}
            />
          </Tooltip>
        </ResponsiveBox>
      )}
    </React.Fragment>
  );
}

function PostsContent() {
  return (
    <React.Fragment>
      <Box flex flexWrap="wrap" gap={16} flexJustify="between">
        <Text
          color="pageTitle"
          tx="title.posts.dashboard"
          variant="titleMd"
          as="h1"
        />

        <OpenCreatePostModalButton />
      </Box>

      <PostsTable />
    </React.Fragment>
  );
}

function UpdateDepartmentPostFormContent() {
  const postsContext = React.useContext(PostsContext);

  const dispatch = useDispatch();

  const updateDepartmentPostStatus = useSelector(
    UpdateDepartmentPostSelector.selectStatus,
  );

  const isLoading = updateDepartmentPostStatus === 'pending';

  const formMethods = useForm<UpdateDepartmentPost>({
    resolver: joiResolver(UpdateDepartmentPostValidation),
    defaultValues: {
      departmentPostKey: postsContext.selectedPost?.postKey ?? '',
      message: postsContext.selectedPost?.message ?? '',
    },
  });

  const updateDepartmentPost = formMethods.handleSubmit(
    (data) => {
      dispatch(updateDepartmentPostThunk(data));
    },
    (err) => {
      Logger.warning('updateDepartmentPost Validation', {
        err: flattenFieldErrorsObject(err),
      });
    },
  );

  React.useEffect(() => {
    formMethods.reset({
      departmentPostKey: postsContext.selectedPost?.postKey,
      message: postsContext.selectedPost?.message,
    });
  }, [formMethods, postsContext.selectedPost]);

  return (
    <FormProvider {...formMethods}>
      <ContentSidebarContentContainer>
        <Text
          color="pageTitle"
          as="h1"
          tx="label.posts.edit-post.title"
          variant="titleMd"
        />

        <ControlledTextarea
          disabled={isLoading}
          labelTx="label.posts.create-post.message"
          maxLength={DEPARTMENT_POST_MESSAGE_MAX_LENGTH}
          name="message"
        />
      </ContentSidebarContentContainer>

      <ContentSidebarFooterContainer flexJustify="end">
        <Button
          isLoading={isLoading}
          onClick={updateDepartmentPost}
          tx="button.save"
          variant="secondary"
        />
      </ContentSidebarFooterContainer>
    </FormProvider>
  );
}

function UpdateActivityPostFormContent() {
  const postsContext = React.useContext(PostsContext);

  const dispatch = useDispatch();

  const updateActivityPostStatus = useSelector(
    UpdateActivityPostSelector.selectStatus,
  );

  const isLoading = updateActivityPostStatus === 'pending';

  const formMethods = useForm<UpdateActivityPost>({
    resolver: joiResolver(UpdateActivityPostValidation),
    defaultValues: {
      activityKey: postsContext.selectedPost?.activityKey ?? '',
      activityPostKey: postsContext.selectedPost?.postKey ?? '',
      message: postsContext.selectedPost?.message ?? '',
    },
  });

  const updateActivityPost = formMethods.handleSubmit(
    (data) => {
      dispatch(updateActivityPostThunk(data));
    },
    (err) => {
      Logger.warning('updateEventPost Validation', {
        err: flattenFieldErrorsObject(err),
      });
    },
  );

  React.useEffect(() => {
    formMethods.reset({
      activityKey: postsContext.selectedPost?.activityKey ?? '',
      activityPostKey: postsContext.selectedPost?.postKey,
      message: postsContext.selectedPost?.message,
    });
  }, [formMethods, postsContext.selectedPost]);

  return (
    <FormProvider {...formMethods}>
      <ContentSidebarContentContainer>
        <Box flex flexDirection="column" gap={8}>
          {postsContext.selectedPost?.type === 'activity' && (
            <Box width="fit-content">
              <Link
                tx="label.posts.go-to-activity"
                to={`/activities/${postsContext.selectedPost?.activityKey}/posts`}
              />
            </Box>
          )}

          <Text
            color="pageTitle"
            as="h1"
            tx="label.posts.edit-post.title"
            variant="titleMd"
          />
        </Box>

        <ControlledTextarea
          disabled={isLoading}
          labelTx="label.posts.create-post.message"
          maxLength={ACTIVITY_POST_MESSAGE_MAX_LENGTH}
          name="message"
        />
      </ContentSidebarContentContainer>

      <ContentSidebarFooterContainer flexJustify="end">
        <Button
          isLoading={isLoading}
          onClick={updateActivityPost}
          tx="button.save"
          variant="secondary"
        />
      </ContentSidebarFooterContainer>
    </FormProvider>
  );
}

function PostsSidebar() {
  const postsContext = React.useContext(PostsContext);

  const isOpen = postsContext.selectedPost !== null;

  const closePostsSidebar = () => {
    postsContext.setSelectedPost(null);
  };

  return (
    <ContentSidebar isOpen={isOpen} onClose={closePostsSidebar} width={400}>
      {postsContext.selectedPost?.type === 'activity' ? (
        <UpdateActivityPostFormContent />
      ) : (
        <UpdateDepartmentPostFormContent />
      )}
    </ContentSidebar>
  );
}

function PostsToolbar() {
  return (
    <Toolbar backgroundColor="tabHeaderBackground" zIndex={1}>
      <ToolbarContentContainer>
        <BreadCrumbs>
          <BreadCrumb tx="label.breadcrumbs.posts.posts" isLast />
        </BreadCrumbs>
      </ToolbarContentContainer>
    </Toolbar>
  );
}

export function Posts() {
  return (
    <PostsProvider>
      <PageContainer>
        <PostsToolbar />

        <InnerPageContainer>
          <ContentContainer>
            <InnerContentContainer>
              <PostsContent />
            </InnerContentContainer>
          </ContentContainer>

          <PostsSidebar />
        </InnerPageContainer>
      </PageContainer>
    </PostsProvider>
  );
}
