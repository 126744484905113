import {
  Box,
  Icon,
  Text,
  parseTimestamp,
  translate,
} from '@orbiapp/components';
import React from 'react';

import {
  ShrodingersTicket,
  ShrodingersTicketMembership,
} from '../../../../../../models';
import { Styled } from './scan-result-memberships.styled';

function getStatusLabel(
  shrodingersTicketMembership: ShrodingersTicketMembership,
) {
  const isActive =
    shrodingersTicketMembership.validFrom &&
    shrodingersTicketMembership.expiredAt &&
    shrodingersTicketMembership.validFrom <= Date.now() &&
    shrodingersTicketMembership.expiredAt >= Date.now();

  if (isActive) {
    return translate('label.scan-qr-codes.scan-result-card.valid-membership');
  }

  if (
    !(
      shrodingersTicketMembership.expiredAt ||
      shrodingersTicketMembership.validFrom
    )
  ) {
    return translate(
      'label.scan-qr-codes.scan-result-card.no-membership-added',
    );
  }

  const expiredAt = shrodingersTicketMembership.expiredAt
    ? translate('label.scan-qr-codes.scan-result-card.expired-at', {
        date: parseTimestamp(
          shrodingersTicketMembership.expiredAt,
          'DD MMM YYYY',
        ),
      })
    : '';

  const pendingActivationAt = shrodingersTicketMembership.validFrom
    ? translate('label.scan-qr-codes.scan-result-card.next-activation-at', {
        date: parseTimestamp(
          shrodingersTicketMembership.validFrom,
          'DD MMM YYYY',
        ),
      })
    : '';

  return [
    expiredAt + (expiredAt && pendingActivationAt ? '\n' : ''),
    pendingActivationAt,
  ].filter((value) => value);
}

function renderRequiredMembershipListItem(
  requiredMembership: ShrodingersTicketMembership,
  index: number,
) {
  const { membershipTypeName } = requiredMembership;

  const value = getStatusLabel(requiredMembership);

  return (
    <Box
      flex
      flexAlign="center"
      gap={16}
      key={`required-membership-list-item-${index}`}
    >
      <Icon name="identification-outline" />

      <Box>
        <Text variant="bodyMd" text={membershipTypeName} />
        <Text
          variant="bodySm"
          text={typeof value === 'string' ? value : value.join('\n')}
        />
      </Box>
    </Box>
  );
}

function UnverifiedMembershipsStatus(props: {
  requiredMembershipCount: number;
}) {
  const { requiredMembershipCount } = props;

  return (
    <Styled.UnverifiedStatus flex flexDirection="column" gap={8} p={16} r={8}>
      <Box flexAlign="center" flex gap={8}>
        <Icon name="exclamation-triangle-outline" />

        <Text
          variant="bodyMdBold"
          txArgs={{ count: requiredMembershipCount }}
          tx="label.scan-qr-codes.scan-result-card.unverified-memberships"
        />
      </Box>

      <Text
        txArgs={{ count: requiredMembershipCount }}
        tx="label.scan-qr-codes.scan-result-card.unverified-memberships-information"
        variant="bodySm"
      />
    </Styled.UnverifiedStatus>
  );
}

export function ScanResultMemberships(props: {
  requiredMemberships: ShrodingersTicket['requiredMemberships'];
  ticketHolder: ShrodingersTicket['ticketHolder'];
}) {
  const { requiredMemberships, ticketHolder } = props;

  if (!requiredMemberships || !requiredMemberships.length) {
    return null;
  }

  if (!ticketHolder?.type || ticketHolder.type === 'user') {
    return (
      <React.Fragment>
        {requiredMemberships.map(renderRequiredMembershipListItem)}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <UnverifiedMembershipsStatus
        requiredMembershipCount={requiredMemberships.length ?? 0}
      />

      <Box flex flexDirection="column" gap={16}>
        {requiredMemberships.map(renderRequiredMembershipListItem)}
      </Box>
    </React.Fragment>
  );
}
